import {useCallback, useEffect, useState} from "react";
import axios from "axios";

const useVideo = () => {
  const [video, setVideo] = useState('');

  const refresh = useCallback(() => {
    axios.post('https://api.jelefaismoimeme.be/video.php')
      .then((response) => {
        setVideo(response.data);
      });
  }, []);

  useEffect(refresh,[refresh]);

  return { video, refresh };
}

export default useVideo;