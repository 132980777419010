import React from 'react';
import {NavLink} from "react-router-dom";
import {Button} from "@mui/material";

export const Borne = {
  nom: 'Borne',
  color: '#FFB100',
  colorHover: '#fbdb91',
  colorBackground: '#FFFAEF',
  description:
    <div>
      Je fais mon installation de borne de recharge avec assistance, rien de plus simple.
      vous commencez par <NavLink to="/devis" ><span className="textRose">réaliser votre devis</span></NavLink> via notre configurateur simplifié et puis avec notre aide,
      nos <span className="textBleu">techniciens qualifiés</span> vous serez <span className="textRose">facilement faire des économies</span>, vous sentir satisfait et fier d’avoir
      réaliser votre propre installation électrique selon vos <span className="textRose">choix</span>, vos goûts, vos désirs et surtout votre budget.
    </div>,
  imageBanniere: 'https://entrepot.jelefaismoimeme.be/typeInstallation/borne.jpg',
  dossier: 'borne',
  categories: {
    "Maison individuelle": {
      triangleClass: 'triangleGauche',
      description: (
        <div>
          <div className="marginBottom TitreDescriptionExempleDevis gras">Configuration</div>
          <ul>
            <li>Borne fixe</li>
            <li>Compteur mono 230V</li>
          </ul>
        </div>
      ),
    },
    Appartement: {
      triangleClass: 'triangleCentre',
      description: (
        <div>
          <div className="marginBottom TitreDescriptionExempleDevis gras">Configuration</div>
          <ul>
            <li>Borne transportable</li>
            <li>Compteur mono 230V</li>
          </ul>
        </div>
      ),
    },
    "Maison mitoyenne": {
      triangleClass: 'triangleDroite',
      description: (
        <div>
          <div className="marginBottom TitreDescriptionExempleDevis gras">Configuration</div>
          <ul>
            <li>Borne fixe</li>
            <li>Compteur mono 230V</li>
          </ul>
        </div>
      ),
    },
  },
  etapes: [
    <NavLink to="/devis"><Button className="btnDevis">Commencer mon devis</Button></NavLink>,
    <div>Rendez-vous chez vous ou à notre showroom pour <span className="gras">finaliser votre projet selon vos besoins et vos choix</span>.</div>,
    <div>Nous réalisons vos <span className="gras">plans précis</span> et <span className="gras">pré-configurons</span> votre demande.</div>,
    <div>Livraison et explication chez vous par un de nos techniciens pour <span className="gras">l'installation de votre matériel</span></div>,
    <div>Profitez de votre installation de votre <span className="gras">borne de recharge</span>.</div>,
  ],
};
