import HomeIcon from "@mui/icons-material/Home";
import AccessTimeFilledIcon from "@mui/icons-material/AccessTimeFilled";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import {useCallback, useRef} from "react";
import React from 'react';

const InformationContact = () => {
  // reference sur le link sur lequel tu click
  const emailRef = useRef(null);
  // fonction appelé lors de l'appuie sur le lien (useCallback c'est pour eviter de reconstruire la fonction a chaque update sur la page
  const sendMail = useCallback(
    () => {
      // la je dit, si il trouve pas la referent ou que le dataset n'existe pas tu retourne
      if (!emailRef.current?.dataset) return;
      // on recup name, domain et tld depuis l'element
      const { name, domain, tld } = emailRef.current.dataset;
      // on redirige pour envoyer un mail avec mailto:
      window.location.href='mailto:' + name + '@' + domain + '.' + tld;
    },
    [emailRef],
  );

  return (
      <div className="contact">
        <div className="InfoContact">
          <HomeIcon sx={{fontSize: 30}}/>
          <div className="PositionInfoContact">
            <div>SRL Easy Services Electricité Domotique</div>
            <div>72 rue Octave Battaille</div>
            <div>7971 Beloeil</div>
          </div>
        </div>
        <div className="InfoContact">
          <AccessTimeFilledIcon sx={{fontSize: 30}}/>
          <div className="PositionInfoContact">
            <div>Horaire</div>
            <div>Du lundi au samedi</div>
            <div>sur rendez-vous</div>
          </div>
        </div>
        <div className="InfoContact">
          <PhoneIcon sx={{fontSize: 30}}/>
          <div className="PositionInfoContact">
            <a href="tel:003269300537">0032 69 30 05 37</a>
          </div>
        </div>
        <div className="InfoContact">
          <EmailIcon sx={{fontSize: 30}}/>
          <div className="PositionInfoContact">
            <a href="#" className="cryptedmail"
               data-name="info"
               data-domain="jelefaismoimeme"
               data-tld="be"
               ref={emailRef}
               onClick={sendMail}>
            </a>
          </div>
        </div>
      </div>

  );
}

export default InformationContact;