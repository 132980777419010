import React from 'react';
import { SvgIcon } from "@mui/material";

function ConnecteIcon(props) {
  return (
    <SvgIcon {...props} viewBox="0 0 408.3 408.17">
      <path d="M408.3,4.84V40.11A4.85,4.85,0,0,1,403.49,45l-69,.44a4.85,4.85,0,0,0-4.82,4.85V64.13A4.84,4.84,0,0,1,324.87,69l-36.67.4a4.84,4.84,0,0,1-4.9-4.95l.3-14.59a4.84,4.84,0,0,0-4.81-5l-67.32-.44a4.85,4.85,0,0,1-4.81-4.91l.4-34.27A4.84,4.84,0,0,1,211.89.49L403.45,0A4.83,4.83,0,0,1,408.3,4.84Z"/>
      <path d="M263.79,129.48V90.37a3.06,3.06,0,0,1,3.07-3.06h82.21a3.06,3.06,0,0,1,3.06,3.06v81.9a3.05,3.05,0,0,1-5.34,2,126.48,126.48,0,0,0-80.3-41.79A3,3,0,0,1,263.79,129.48Z"/>
      <path d="M278.82,362.22l9.37-23.63a3.35,3.35,0,0,1,2.27-2l40-10.75a3.39,3.39,0,0,1,4.08,4.4A54.8,54.8,0,0,1,282,366.87,3.39,3.39,0,0,1,278.82,362.22Z"/>
      <path d="M155.87,398.42l93.32-22.29a2.94,2.94,0,0,0,1.94-1.53l11.67-23.34a2.92,2.92,0,0,0-3.39-4.13,131.2,131.2,0,0,1-86.49-5.74,2.91,2.91,0,0,0-4.06,2.65,89.42,89.42,0,0,1-16.07,49.88A2.92,2.92,0,0,0,155.87,398.42Z"/>
      <path d="M57.76,262.87l-19.1-8.41a6.09,6.09,0,0,0-8.31,3.84c-4.89,16.42-11,47.42,1.1,81.26,1.44,4,26.72,72.6,67.41,68.43,25.94-2.66,51.39-34.2,53.5-77a6.15,6.15,0,0,0-2.23-5.08l-.11-.09a6.11,6.11,0,0,0-9.84,3.47c-1.52,7-5.16,15.82-13.75,22-12.44,9-26.55,6.07-28.6,5.61-28.56-6.39-37-47.53-37.78-51.57a82.18,82.18,0,0,1,1.17-35.44A6.09,6.09,0,0,0,57.76,262.87Z"/>
      <path d="M81.33,277.31A2.81,2.81,0,0,0,77,279.47a84.5,84.5,0,0,0,2.47,26.39c2.31,8.94,8.14,31.53,22,33.7,7.7,1.21,14.39-4.47,16.34-6.13,9.39-8,9.92-19.79,9.79-24.07a2.8,2.8,0,0,0-1.25-2.23Z"/>
      <path d="M2.69,221.05a3.94,3.94,0,0,1,.21-7.53l221.57-60.34c8.46-1.9,45.6-9.24,82.22,12.77,47.26,28.4,53,81.33,53.6,87.68,0,.1,0,.2,0,.3l.48,41.29A3.93,3.93,0,0,1,358,299L255.11,329.35a113.45,113.45,0,0,1-74.47-4.85,3.77,3.77,0,0,1-1.07-.69A507.53,507.53,0,0,0,97.32,264,507.34,507.34,0,0,0,2.69,221.05Z"/>
    </SvgIcon>
  );
}

export default ConnecteIcon;