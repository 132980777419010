import './EtapeShowroom.css'
import HexagoneIcon from "../../../Globale/Icons/HexagoneIcon";

const EtapeShowroom = ({Etape}) => {
  return(
    <div className="etapeShowroom">
      {Etape.map((item, idItem) => (
        <div className="gridShowroom" key={idItem}>
          <div className={idItem % 2 === 0 ? "positionEtape decallageDroite" : "positionEtape decallageGauche"} style={{gridRow: idItem}}>
            {item.description}
          </div>
          <div className={idItem % 2 === 0 ? "positionEtape decallageGauche" : "positionEtape decallageDroite"} style={{gridRow: idItem}}>
            <div className={idItem % 2 === 0 ?"hexa hexaShowroom hexaShowroomGauche": "hexa hexaShowroom hexaShowroomDroite"} style={{color:'#0A90C9'}}>
              <HexagoneIcon/>
              <div className="etapeHexa center">{item.nom}</div>
            </div>
            <img className="imageEtape" src={"https://entrepot.jelefaismoimeme.be/showroom/"+item.image} alt="Image pour les étapes de materiel a choisir"/>
          </div>
        </div>
      ))}
  </div>
  );
}

export default EtapeShowroom;