import './StepperDevis.css';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import {useCallback, useContext, useMemo} from "react";
import {DevisContext} from "../../../../Context/DevisContext";
import {StepButton} from "@mui/material";

const StepperDevis = ({stepCompleted, numeroActiveStep, etapes, setNumeroActiveStep}) => {
  const { devis: { devis } } = useContext(DevisContext);

  const lastStep = useMemo(() => Object.values(stepCompleted).filter(Boolean).length, [stepCompleted]);

  const goToStep = useCallback((idx) => {
    setNumeroActiveStep(idx);
  }, [setNumeroActiveStep]);

  if (!devis) {
    return <div />;
  }

  return(
    <Box sx={{ width: '100%' }}>
      <Stepper nonLinear activeStep={numeroActiveStep + 1} alternativeLabel>
        <Step>
          <StepLabel StepIconComponent={() => <devis.icon style={{color: '#0a90c9'}}/>}>{devis?.nom}</StepLabel>
        </Step>
        {etapes.map((label, idx) => (
          <Step key={label} completed={stepCompleted[idx]}>
            {idx < lastStep ? (
              <StepButton icon={idx + 1} onClick={() => goToStep(idx)}>{label}</StepButton>
            ) : (
              <StepLabel icon={idx + 1}>{label}</StepLabel>
            )}
          </Step>
        ))}
      </Stepper>
    </Box>
  );
}

export default StepperDevis;