import './ConfigNiveau.css'
import * as React from "react";
import ConfigPieces from "../ConfigPieces/ConfigPieces";

const ConfigNiveau = () => {

  return(
    <div className="configNiveau">
      <ConfigPieces/>
    </div>
  );
}

export default ConfigNiveau;
