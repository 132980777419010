import './ExempleDevis.css'
import Menu from "../../Globale/Composants/Menu/Menu";
import PiedDePage from "../../Globale/Composants/PiedDePage/PiedDePage";
import NomExempleDevis from "./Composants/NomExempleDevis/NomExempleDevis";
import JLFMMTitre from "../../Globale/Composants/JLFMMTitre/JLFMMTitre";
import ExplicationService from "./Composants/ExplicationService/ExplicationService";
import Galerie from "../Accueil/Composants/Galerie/Galerie";
import ExemplePrix from "./Composants/ExemplePrix/ExemplePrix";
import Processus from "./Composants/Processus/Processus";
import React from 'react';
import ScrollTop from "../../Globale/Composants/ScrollTop/ScrollTop";

const ExempleDevis = ({Data}) => {
  return(
    <div>
      <ScrollTop />
      <header>
        <Menu />
      </header>
      <div className="corps">
        <NomExempleDevis Nom={Data.nom} Banniere={Data.imageBanniere} Couleur={Data.color}/>
        <JLFMMTitre />
        <ExplicationService description={Data.description}/>
        <Galerie dossier={Data.dossier}/>
        <ExemplePrix Nom={Data.nom} Couleur={Data.color} Categories={Data.categories} CouleurBackground={Data.colorBackground} ColorHover={Data.colorHover}/>
        <Processus Etapes={Data.etapes} Couleur={Data.color}/>
      </div>
      <footer>
        <PiedDePage />
      </footer>
    </div>
  );
}

export default ExempleDevis;
