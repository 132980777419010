import HexagoneAssistant from "../../HexagoneAssistant/HexagoneAssistant";
import {useContext, useEffect, useState} from "react";
import {DevisContext} from "../../../../../Context/DevisContext";

const Construction = ({onClickHexa}) => {
  const { devis, setDevis } = useContext(DevisContext);
  const [selectionneHabitation,setSelectionneHabitation] = useState(devis.construction ?? null);
  const habitation = [
    {
      nom:"Nouvelle construction",
    },
    {
      nom:"Rénovation",
    },
  ];
  
  useEffect(() => {
    setDevis((oldDevis) => ({
      ...oldDevis,
      construction: selectionneHabitation,
    }))
  }, [selectionneHabitation, setDevis]);
  
  return(
    <div className="contenuEtape fontAssisant positionHexaTexte marginTop">
      <div>
        <div className="titreEtape texteInfoService center">Cette installation concerne une nouvelle construction ou une rénovation ?</div>
        <div className="hexagoneCentrerConstruction">
        <HexagoneAssistant
          ServicesItem={habitation}
          setSelectionHexagone={setSelectionneHabitation}
          selectionHexagone={selectionneHabitation}
          onClickHexa={onClickHexa}/>
        </div>
      </div>
      {devis?.devis?.nom !== "Panneaux photo voltaïque" ?
        <div className="texteInfoDevis">
          Pour une nouvelle construction la TVA est de 21% et pour une rénovation (maison de plus de 10 ans) la TVA est de 6%.
        </div>
      :<div className="texteInfoDevis">La TVA est de 6% pour toute les installation de panneaux photovoltaïques</div>
      }
    </div>
  );
}

export default Construction;