import './EntrepotElements.css'
import React, {useMemo} from "react";
import logo from "../../../../Globale/Images/logoJelefaismoimeme.png";

/*La 1er colonne de la page admin qui liste tout les composants modifiable par l'admin*/
const EntrepotElement = ({ElementSelectionne, setElementSelectionne}) =>{
  const ComposantItems = useMemo(() => ([
    'Actualité',
    'Galerie',
    'Vidéo',
    'Partenaire',
    'Devis',
    'Promo',
    'Pop-up',
    'Avis',
  ]), []);

  return(
    <div className="EntrepotElement alignementCol">
      <img src={logo} alt="Logo" loading="eager" className="logo"/>
      {ComposantItems.map((item , idItem) => (
        <div
          key={'ComposantItems' + idItem}
          onClick={() => setElementSelectionne(item)}
          className="survole"
        >
          <div className={ ElementSelectionne === item? "zoneSelectionne" : 'EntrepotElement'}>{item}</div>
        </div>
      ))}
    </div>
  );
}
export default EntrepotElement;