import './Partenaires.css'
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import React from 'react';
import useGalerie from "../../../../Hook/useGalerie";

const Partenaires = () => {
  const { listImage } = useGalerie({dossier: "", composant: 'partenaire'})
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    }
  };
  return (
    <div className="partenaires">
      <div className="titre1">Partenaires</div>
      <Carousel
        responsive={responsive}
        autoPlay={true}
        autoPlaySpeed={5000}
        infinite={true}
        arrows={false}
        className="carousel"
      >
        {listImage.map((item , idItem) => (
          <div
            key={'partenairesItem' + idItem}
          >
            <div className="logocentrer">
              <img src={"https://entrepot.jelefaismoimeme.be/partenaires/"+item} alt="image logo partenaire" loading="eager" className="imageLogoPartenaire"/>
            </div>
          </div>
        ))}
      </Carousel>
    </div>
  );
}

export default Partenaires;