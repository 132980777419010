import './Information.css'
import InformationContact from "../../../../Globale/Composants/InformationContact/InformationContact";
import Maps from "../Maps/Maps";
import React from 'react';

const Information = () => {
  return(
    <div className="information">
      <div className="informationContact">
        <InformationContact />
      </div>
      <div className="maps">
        <Maps />
      </div>
    </div>
  );
}

export default Information;