import CorbeilleIcon from "../../../../Globale/Icons/corbeilleIcon";
import React, {useCallback, useContext} from "react";
import axios from "axios";
import {AdminContext} from "../../../../Context/AdminContext";

const BoutonSupprimer = ({composant, id, onClick}) => {
  const {
    actualite: { refresh: refreshActu },
    video: { refresh: refresh },
    promo: { refresh: refreshPromo },
    avis: { refresh: refreshAvis },
  } = useContext(AdminContext);

  /*Appelle API pour supprimer l'actualité, promo ou la vidéo */
  const supprimer = useCallback(() => {
    axios.post('https://api.jelefaismoimeme.be/supprimeActualite.php',{
      id : id,
      composant : composant
    })
      .then((response) => {
        refreshActu();
        refresh();
        refreshPromo();
        refreshAvis();
        onClick();
      });
  }, [id, composant, refreshActu, refreshPromo, refresh, refreshAvis]);

  return(
    <div onClick={supprimer}>
      <CorbeilleIcon/>
    </div>
  );
}

export default BoutonSupprimer;