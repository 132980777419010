import './NombrePrise.css'
import {Button} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule';
import * as React from "react";
import {useCallback, useContext} from "react";
import {DevisContext} from "../../../../../../Context/DevisContext";

const NombrePrise = ({texte,titre, setListPrise, id, nombrePrise}) => {
  const devis = useContext(DevisContext);

  const save = useCallback((valeur) => {
    setListPrise(value => {
      if(value[id].valeur === undefined){
        if (typeof value[id].nombre === 'number') {
          value[id].nombre = value[id].nombre + valeur;
        }
        else {
          value[id].nombre[devis?.devis?.habitation?.nom] += valeur;
        }
      }
      else{
        value[id].valeur += valeur;

      }

      return [...value];
    });
  }, [devis?.devis?.habitation?.nom, id, setListPrise]);

  const retirePrise = () => {
    if(nombrePrise > 0){
      save(-1);
    }
  }
  const ajoutePrise = () => {
    if(titre === "Prises"){
      if(nombrePrise < 5){
        save(1)
      }
    }
    else if(texte.includes("Internet") || texte.includes("USB")){
      if(nombrePrise < 4){
        save(1)
      }
    }
    else if(texte.includes("TV")){
      if(nombrePrise < 2){
        save(1)
      }
    }
    else{
      save(1)
    }
  }

  return(
    <div className="nombreprise">
      <div className="nombreprise styleBtnDevis">
        <Button variant="contained" onClick={retirePrise} ><HorizontalRuleIcon sx={{ fontSize: 15 }}/></Button>
        <div className={nombrePrise === 0 ? "quantitePrise textGris" : "quantitePrise"}>{nombrePrise}</div>
        <Button variant="contained" onClick={ajoutePrise}><AddIcon sx={{ fontSize: 15 }}/></Button>
      </div>
      <div className={nombrePrise === 0 ? "textePrise textGris" : "textePrise"}>{texte}</div>
    </div>
  );
}

export default NombrePrise;
