import './RecapitulatifDevis.css'
import ScrollTop from "../../../../../Globale/Composants/ScrollTop/ScrollTop";
import * as React from "react";
import HexagoneAssistant from "../../HexagoneAssistant/HexagoneAssistant";
import ElectriciteIcon from "../../../../../Globale/Icons/ElectriciteIcon";
import AlarmeIcon from "../../../../../Globale/Icons/AlarmeIcon";
import CameraIcon from "../../../../../Globale/Icons/CameraIcon";
import PVIcon from "../../../../../Globale/Icons/PVIcon";
import BorneIcon from "../../../../../Globale/Icons/BorneIcon";
import domotiqueIcon from "../../../../../Globale/Icons/DomotiqueIcon";
import connecteIcon from "../../../../../Globale/Icons/ConnecteIcon";
import {useContext} from "react";
import {DevisContext} from "../../../../../Context/DevisContext";

const RecapitulatifDevis = ({type, donneeDevis, codePromoActif}) => {
  const {devis} = useContext(DevisContext);
  const choixIcon = (type) => {
    switch (type) {
      case 'Electricité':
        return ElectriciteIcon
      case 'Borne':
        return BorneIcon
      case 'Panneaux photo voltaïque':
        return PVIcon
      case 'Alarme':
        return AlarmeIcon
      case 'Caméra':
        return CameraIcon
      default :
        return null
    }
  }

  /*Recuperation des éléments a affiché dans l'hexagone du prix*/
  const ServicesItem = [
    {
      icon: choixIcon(type),
      nom: type,
    },
  ];

  /*Recuperation des éléments a affiché dans l'hexagone Assistant*/
  const Assistant = [
    {
      nom: "Services plus +",
    },
  ];
  const domotique = [
    {
      icon: domotiqueIcon,
      nom: "Domotique",
    },
  ];
  const connecte = [
    {
      icon: connecteIcon,
      nom: "Connectée",
    },
  ];

  return (
    <div className="recapitulatifDevis">
      <ScrollTop key={type}/>
      <div className="prix borderArrondi recupererDevis">
        <div className="center marginTop titreEnvoieDevis">Vous obtenez le prix et les services suivant.</div>
        <div className="boxEvenly">
          <div className="positionZonePrix">
            <div className="prixHexa">
              <div className="hexagoneBleu positionHexaCase">
                <HexagoneAssistant
                  ServicesItem={ServicesItem}
                />
              </div>
              <div className="boxPrixDevis borderBleu">
                <div className="marginTop">
                  <div className="gras affichagePrixDevis center">
                    {type !== "Panneaux photo voltaïque" ?
                      <div>{codePromoActif  === false ?
                        <div>{donneeDevis?.prix}€ TTC</div>
                        : <div>
                          <div className="barrerTexte">{donneeDevis?.prix}€ TTC</div>
                          <div className="textRose">{donneeDevis?.prixSansPromo?.prix}€ TTC</div>
                        </div>
                      }

                      </div>
                      :<div>{codePromoActif  === false ?
                        <div>{donneeDevis?.prixPVMin}€ TTC et {donneeDevis?.prixPVMax}€ TTC</div>
                        :<div>
                          <div className="barrerTexte">{donneeDevis?.prixSansPromo?.prixPVMin}€ TTC et {donneeDevis?.prixSansPromo?.prixPVMax}€ TTC</div>
                          <div className="textRose">entre {Math.round((donneeDevis?.prixPVMin + Number.EPSILON) * 100) / 100}€ TTC et {Math.round((donneeDevis?.prixPVMax + Number.EPSILON) * 100) / 100}€ TTC</div>
                        </div>
                      }
                      </div>
                    }
                  </div>
                  <div className="center">TVA de
                    {devis?.construction?.nom === 'Nouvelle construction' && type !== "Panneaux photo voltaïque" ? ' 21' : ' 6'}%
                  </div>
                  <div>
                    <div className='center texteInfoService'
                         dangerouslySetInnerHTML={{__html: donneeDevis?.titreMateriel}}></div>
                    <div className="marginBottom texteService"
                         dangerouslySetInnerHTML={{__html: donneeDevis?.texteMateriel}}></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="servicesEspace">
            <div className='center marginBottom texteInfoService textBleu'
                 dangerouslySetInnerHTML={{__html: donneeDevis?.titreService}}></div>
            <div className="marginCote marginBottom texteService"
                 dangerouslySetInnerHTML={{__html: donneeDevis?.texteService}}></div>
          </div>
        </div>
      </div>
      {type === "Electricité" ?
        <div className="prix borderArrondi recupererDevis">

          <div>
            <div className="center marginTop titreEnvoieDevis">Les options supplémentaires</div>
            <div className="boxEvenly">
              <div className="positionZonePrix">
                <div className="prixHexa">
                  <div className="hexagoneVert positionHexaCase">
                    <HexagoneAssistant
                      ServicesItem={connecte}
                    />
                  </div>
                  <div className="boxPrixDevis borderVert">
                    <div className="marginTop">
                      <div className="gras affichagePrixDevis center">{codePromoActif  === false ?
                        <div>{donneeDevis?.prixConnecte}€ TTC</div>
                        :<div>
                          <div className="barrerTexte">{donneeDevis?.prixConnecte}€ TTC</div>
                          <div className="textRose">{donneeDevis?.prixSansPromo?.prixConnecte}€ TTC</div>
                        </div>
                      }</div>
                      <div className="center">TVA de
                        {devis?.construction.nom === 'Nouvelle construction' ? ' 21' : ' 6'}%
                      </div>
                      <div>
                        <div className='center texteInfoService'
                             dangerouslySetInnerHTML={{__html: donneeDevis?.titreMateriel}}></div>
                        <div className="marginBottom texteService"
                             dangerouslySetInnerHTML={{__html: donneeDevis?.texteConnecte}}></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="positionZonePrix">
                <div className="prixHexa">
                  <div className="hexagoneJaune positionHexaCase">
                    <HexagoneAssistant
                      ServicesItem={domotique}
                    />
                  </div>
                  <div className="boxPrixDevis borderJaune">
                    <div className="marginTop">
                      <div className="gras affichagePrixDevis center">{codePromoActif  === false ?
                        <div>{donneeDevis?.prixDomotique}€ TTC</div>
                        :<div>
                          <div className="barrerTexte">{donneeDevis?.prixDomotique}€ TTC</div>
                          <div className="textRose">{donneeDevis?.prixSansPromo?.prixDomotique}€ TTC</div>
                        </div>
                      }</div>
                      <div className="center">TVA de
                        {devis?.construction.nom === 'Nouvelle construction' ? ' 21' : ' 6'}%
                      </div>
                      <div>
                        <div className='center texteInfoService'
                             dangerouslySetInnerHTML={{__html: donneeDevis?.titreMateriel}}></div>
                        <div className="marginBottom texteService"
                             dangerouslySetInnerHTML={{__html: donneeDevis?.texteDomotique}}></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        : <div/>}
      <div className="prix borderArrondi recupererDevis">
        <div className="center marginTop titreEnvoieDevis">Les services plus</div>
        <div className="positionHexa">
          <div className="positionZonePrix">
            <div className="prixHexa">
              <div className="hexagoneRose positionHexaCase">
                <HexagoneAssistant
                  ServicesItem={Assistant}
                />
              </div>
              <div className="boxPrixDevis borderRose">
                <div className="marginTop">
                  <div className="gras affichagePrixDevis center">{donneeDevis?.prixAssistant}€ TTC</div>
                  <div className="center">TVA de
                    {devis?.construction?.nom === 'Nouvelle construction' && type !== "Panneaux photo voltaïque" ? ' 21' : ' 6'}%
                  </div>
                  <div>
                    <div className="center texteInfoService"
                         dangerouslySetInnerHTML={{__html: donneeDevis?.titreAssistance}}></div>
                    <div className="marginBottom texteService"
                         dangerouslySetInnerHTML={{__html: donneeDevis?.texteAssistance}}></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RecapitulatifDevis;