import {Button, Snackbar} from "@mui/material";
import React, {useState} from "react";
import axios from "axios";
import MuiAlert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";

const BoutonSauvegarder = ({nom, description, image, id, position, date, setDate, setEtatSauvegarde, maxPosition, onClick}) =>{
  const [open, setOpen] = React.useState(false);
  const [messageErreur, setMessageErreur] = useState('');
  const [severity, setSeverity] = useState("success");

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  /*Ouvrir le message d'erreur*/
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  /*Event pour sauvegarder actualité et upload l'image si y en a une*/
  const sauvegarderClick = () => {
    if(position <=maxPosition && position >=1){
    var formData = new FormData();
    formData.append("id", id);
    formData.append("image", image);
    formData.append("nom", nom);
    formData.append("position", position);
    formData.append("dateDebut", date[0]);
    formData.append("dateFin", date[1]);
    formData.append("description", description);
    axios.post('https://api.jelefaismoimeme.be/sauvegarderActualite.php', formData,{
      headers: {
        'Content-Type': 'multipart/form-data'
      },
    })
      .then((response) => {
        onClick();
        setOpen(true);
        if(response.data.includes("Erreur")){
          setSeverity("error")
          setMessageErreur(response.data)
        }
        else{
          setSeverity("success")
          setMessageErreur("L'actualité a été sauvegarder")
          setEtatSauvegarde(true)
        }
      });
    }
    else{
      setOpen(true);
      setSeverity("error")
      setMessageErreur("Position incorrecte")
    }
  };

  return(
    <div>
      <div className="modifbtn">
        <Button variant="contained" onClick={sauvegarderClick}>Sauvegarder</Button>
      </div>
      <Stack spacing={2} sx={{ width: '100%' }}>
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert className="alert" onClose={handleClose} severity={severity} sx={{ width: '400px'}}>
            {messageErreur}
          </Alert>
        </Snackbar>
      </Stack>
    </div>
  );
}

export default BoutonSauvegarder;