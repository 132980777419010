import './ConfigurationElectricite.css'
import * as React from "react";
import ConfigNiveau from "./ConfigNiveau/ConfigNiveau";
import {useContext} from "react";
import {TutoContext} from "../../../../../Context/TutoContext";

const ConfigurationElectricite = () => {
  const { fermetureTuto } = useContext(TutoContext);

  return(
    <div className="contenuEtape fontAssisant">
      <div className="titreEtape texteInfoService center">Configurer les pièces de votre habitation</div>
      <div className={fermetureTuto === false ? "flouTuto center marginBottom marginCote" : "center marginBottom marginCote"}>
        Ci-dessous vous pouvez configurer vos pièces en choisissant ou modifiant les éléments prédéfinies et conseillés
        pour chacune de vos pièces que vous avez choisis par niveau dans l’étape précédente.
      </div>
      <div className="configuration">
        <ConfigNiveau />
      </div>
    </div>
  );
}

export default ConfigurationElectricite;