import React from 'react';
import { SvgIcon } from "@mui/material";

function AlarmeIcon(props) {
  return (
    <SvgIcon {...props} viewBox="0 0 270.86 397.18">
      <path d="M205.79,82.76l-.51,61.79,31.15-.51L238,78.68a100,100,0,0,0-24.51-47.49C185.1,1,147,.24,137.36,0c-8-.17-53.43-1.11-84.25,34.21A100.43,100.43,0,0,0,30.64,80.72V144l31.66,1,1-63.32c9.74-32.84,40.51-54.95,74-53.62C169.19,29.38,197,51.5,205.79,82.76Z"/>
      <path d="M236.68,152.4,33.79,153.55A34,34,0,0,0,0,187.53V363.2a34,34,0,0,0,34.11,34l201.42-.76a34,34,0,0,0,33.86-33.7l1.47-176.05A34,34,0,0,0,236.68,152.4ZM102.13,346.76l15.83-73c-13.08-8.34-18.65-24.23-13.79-37.79,4.76-13.29,18.1-20.28,30.13-20.42,13.26-.16,28.76,7.94,32.17,23.49,2.94,13.41-4.14,27.76-17.36,34.21l18.89,74Z"/>
    </SvgIcon>
  );
}

export default AlarmeIcon;