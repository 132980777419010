import {useContext, useEffect, useState} from "react";
import {DevisContext} from "../../../../../Context/DevisContext";
import FormControl from "@mui/material/FormControl";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import * as React from "react";

const Communication = () => {
  const { devis, setDevis } = useContext(DevisContext);
  const [valueCommunication, setValueCommunication] = useState(devis?.communication ?? '');

  useEffect(() => {
    setDevis((oldDevis) => ({
      ...oldDevis,
      communication:valueCommunication,
    }))
  }, [valueCommunication, setDevis]);

  const handleChange = (event) => {
    setValueCommunication(event.target.value);
  };

  return(
      <div>
        <div className="center marginBottom">Communication</div>
        <div className="radioBouton">
          <FormControl>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              defaultValue="Sonnette"
              value={valueCommunication}
              onChange={handleChange}
            >
              <FormControlLabel  value="Application GSM"
                                 control={<Radio sx={{color: '#0A90C9', '&.Mui-checked': {color: '#0A90C9',},}}/>}
                                 label="Application GSM" className={valueCommunication === "Application GSM" ? "" : "textGris"}/>
              <FormControlLabel value="Appel ou SMS sur GSM"
                                control={<Radio sx={{color: '#0A90C9', '&.Mui-checked': {color: '#0A90C9',},}}/>}
                                label="Appel ou SMS sur GSM" className={valueCommunication === "Appel ou SMS sur GSM" ? "" : "textGris"}/>
            </RadioGroup>
          </FormControl>
        </div>
      </div>
  );
}

export default Communication;