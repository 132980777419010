import React from 'react';
import {NavLink} from "react-router-dom";
import {Button} from "@mui/material";

export const Panneaux = {
  nom: 'Panneaux photovoltaïque',
  color: '#05AB33',
  colorHover: '#7ab98b',
  colorBackground: '#EFFAF2',
  description:
    <div>
      Je fais mon installation de panneaux photovoltaïque avec assistance, rien de plus simple.
      vous commencez par <NavLink to="/devis" ><span className="textRose">réaliser votre devis</span></NavLink> via notre configurateur simplifié et puis avec notre aide,
      nos <span className="textBleu">techniciens qualifiés</span> vous serez <span className="textRose">facilement faire des économies</span>, vous sentir satisfait et fier d’avoir
      réaliser votre propre installation électrique selon vos <span className="textRose">choix</span>, vos goûts, vos désirs et surtout votre budget.
    </div>,
  imageBanniere: 'https://entrepot.jelefaismoimeme.be/typeInstallation/panneaux.jpg',
  dossier: 'panneaux',
  categories: {
    "Maison individuelle": {
      triangleClass: 'triangleGauche',
      description: (
        <div>
          <div className="marginBottom TitreDescriptionExempleDevis gras">Configuration de base</div>
          <ul>
            <li>Toit incliné en tuile</li>
            <li>Longueur de 10m et largeur de 5m</li>
            <li>Compteur mono 230V</li>
            <li>Consommation annuelle de 10000kwh</li>
          </ul>
        </div>
      ),
    },
    Appartement: {
      triangleClass: 'triangleCentre',
      description: ( <div>Installation de panneaux photovoltaïque non disponible pour les appartements</div>),
    },
    "Maison mitoyenne": {
      triangleClass: 'triangleDroite',
      description: (
        <div>
          <div className="marginBottom TitreDescriptionExempleDevis gras">Configuration de base</div>
          <ul>
            <li>Toit incliné en tuile</li>
            <li>Longueur de 8m et largeur de 4m</li>
            <li>Compteur mono 230V</li>
            <li>Consommation annuelle de 4000kwh</li>
          </ul>
        </div>
      ),
    },
  },
  etapes: [
    <NavLink to="/devis"><Button className="btnDevis">Commencer mon devis</Button></NavLink>,
    <div>Rendez-vous chez vous ou à notre showroom pour <span className="gras">finaliser votre projet selon vos besoins et vos choix</span>.</div>,
    <div>Nous réalisons vos <span className="gras">plans précis</span> et <span className="gras">pré-configurons</span> votre demande.</div>,
    <div>Livraison et explication chez vous par un de nos techniciens pour <span className="gras">l'installation de votre matériel</span></div>,
    <div>Profitez de votre installation de <span className="gras">panneaux photovoltaïque</span>.</div>,
  ],
};
