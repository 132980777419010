import React from 'react';
import { SvgIcon } from "@mui/material";

function ConnecteIcon(props) {
  return (
    <SvgIcon {...props} viewBox="0 0 368.84 404.17">
      <path d="M161.92,20.63l2.47,1.82a4.38,4.38,0,0,1,.22,6.88l-154,129.18a6.77,6.77,0,0,1-9.19-1A6.69,6.69,0,0,1,1,149.85a3.92,3.92,0,0,1,.84-.93L156.53,20.78A4.39,4.39,0,0,1,161.92,20.63Z"/>
      <path d="M178.82,6l1.55-3.14a5.08,5.08,0,0,1,7.75-1.68L366.36,146.63a7.67,7.67,0,0,1,.74,10.51,7.81,7.81,0,0,1-8.59,2,4.19,4.19,0,0,1-1.23-.75L180.1,12A4.93,4.93,0,0,1,178.82,6Z"/>
      <path d="M353.13,175.6,183.31,39.15a4.22,4.22,0,0,0-5.35.06L21.23,171.07a4.2,4.2,0,0,0-1.5,3.15L17.28,328.36a4.21,4.21,0,0,0,4.21,4.28H91.35a4.19,4.19,0,0,0,4.2-4.31c-.57-20.23,10.23-39.12,27.87-48.28a52.3,52.3,0,0,1,47.5,0,4.21,4.21,0,0,0,6.15-3.71l.48-103.46a4,4,0,0,1,.14-1.08c5.08-19.39,22.32-32.8,41.22-32.66,19.22.14,35,14.25,38.81,27.57.76,2.69,1,8.6,1.53,20.43.28,6.42.41,13.83.47,18.19a4.2,4.2,0,0,0,3.61,4.11,37.11,37.11,0,0,1,20.21,9.65,4.17,4.17,0,0,0,4,1,29.62,29.62,0,0,1,33.71,12.38,4.2,4.2,0,0,0,4,1.88l24.77-2.84a4.22,4.22,0,0,0,3.73-4.11L354.7,179A4.19,4.19,0,0,0,353.13,175.6Zm-281.79-14A2.55,2.55,0,0,1,73.89,159h18.9a2.55,2.55,0,0,1,2.55,2.55v34.21a2.55,2.55,0,0,1-2.55,2.55H73.89a2.55,2.55,0,0,1-2.55-2.55ZM95.59,243.2A2.55,2.55,0,0,1,93,245.75H74.15a2.56,2.56,0,0,1-2.56-2.55V209a2.56,2.56,0,0,1,2.56-2.55H93A2.55,2.55,0,0,1,95.59,209Zm31.41.59a2.56,2.56,0,0,1-2.56,2.55H105.55a2.55,2.55,0,0,1-2.55-2.55V209.58a2.55,2.55,0,0,1,2.55-2.55h18.89a2.56,2.56,0,0,1,2.56,2.55Zm.17-47.83a2.56,2.56,0,0,1-2.56,2.55H105.72a2.55,2.55,0,0,1-2.55-2.55V161.75a2.55,2.55,0,0,1,2.55-2.55h18.89a2.56,2.56,0,0,1,2.56,2.55Z"/>
      <path d="M335.26,403.83l-12.07.34a3.33,3.33,0,0,1-2.38-5.77,45.72,45.72,0,0,0,14.49-27,3.15,3.15,0,0,0,0-.53q-.18-49.71-.34-99.44a3.05,3.05,0,0,0-.08-.74,10.25,10.25,0,0,0-19.87-.8,3,3,0,0,0-.13,1l.33,40.53a3.26,3.26,0,0,1-.13,1,7.82,7.82,0,0,1-15,0,3.1,3.1,0,0,1-.14-1l.33-50.56c-.31-5.81-4.78-10.29-9.87-10.55a10.57,10.57,0,0,0-9.35,5.2,3.36,3.36,0,0,0-.52,1.79v39c.54,6.67-3.9,11.5-7.49,11.57s-8.54-4.91-7.83-11.91l.34-47.61a3.21,3.21,0,0,0-.3-1.4,13.08,13.08,0,0,0-23-1.32,3.48,3.48,0,0,0-.53,1.89q.51,21.66,1,43.33a6.81,6.81,0,1,1-13.61-.34l-.34-111.15a3.37,3.37,0,0,0-.15-1,11.26,11.26,0,0,0-21.46-.38A3.12,3.12,0,0,0,207,179V329.13a1.48,1.48,0,0,1,0,.21c-.1,1.52-.61,6.15-4.08,8.75-4.7,3.53-13.37,2.21-19.4-4.77L163.76,312.9c-.93-1-8.24-8.77-19.06-7.83a21.87,21.87,0,0,0-9.13,2.93,3.35,3.35,0,0,0-1,4.94l64.61,83.88a3.34,3.34,0,0,1-2.59,5.38l-12.78.2a3.35,3.35,0,0,1-2.7-1.29l-64.73-83.66a3.35,3.35,0,0,1-.7-1.89,22.61,22.61,0,0,1,4.5-14.92c4.63-5.84,11.11-7.17,16-8.17,12.76-2.61,23.25,1.93,26.55,3.41a48.48,48.48,0,0,1,20.89,18.28,3.33,3.33,0,0,0,2.68,1.53l2.93.13a3.34,3.34,0,0,0,3.48-3.32l.66-139.88a3.41,3.41,0,0,1,.24-1.27,25.76,25.76,0,0,1,48.27,1.45,3.54,3.54,0,0,1,.18,1.08l.3,48.26a3.34,3.34,0,0,0,4.18,3.2,24.33,24.33,0,0,1,27.1,11.27,3.33,3.33,0,0,0,4.25,1.26A24.46,24.46,0,0,1,292.1,236c9.76,1.69,15.16,9,17.17,12.35a3.31,3.31,0,0,0,2.85,1.61H320a3.43,3.43,0,0,0,.71-.08,24.48,24.48,0,0,1,29.58,20.24,4.38,4.38,0,0,1,0,.51v94.49a51.76,51.76,0,0,1-1.71,17.26,52.29,52.29,0,0,1-10.93,20.36A3.3,3.3,0,0,1,335.26,403.83Z"/>
    </SvgIcon>
  );
}

export default ConnecteIcon;