import React from "react";
import useActualite from "../Hook/useActualite";
import useVideo from "../Hook/useVideo";
import usePromo from "../Hook/usePromo";
import usePopup from "../Hook/usePopup";
import useAvis from "../Hook/useAvis";

export let AdminContext = React.createContext({
  actualite: {
    listActu: [],
    refresh: () => {},
  },
  video: {
    video: [],
    refresh: () => {},
  },
  promo: {
    listPromo: [],
    refresh: () => {},
  },
  popup: {
    popup: [],
    refresh: () => {},
  },
  avis: {
    listAvis: [],
    refresh: () => {},
  },
})

export const AdminProvider = ({ children }) => {
  const value = {
    actualite: useActualite(),
    video: useVideo(),
    promo: usePromo(),
    popup: usePopup(),
    avis: useAvis(),
  }
  return <AdminContext.Provider value={value}>{children}</AdminContext.Provider>;
};