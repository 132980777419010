import HexagoneAssistant from "../../HexagoneAssistant/HexagoneAssistant";
import {useContext, useEffect, useState} from "react";
import {DevisContext} from "../../../../../Context/DevisContext";

const Compteur = ({onClickHexa}) => {
  const { devis, setDevis } = useContext(DevisContext);
  const [selectionneCompteur, setSelectionneCompteur] = useState(devis?.compteur ?? null);
  const compteur = [
    {
      nom:"Mono 230V",
    },
    {
      nom:"Triphasé 230V",
    },
    {
      nom:"Triphasé 400V",
    },
    {
      nom:"Non connu",
    },
  ];

  useEffect(() => {
    setDevis((oldDevis) => ({
      ...oldDevis,
      compteur: selectionneCompteur,
    }))
  }, [selectionneCompteur, setDevis])
  return(
    <div className="contenuEtape fontAssisant">
      <div className="titreEtape texteInfoService center">Quel type de compteur avez-vous ?</div>
      <div className="hexagoneCentrer">
        <HexagoneAssistant
          ServicesItem={compteur}
          setSelectionHexagone={setSelectionneCompteur}
          selectionHexagone={selectionneCompteur}
          onClickHexa={onClickHexa}
        />
      </div>
    </div>
  );
}

export default Compteur;