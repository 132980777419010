import './ModifAvis.css'
import BoutonSupprimer from "../BoutonSupprimer/BoutonSupprimer";
import React, {useCallback, useContext, useEffect, useState} from "react";
import {AdminContext} from "../../../../Context/AdminContext";
import BoutonSauvegarderAvis from "../Sauvegarder/BoutonSauvegarderAvis";
import Etoiles from "../../../../Globale/Composants/etoiles/Etoiles";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import FormControl from "@mui/material/FormControl";

const ModifAvis = ({avis, setZoneModifSelectionne}) => {
  const {avis: { listAvis: AvisItems, refresh} } = useContext(AdminContext);
  const [id, setId] = useState('');
  const [nom, setNom] = useState('');
  const [note, setNote] = useState(0);
  const [commentaire, setCommentaire] = useState('');
  const [valide, setValide] = useState('');

  const reset = useCallback(() => {
    setZoneModifSelectionne(null);
  }, [setZoneModifSelectionne]);

  useEffect(() => {
    if (avis?.id) {
      setId(avis.id)
      setNom(avis.nom)
      setNote(avis.note)
      setCommentaire(avis.commentaire)
      setValide(avis.valide)
    }
  }, [avis])

  if (!avis) return (
    <div className="modifAvis">
      <div/>
      <div className="elementAValider center" >
        <div className="titreAvis marginTop marginBottom">Avis validé</div>
        <div>
          {AvisItems?.map((item , idItem) => (
            <div
              key={'ComposantItems' + idItem}
              onClick={() => setZoneModifSelectionne(item)}
              className="survole"
            >
              {item.valide === 'true' ?
                <div className="alignementHorizontal">
                  <div className={avis && (avis.id ?? avis) === (item.id ?? item) ? "zoneSelectionne" : 'ListeElement2'}>
                    <div className="item">{item.nom}</div>
                    <BoutonSupprimer id={item.id} composant="Avis" onClick={reset}/>
                  </div>
                </div>
              :<div/>
              }
            </div>
          ))}
        </div>
      </div>
    </div>
  );
  return(
    <div className="modifAvis">
      <div>
        <BoutonSauvegarderAvis id={id} note={note} nom={nom} valide={valide} commentaire={commentaire} onClick={refresh}/>
        <div className="espacementbox marginTop">
          <div>Validation de l'avis</div>
          <FormControl>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue="euro"
              name="radio-buttons-group"
              value={valide}
              onChange={event => setValide(event.target.value)}
            >
              <FormControlLabel value={true} control={<Radio />} label="avis validé" />
              <FormControlLabel value={false} control={<Radio />} label="avis non validé" />
            </RadioGroup>
          </FormControl>
        </div>
        <div className="espacementbox marginTop">
          <div>Nom</div>
          <input type={"text"} className="borderArrondi espacebox" value={nom} onChange={event => setNom(event.target.value)}/>
        </div>
        <div className="espacementbox marginTop">
          <div>Note</div>
          <Etoiles note={note} setNote={setNote}/>
        </div>
        <div className="espacementbox marginTop">
          <div>Avis</div>
          <textarea value={commentaire} className="boxtextarea borderArrondi" onChange={event => setCommentaire(event.target.value)}/>
        </div>
      </div>
      <div className="elementAValider center" >
        <div className="titreAvis marginTop marginBottom">Avis validé</div>
        <div >
          {AvisItems?.map((item , idItem) => (
            <div
              key={'ComposantItems' + idItem}
              onClick={() => setZoneModifSelectionne(item)}
              className="survole"
            >
              {item.valide === 'true' ?
                <div className="alignementHorizontal">
                  <div className={avis && (avis.id ?? avis) === (item.id ?? item) ? "zoneSelectionne" : 'ListeElement2'}>
                    <div className="item">{item.nom}</div>
                    <BoutonSupprimer id={item.id} composant="Avis" onClick={reset}/>
                  </div>
                </div>
                :<div/>
              }
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default ModifAvis;