import './ModifImages.css'
import BoutonImporterImage from "./BoutonImporterImage";
import React from "react";
import BoutonSupprimerImage from "../BoutonSupprimerImage/BoutonSupprimerImage";
import useGalerie from "../../../../Hook/useGalerie";

const ModifImages = ({elementSelectionne,dossier}) =>{
  const dossierSansMaj = dossier?.toString()?.toLowerCase();
  const dossierSansAccent = dossierSansMaj?.toString()?.normalize('NFD')?.replace(/([\u0300-\u036f]|[^0-9a-zA-Z])/g, '');
  elementSelectionne = elementSelectionne?.toString()?.toLowerCase()?.normalize('NFD')?.replace(/([\u0300-\u036f]|[^0-9a-zA-Z])/g, '');

  const { listImage, refresh } = useGalerie({ composant: elementSelectionne, dossier: typeof dossier === "string" ? dossierSansAccent : null });

  if (!dossier && elementSelectionne!== "partenaire") return null;

  return(
    <div className="ModifImages">
      <div className="positionBoutonImage">
        <BoutonImporterImage composant={elementSelectionne} dossier={dossierSansAccent} onClick={refresh}/>
      </div>
      <div className="positionListImage">
        {listImage.map((item , idItem) => (
          <div key={'ComposantItems' + idItem + item}>
            <div className="imagelist">
              <BoutonSupprimerImage composant={elementSelectionne} dossier={dossierSansAccent} element={item} onClick={refresh}/>
              {elementSelectionne === "galerie"?(
                <img src={"https://entrepot.jelefaismoimeme.be/"+elementSelectionne+"/"+dossierSansAccent+"/"+item} alt="image galerie" className="imageModif"/>
              ):
                <img src={"https://entrepot.jelefaismoimeme.be/"+elementSelectionne+"s/"+item} alt="image partenaire" className="imageModif"/>
              }
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default ModifImages;