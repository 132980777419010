
export const ListeNiveau =[
  {
    nom: "Rez de chaussée",
    defautNiveau : [
      "Appartement","Maison mitoyenne","Maison individuelle"
    ]
  },
  {
    nom: "Etage 1",
    defautNiveau : [
      "Maison mitoyenne","Maison individuelle"
    ]
  },
  {
    nom: "Etage 2",
    defautNiveau : []
  },
  {
    nom: "Grenier",
    defautNiveau : [
      "Maison mitoyenne","Maison individuelle"
    ]
  },
  {
    nom: "Cave",
    defautNiveau : [
      "Maison individuelle"
    ]
  },
  {
    nom: "Extérieur",
    defautNiveau : []
  }
];