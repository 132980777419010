import './Avis.css'
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import {Rating} from "@mui/material";
import React, {useEffect, useState} from 'react';
import axios from "axios";

const Avis = () => {
  const [avis, setAvis] = useState([]);

  useEffect(() => {
    axios.post('https://api.jelefaismoimeme.be/afficheAvis.php')
      .then((response) => {
        setAvis(response.data ?? [])
      });
  },[setAvis])

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <div className="avis">
      <Carousel
        responsive={responsive}
        autoPlay={true}
        autoPlaySpeed={5000}
        infinite={true}
        className="carousel"
      >
        {avis.map((item , idItem) => (
          <div
            key={'AvisItems' + idItem}
          >
            <div>{item.nom}</div>
            <div>
              <Rating name="read-only" value={item.note} readOnly className="etoile" />
            </div>
            <div className="descriptionAvis">{item.commentaire}</div>
          </div>
        ))}
      </Carousel>
    </div>
  );
}

export default Avis;