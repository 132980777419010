import './ListeElements.css'
import React, {useCallback, useContext, useEffect, useMemo} from "react";
import BoutonAjouter from "../BoutonAjouter/BoutonAjouter";
import {AdminContext} from "../../../../Context/AdminContext";
import BoutonSupprimer from "../BoutonSupprimer/BoutonSupprimer";
import {Electricite} from "../../../ExempleDevis/Electricite";
import {Borne} from "../../../ExempleDevis/Borne";
import {Panneaux} from "../../../ExempleDevis/Panneaux";
import {Camera} from "../../../ExempleDevis/Camera";
import {Alarme} from "../../../ExempleDevis/Alarme";

/*La 2eme colonne de la page admin*/
const ListeElement = ({ElementSelectionne, ZoneModifSelectionne, setZoneModifSelectionne}) =>{
  const {
    actualite: { listActu: EvenementItem },
    promo: { listPromo: PromoItems },
    video: { video: VideoItems },
    avis: { listAvis: AvisItems },
  } = useContext(AdminContext);

  useEffect(() => {
    setZoneModifSelectionne((value) => {
      const selectionne = VideoItems.find((video) => value && video?.id === value?.id);
      if (selectionne) return selectionne;
      return value;
    });
  }, [VideoItems, setZoneModifSelectionne]);
  
  const reset = useCallback(() => {
    setZoneModifSelectionne(null);
  }, [setZoneModifSelectionne]);

  /*Liste des catégories de galerie*/
  const GalerieItems = useMemo(() => ([
    'Accueil',
    Electricite.nom,
    Borne.nom,
    Panneaux.nom,
    Camera.nom,
    Alarme.nom,
    'Showroom',
  ]), []);

  /*Selectionne la liste a afficher par rapport a l'element selectionner dans la 1er colonne*/
  const selectList = useMemo(() => {
    switch (ElementSelectionne) {
      case 'Actualité': return EvenementItem;
      case 'Galerie': return GalerieItems;
      case 'Vidéo': return VideoItems;
      case 'Promo': return PromoItems;
      case 'Avis': return AvisItems;
      default: return [];
    }
  }, [EvenementItem, GalerieItems, VideoItems, PromoItems, AvisItems, ElementSelectionne]);

  /*Affiche le bouton ajouter dans le bas de la 2eme colonne dans la page admin*/
  const affichageBtnAjouter = useMemo(() => {
    switch (selectList) {
      case EvenementItem: return <BoutonAjouter composant={"actualite"}/>
      case GalerieItems: return <div/>
      case VideoItems: return <BoutonAjouter composant={"video"}/>
      case PromoItems: return <BoutonAjouter composant={"promo"}/>
      case AvisItems: return <BoutonAjouter composant={"avis"}/>
      default: return <div/>;
    }
  },[EvenementItem, GalerieItems, VideoItems, PromoItems, AvisItems, selectList]);

  /*Selectionne le nom donner dans le fichier json pour l'affichier dans la colonne*/
  const getNom = useCallback((element) => {
    switch (selectList) {
      case (EvenementItem): return element.nom;
      case (GalerieItems): return element;
      case (VideoItems): return element.video;
      case (PromoItems): return element.code;
      case (AvisItems): return element.nom;
      default: return '';
    }
  }, [EvenementItem, GalerieItems, VideoItems, PromoItems, AvisItems, selectList]);

  return(
    <div className="alignementCol ListeElement positionListeElement">
      <div >
        {selectList?.map((item , idItem) => (
          <div
            key={'ComposantItems' + idItem}
            onClick={() => setZoneModifSelectionne(item)}
            className="survole"
          >
            <div className="alignementHorizontal">
              <div className={ZoneModifSelectionne && (ZoneModifSelectionne.id ?? ZoneModifSelectionne) === (item.id ?? item) ? "zoneSelectionne" : 'ListeElement2'}>
                <div className="item">{getNom(item)}</div>
                {selectList !== GalerieItems && selectList === EvenementItem || (selectList === VideoItems && idItem !== 0) || selectList === PromoItems || selectList === AvisItems
                  ?(
                  <BoutonSupprimer id={item.id} composant={ElementSelectionne} onClick={reset}/>
                ): <div/>}
              </div>
            </div>
          </div>
        ))}
      </div>
      <div>
        {affichageBtnAjouter}
      </div>
    </div>
  );
}

export default ListeElement;