import React, {useEffect, useRef, useState} from "react";
import axios from "axios";

const Video = () => {
  const [changevideo, setChangeVideo] = useState("");
  const videoRef = useRef();

  /*déclenchez un loadévénement pour l'élément vidéo chaque fois qu'il y a une nouvelle URL*/
  useEffect(() => {
    videoRef.current?.load();
  }, [changevideo]);

  /*Appel API pour récuperer la bonne vidéo */
  useEffect(() => {
    axios.post('https://api.jelefaismoimeme.be/recupereVideo.php')
      .then((response) => {
        setChangeVideo(response.data)
      });
  })

  return(
    <video ref={videoRef} className='backgroundVideo' autoPlay loop muted>
      <source src={"https://entrepot.jelefaismoimeme.be/video/"+changevideo} type="video/mp4" />
    </video>
  );
}

export default Video;
