import HexagoneAssistant from "../../HexagoneAssistant/HexagoneAssistant";
import {useContext, useEffect, useState} from "react";
import {DevisContext} from "../../../../../Context/DevisContext";

const TypeBorne = ({onClickHexa}) => {
  const { devis, setDevis } = useContext(DevisContext);
  const [selectionneTypeBorne, setSelectionneTypeBorne] = useState(devis.typeBorne ?? null);
  const materiauxConstruction = [
    {
      nom:"Fixe",
    },
    {
      nom:"Transportable",
    },

  ];

  useEffect(() => {
    setDevis((oldDevis) => ({
      ...oldDevis,
      typeBorne: selectionneTypeBorne,
    }))
  }, [selectionneTypeBorne, setDevis])
  return(
    <div className="contenuEtape fontAssisant">
      <div className="titreEtape texteInfoService center">Voulez-vous une borne fixe ou transportable ?</div>
      <div className="hexagoneCentrer">
        <HexagoneAssistant
          ServicesItem={materiauxConstruction}
          setSelectionHexagone={setSelectionneTypeBorne}
          selectionHexagone={selectionneTypeBorne}
          onClickHexa={onClickHexa}
        />
      </div>
    </div>
  );
}

export default TypeBorne;