import './SliderDevis.css'
import * as React from 'react';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';

const SliderDevis = ({min, max, step, value, setValue}) => {

  const handleChange = (event, newValue) => {
    if (typeof newValue === 'number') {
      setValue(newValue);
    }
  };

  return(
    <div className="slider">
      <Box>
        <Slider
          value={value}
          defaultValue={0}
          aria-label="Default"
          valueLabelDisplay="auto"
          step={step}
          marks
          min={min}
          max={max}
          onChange={handleChange}
          sx={{ color:"#0A90C9"}}
        />
      </Box>
    </div>
  );
}

export default SliderDevis;