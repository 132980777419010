import './EtapeCommencerDevis.css'
import ElectriciteIcon from "../../../../Globale/Icons/ElectriciteIcon";
import CameraIcon from "../../../../Globale/Icons/CameraIcon";
import AlarmeIcon from "../../../../Globale/Icons/AlarmeIcon";
import React, {useCallback, useContext, useEffect, useState} from "react";
import HexagoneAssistant from "../HexagoneAssistant/HexagoneAssistant";
import {DevisContext} from "../../../../Context/DevisContext";
import BorneIcon from "../../../../Globale/Icons/BorneIcon";
import PVIcon from "../../../../Globale/Icons/PVIcon";
import {Button, Snackbar, TextField} from "@mui/material";
import axios from "axios";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import {LoadingButton} from "@mui/lab";
import Stack from "@mui/material/Stack";
import MuiAlert from "@mui/material/Alert";

const EtapeCommencerDevis = ({setRecupererDevis, setStatusEtape, setSelectionneService, selectionneService}) => {
  const { devis, setDevis } = useContext(DevisContext);
  const [clickHexa, onClickHexa] = useState(false);
  const urlParams = new URLSearchParams(window.location.search);
  const devisId = urlParams.get('id');
  const [numeroDevis, setNumeroDevis] = useState(devisId ?? '');
  const [clickBoutonNumeroDevis, setClickBoutonNumeroDevis] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [messageErreur, setMessageErreur] = useState('');
  const [severity, setSeverity] = useState("error");
  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  useEffect(() => {
    if(devisId !== null){
      validateNumeroDevis()
    }
  }, [devisId]);

  useEffect(() => {
    setDevis((oldDevis) => ({
      ...oldDevis,
      devis: selectionneService,
    }))
  }, [selectionneService, setDevis]);

  const ServicesItem = [
    {
      icon: ElectriciteIcon,
      nom:'Electricité',
    },
    {
      icon: AlarmeIcon,
      nom:'Alarme',
    },
    {
      icon: CameraIcon,
      nom:'Caméra',
    },
    {
      icon: PVIcon,
      nom:'Panneaux photo voltaïque',
    },
    {
      icon: BorneIcon,
      nom:'Borne',
    },
  ];

  useEffect(() => {
    if(clickHexa === true){
      const timer = setTimeout(() => {
        setStatusEtape(true)
      }, 150);
      return () => clearTimeout(timer);
    }
    onClickHexa(false)
  }, [clickHexa]);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const validateNumeroDevis = useCallback(() => {
    setClickBoutonNumeroDevis(true)
    setRecupererDevis(false)

    axios.get('https://morgane.kalanda.info/devisjlfmm/get_devis/'+numeroDevis, {
        headers: {
          'Authorization': '56AALyYdKfRQeX4#sd&Pb3ArktAhDd',
          'Content-Type': 'application/json',
        },
      }
    ).then((response) => {
      if(numeroDevis !== undefined || response.data !== "ERREUR"){  //Ajouter si api == erreur
        response.data[0].devis.devis.devis.icon = ServicesItem.find(item => item.nom === response.data[0].devis.devis.devis.nom)?.icon
        response.data[0].devis.devis.secret = numeroDevis;
        setDevis(response.data[0].devis.devis)
        setSelectionneService(response.data[0].devis.devis.devis)
        setStatusEtape(true)
        setRecupererDevis(true)
      }
      else{
        setClickBoutonNumeroDevis(false)
        setMessageErreur("Le numéro du devis est incorrecte")
        setOpen(true)
      }
      }
    );
  }, [numeroDevis, setDevis])

  //useEffect(() => console.log('DEVIS', devis), [devis]);
  return(
    <div>
        <div className="etapecommencerDevis" >
          <div className="devisTaillePage">
            <div className="espace">
              <h1 className="titreEtape titreDevis center" >Mon devis personnalisé en 5 minutes</h1>
            </div>
            <div className="fontAssisant">
              <div className="texteInfoService center" >Selectionner l'installation souhaitée</div>
              <div className="CommencerDevis">
                <HexagoneAssistant
                  ServicesItem={ServicesItem}
                  setSelectionHexagone={setSelectionneService}
                  selectionHexagone={selectionneService}
                  onClickHexa={onClickHexa}
                />
              </div>
              <div className="texteInfoDevis">
                Votre devis est sauvegardé automatiquement, vous recevrez au terme de ce configurateur un email, pour le reprendre
                ou le modifier au gré de vos envies.
              </div>
            </div>
            <div className="positionRecupererDevis fontAssisant">
              <div className="texteInfoService">Pour récuperer votre devis, entrer le numéro de celui-ci</div>
              <div className="recupererDevis">
                <TextField
                  helperText=""
                  id="demo-helper-text-aligned"
                  label="Récuperer numéro devis"
                  onInput={event => setNumeroDevis(event.target.value)}
                  value={numeroDevis}
                />
                <div className="boutonNumeroDevis">
                  {clickBoutonNumeroDevis === false ?
                    <Button variant="contained" onClick={validateNumeroDevis}><ArrowForwardIcon/></Button>
                    :<LoadingButton loading variant="outlined"></LoadingButton>
                  }
                </div>
              </div>
            </div>
          </div>
          <Stack spacing={2} sx={{width: '100%'}}>
            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
              <Alert className="alert" onClose={handleClose} severity={severity} sx={{width: '400px'}}>
                {messageErreur}
              </Alert>
            </Snackbar>
          </Stack>
        </div>
    </div>
  );
}

export default EtapeCommencerDevis;