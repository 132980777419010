import './Maps.css'
import React from 'react';

const Maps = () => {
  return(
    <div className="maps">
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2536.3138315261817!2d3.6408363156188734!3d50.52833287948611!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c2f95d657757d7%3A0x4519cf0be1ab844b!2sEasy%20Services%20-%20%C3%89lectricit%C3%A9%3B%20Domotique%2C%20S%C3%A9curit%C3%A9%2C%20Informatique%20avec%20ou%20sans%20assistance%20pour%20particulier%20et%20professionnelle!5e0!3m2!1sfr!2sbe!4v1645000724947!5m2!1sfr!2sbe"
        allowFullScreen="" loading="lazy"/>
    </div>
  );
}

export default Maps;