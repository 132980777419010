import React, {useState} from "react";

export const TutoContext = React.createContext({})

export const TutoProvider = ({ children }) => {
  const [fermetureTuto, setFermetureTuto] = useState(false);

  const value = {
    fermetureTuto, setFermetureTuto,
  }
  return <TutoContext.Provider value={value}>{children}</TutoContext.Provider>;
};