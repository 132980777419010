import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './Globale/Polices/AvenirNextLTPro-Bold.otf'
import './Globale/Polices/AvenirNextLTPro-It.otf'
import './Globale/Polices/AvenirNextLTPro-Regular.otf'
import App from "./Globale/Composants/Application/App";

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);
