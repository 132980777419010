import './Etoiles.css'
import {Rating} from "@mui/lab";

const Etoiles = ({setNote, note}) => {
  return(
    <div className="etoiles">
      <Rating
        name="size-large"
        defaultValue={0}
        value={note}
        onChange={event => setNote(event.target.value)}
        sx={{ fontSize: 50 }}/>
    </div>
  );
}

export default Etoiles;