import './CaseElement.css'
import React  from 'react';
import HexagoneIcon from "../../Icons/HexagoneIcon";
import {useState} from "react";

const CaseElement = ({icon, nomServices, color, colorBackground, hoverColor}) => {
  const [hover, setHover] = useState(false);
  return (
    <div className="caseHexagone hoverStyle" >
      <div>
        <HexagoneIcon style={{color: hover ?  hoverColor : color}}
        onPointerOver={()=> setHover(true)}
        onPointerOut={() => setHover(false)}
         className="styleHexagone"/>
      </div>
      <div className="element"
           onPointerOver={()=> setHover(true)}
           onPointerOut={() => setHover(false)}
      >
        <div className="iconCase" style={{color: "white"}}>{icon}</div>
        <div className="nomServiceCase" style={{color: 'white'}}>{nomServices}</div>
      </div>
    </div>
  );
}

export default CaseElement;