import {useCallback, useEffect, useState} from "react";
import axios from "axios";

const usePromo = () => {
  const [listPromo, setListPromo] = useState([]);

  const refresh = useCallback(() => {
    axios.post('https://api.jelefaismoimeme.be/promo.php')
      .then((response) => {
        setListPromo(response.data ?? []);
        //console.log(response.data.map(elm => elm.id));
      });
  }, []);

  useEffect(refresh,[refresh]);
  return { listPromo, refresh };
}

export default usePromo;