import React from 'react';
import { SvgIcon } from "@mui/material";

function AppartementIcon(props) {
  return (
    <SvgIcon {...props} viewBox="0 0 94 100">
      <path d="M0.845703 0V100H43.1534V84.6154H50.8457V100H93.1534V0H0.845703ZM8.53801 7.69231H85.4611V92.3077H58.538V76.9231H35.4611V92.3077H8.53801V7.69231ZM16.2303 15.3846V23.0769H31.6149V15.3846H16.2303ZM39.3072 15.3846V23.0769H54.6919V15.3846H39.3072ZM62.3842 15.3846V23.0769H77.7688V15.3846H62.3842ZM16.2303 30.7692V38.4615H31.6149V30.7692H16.2303ZM39.3072 30.7692V38.4615H54.6919V30.7692H39.3072ZM62.3842 30.7692V38.4615H77.7688V30.7692H62.3842ZM16.2303 46.1538V53.8462H31.6149V46.1538H16.2303ZM39.3072 46.1538V53.8462H54.6919V46.1538H39.3072ZM62.3842 46.1538V53.8462H77.7688V46.1538H62.3842ZM16.2303 61.5385V69.2308H31.6149V61.5385H16.2303ZM39.3072 61.5385V69.2308H54.6919V61.5385H39.3072ZM62.3842 61.5385V69.2308H77.7688V61.5385H62.3842ZM16.2303 76.9231V84.6154H31.6149V76.9231H16.2303ZM62.3842 76.9231V84.6154H77.7688V76.9231H62.3842Z"
            fill="currentColor"/>
    </SvgIcon>
  );
}

export default AppartementIcon;