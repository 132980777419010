import './TableauDevis.css'
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import * as React from "react";
import LigneTableauDevis from "./LigneTableauDevis/LigneTableauDevis";
import {useEffect, useMemo, useState} from "react";
import axios from "axios";
import { format } from 'date-fns'

const TableauDevis = () => {
  const [donneesDevis, setDonneesDevis] = useState([]);
  const [listStatus, setListStatus] = React.useState([]);

  function createData(date, numeroDevis, typeInstallation, status, prix, connecte, domotique, assistant, mail, telephone) {
    return { date, numeroDevis, typeInstallation, status, prix, connecte, domotique, assistant, mail, telephone };
  }

  const rows = useMemo(() => {
    return donneesDevis.map((item) => {
      let date = new Date(item.DateDevis);
      let json_site = JSON.parse(item.json_site);

      return createData(
        format(date, 'dd/MM/yyyy'),
        item?.code_secret ,
        json_site[0]?.devis?.devis?.devis?.nom ?? '',
        item?.IDstatus_devis,
        Math.round((json_site[0]?.devis?.calcul?.totalht + Number.EPSILON) * 100) / 100,
        Math.round((json_site[0]?.devis?.calcul?.totalhtconnecte + Number.EPSILON) * 100) / 100,
        Math.round((json_site[0]?.devis?.calcul?.totalhtdomo + Number.EPSILON) * 100) / 100,
        Math.round((json_site[0]?.devis?.calcul?.totalhtassistance + Number.EPSILON) * 100) / 100,
        item?.m_Email,
        item?.m_Téléphone)
    })
  }, [donneesDevis])

  useEffect(() => {
    axios.get('https://morgane.kalanda.info/devisjlfmm', {
        headers: {
          'Authorization': '56AALyYdKfRQeX4#sd&Pb3ArktAhDd',
          'Content-Type': 'application/json',
        },
      }
    ).then((response) => {
      setDonneesDevis(response.data.reverse())
    })

    axios.get('https://morgane.kalanda.info/status_devis', {
        headers: {
          'Authorization': '56AALyYdKfRQeX4#sd&Pb3ArktAhDd',
          'Content-Type': 'application/json',
        },
      }
    ).then((response) => {
      setListStatus(response.data)
    })
  }, [])

  return(
    <div className="tableauDevis">
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="center"/>
              <TableCell align="center">Date</TableCell>
              <TableCell align="center">Numéro du devis</TableCell>
              <TableCell align="center">Type d'installation</TableCell>
              <TableCell align="center">Status</TableCell>
              <TableCell align="center">Prix</TableCell>
              <TableCell align="center">Prix connecté</TableCell>
              <TableCell align="center">Prix domotique</TableCell>
              <TableCell align="center">Prix assistant +</TableCell>
              <TableCell align="center">Contact</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((itemLigne, itemRow) => (
                <LigneTableauDevis key={itemRow} ligne={itemLigne} id={itemRow} setDonneesDevis={setDonneesDevis} donneesDevis={donneesDevis} listStatus={listStatus} setListStatus={setListStatus}/>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

export default TableauDevis;