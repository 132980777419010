import React, {useEffect, useState} from "react";

export let DevisContext = React.createContext({
  devis: {},
  setDevis: () => {},
})

export const DevisProvider = ({ children }) => {
  const [devis, setDevis] = useState({});
  useEffect(() => {
  }, [devis]);
  const value = {
    devis, setDevis,
  }
  return <DevisContext.Provider value={value}>{children}</DevisContext.Provider>;
};