import TableCell from "@mui/material/TableCell";
import {Collapse} from "@mui/material";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import * as React from "react";
import {
  ListDetailsConnecte,
  ListDetailsDomotique,
  ListDetailsElectriciteGeneraux,
  ListDetailsClassique,
} from "../ListDetailsTableau";
import {useMemo} from "react";

const TableauDetails = ({openCollapse, donnees, valuePrix}) => {
  let json_site = JSON.parse(donnees.json_site);

  const details = useMemo(() => {
    switch (valuePrix){
      case 1 : return [...ListDetailsClassique(json_site), ...ListDetailsElectriciteGeneraux(json_site)];
      case 2 : return [...ListDetailsConnecte(json_site),...ListDetailsElectriciteGeneraux(json_site)];
      case 3 : return [...ListDetailsDomotique(json_site), ...ListDetailsElectriciteGeneraux(json_site)];
      default: break;
    }
  }, [json_site, valuePrix]);

  return(
    <TableRow className="tableauDetails">
      <TableCell colSpan={10}>
        <Collapse in={openCollapse} timeout="auto" unmountOnExit>
          <Box>
            <Table size="small" aria-label="purchases">
              <TableHead>
                <TableRow>
                  <TableCell style={{padding: "10px"}}>Nom</TableCell>
                  <TableCell style={{padding: "10px"}}>Valeur</TableCell>
                  <TableCell style={{padding: "10px"}}>Prix d’achat</TableCell>
                  <TableCell style={{padding: "10px"}}>Prix de vente</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {details.map((historyRow, idRow) => (
                  <TableRow key={idRow}>
                    <TableCell style={{paddingLeft: "50px"}}>{historyRow.nom}</TableCell>
                    <TableCell style={{paddingRight: "50px"}}>{historyRow.valeur}</TableCell>
                    <TableCell style={{paddingRight: "50px"}}>{historyRow?.prixAchat}</TableCell>
                    <TableCell style={{paddingRight: "50px"}}>{historyRow?.prixVente}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
        </Collapse>
      </TableCell>
    </TableRow>
  );
}

export default TableauDetails;