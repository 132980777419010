import './Superficie.css'
import {useContext, useEffect, useState} from "react";
import {DevisContext} from "../../../../../Context/DevisContext";
import * as React from "react";
import SliderDevis from "../Slider/SliderDevis";

const Superficie = () => {
  const { devis, setDevis } = useContext(DevisContext);
  const [longueur, setLongueur] = useState(devis?.superficie?.longueur ?? null);
  const [largeur, setLargeur] = useState(devis?.superficie?.largeur ?? null);

  useEffect(() => {
    setDevis((oldDevis) => ({
      ...oldDevis,
      superficie: {
        longueur:longueur,
        largeur:largeur,
      },
    }))
  }, [largeur, longueur, setDevis]);

  return(
    <div className="contenuEtapeSuperficie fontAssisant superficieCheck ">
      <div className="titreEtape texteInfoService center">Quel est la superficie de votre toiture ?</div>
      <div className="boxSuperficie checkGeneralePosition">
        <div>
          <div className="superficie">
            <div className="texteLongPiece">Indiquer la longueur de votre toiture :</div>
            <div className="sliderPosisition marginTop">
              <SliderDevis min={0} max={25} step={1} value={longueur} setValue={setLongueur}/>
              <div className="distance">{longueur} m</div>
            </div>
          </div>
          <div className="superficie">
            <div className="texteLongPiece">Indiquer la largeur de votre toiture:</div>
            <div className="sliderPosisition marginTop">
              <SliderDevis min={0} max={15} step={1} value={largeur} setValue={setLargeur}/>
              <div className="distance">{largeur} m</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default  Superficie;