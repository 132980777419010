import './ConfigPiece.css'
import * as React from "react";
import {Button} from "@mui/material";
import CheckBoxConfig from "../CheckBoxConfig/CheckBoxConfig";
import NombrePrise from "../NombrePrise/NombrePrise";
import Eclairage from "../Eclairage/Eclairage";
import {useContext, useEffect, useState} from "react";
import {DevisContext} from "../../../../../../Context/DevisContext";
import TooltipInformation from "../tooltipInformation/TooltipInformation";
import {
  listDimensionLongueur,
  listEclairageDefaut,
  listPrisesDefaut,
  listPrisesMultiDefaut,
  listPrisesSpecialesDefaut,
  listAutreOptionDefaut, listDimensionLargeur,
} from "../../ListeConfigurationPiece";

const ConfigPiece = ({nom, pieceIndex, piecelong}) => {
  const { devis, setDevis } = useContext(DevisContext);
  const [longueur, setLongueur] = useState(devis?.configPiece?.[nom]?.autre?.longueur ?? listDimensionLongueur(nom));
  const [largeur, setLargeur] = useState(devis?.configPiece?.[nom]?.autre?.largeur ?? listDimensionLargeur(nom));
  const [DCPiece, setDCPiece] = useState(devis?.configPiece?.[nom]?.autre?.DCPiece ??(
    nom === "Chalet" ? 15 : 10));

  const [listEclairage, setListEclairage] = useState(devis?.configPiece?.[nom]?.eclairage ??
    listEclairageDefaut(nom))
  const [listPrises, setListPrises] = useState(devis?.configPiece?.[nom]?.prises ??
    listPrisesDefaut(nom))
  const [listPrisesMulti, setListPrisesMulti] = useState( devis?.configPiece?.[nom]?.prisesMulti ??
    listPrisesMultiDefaut(nom))
  const [listPrisesSpeciales, setListPrisesSpeciales] = useState( devis?.configPiece?.[nom]?.prisesSpeciales ??
    listPrisesSpecialesDefaut(nom))
  const [listAutreOption, setListAutreOption] = useState( devis?.configPiece?.[nom]?.autre?.option ??
    listAutreOptionDefaut(nom))

  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => setLoading(false), 200);
  }, [nom]);


  useEffect(() => {
    setDevis((oldDevis) => ({
      ...oldDevis,
      configPiece: {
        ...oldDevis?.configPiece,
          [nom]:{
            eclairage:listEclairage.map(({interrupteurs}) => ({ interrupteurs})),
            autre:{
              DCPiece:DCPiece,
              longueur:longueur,
              largeur:largeur,
              option:listAutreOption.map(({nom, option}) => ({ nom, option })),
            },
            prises:listPrises.map(({nom, valeur}) => ({ nom, valeur })),
            prisesMulti:listPrisesMulti.map(({nom, valeur}) => ({ nom, valeur })),
            prisesSpeciales:listPrisesSpeciales.map(({nom, valeur}) => ({ nom, valeur })),
          }
      },
    }))
  }, [DCPiece, longueur, largeur, listEclairage, listPrises, listPrisesMulti, listPrisesSpeciales, setDevis, nom, listAutreOption]);

  const ajouteInterrupteur = () => {
    setListEclairage((value) => [...value,  {interrupteurs: 1}]);
  }

  const pluriel = (nombre) => {
    if(nombre <= 1) return "";
    return "s"
  }

  return(
    <div className="configPiece">
      <div className="boxConfigPieceTitre">
        <div className="configPieceTitre">
          <div>{nom}</div>
        </div>
      </div>
      <div className="etapePiece marginBottom">Pièce {pieceIndex} / {piecelong}</div>
      <div style={{ visibility: loading ? 'hidden' : 'visible' }}>
        <div className="configPosition ">
          <div className="configPriseAutre">
            <div className="niveauTitre center configPieceInformation">
              <div>Eclairage</div>
              <TooltipInformation
                texte={(<div>
                  Pour chaque circuit d'éclairage, entrez le nombre d'interrupteurs nécessaire.
                  Vous pouvez ajouter maximum 5 interrupteurs par circuit d’éclairage, mais autant de circuit que vous souhaitez
                  avec le bouton « Ajouter un circuit d’éclairage ».
                </div>)}/>
            </div>
            <div className="marginCote styleEclairage">
              <div>
                {listEclairage.map((item, itemIdx) => (
                  <Eclairage nombrePrise={item.interrupteurs} id={itemIdx}
                             setListEclairage={setListEclairage}
                             key={"eclairage"+ item.interrupteurs + itemIdx}/>
                ))}
              </div>
              <div className="btnCircuit">
                <div className="ajoutePiece styleBtnDevis">
                  <Button variant="contained" onClick={ajouteInterrupteur}>Ajouter un circuit d'éclairage</Button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="configPosition">
          <div className="configPrise">
            <div className="niveauTitre center configPieceInformation">
              <div>Prises</div>
              <TooltipInformation
                texte={(<div>
                  Ajouter le nombre de prises pour votre <span style={{textTransform:"lowercase"}}> {nom}.</span>
                </div>)}/>
            </div>
            <div className="marginCote">
              {listPrises.map((item, itemIdx) => (
                <div className="marginBottom">
                  <NombrePrise key={item.nom + itemIdx} texte={"Prise" + pluriel(item.valeur) +" "+ item.nom + pluriel(item.valeur)} titre="Prises" nombrePrise={item.valeur}
                               id={itemIdx} setListPrise={setListPrises}/>
                </div>
              ))}
            </div>
          </div>
          <div className="configPrise">
            <div className="niveauTitre center configPieceInformation">
              <div>Prises multimédia</div>
              <TooltipInformation
                texte={(<div>
                  Ajouter le nombre de prises multimédia dans votre <span style={{textTransform:"lowercase"}}> {nom}.</span>
                </div>)}/>
            </div>
            <div className="marginCote">
              {listPrisesMulti.map((item, itemIdx) => (
                <div className="marginBottom">
                  <NombrePrise key={item.nom + itemIdx} texte={"Prise"+pluriel(item.valeur) + " " + item.nom} nombrePrise={item.valeur} id={itemIdx}
                               setListPrise={setListPrisesMulti} />
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="configPosition">
          <div className="configPrise">
            <div className="niveauTitre center configPieceInformation">
              <div>Prises spécifiques</div>
              <TooltipInformation
                texte={(<div>
                  Cocher les prises spécifiques (circuit à alimentation unique) dans votre <span style={{textTransform:"lowercase"}}> {nom}.</span>
                </div>)}/>
            </div>
            <div className="marginCote">
              {listPrisesSpeciales.map((item, itemId) => (
                <CheckBoxConfig defaultCheck={item.valeur} nom={item.nom} key={item.nom  + itemId} onChange={(checked) => {
                  setListPrisesSpeciales((oldListPrisesSpeciales) => {
                    oldListPrisesSpeciales[itemId].valeur = checked;
                    return [...oldListPrisesSpeciales];
                  })
                }} isChecked={item.valeur}/>
              ))}
            </div>
          </div>
          <div className="configPrise">
            <div className="marginCote">
              <div className="marginTop niveauTitre center configPieceInformation">
                <div>Configuration de la pièce</div>
                <TooltipInformation
                  texte={(<div>
                    Entrer les dimensions de votre <span style={{textTransform:"lowercase"}}> {nom}</span> et completer à quelle
                    distance de votre pièce se situe par rapport au coffret électrique. Et chocher l'option si elle vous intéresse.
                  </div>)}/>
              </div>
              <div className="checkGenerale">
                <input type="number" className="inputCoffret font" min="0"
                       onInput={event => setDCPiece(event.target.value)} value={DCPiece}/>
                <div>m de distance du coffret</div>
              </div>
              <div className="checkGenerale marginTop marginBottom">
                <input type="number" className="inputCoffret font" min="0"
                       onInput={event => setLongueur(event.target.value)} value={longueur}/>
                <div className="texteLongPiece">m longueur x</div>
                <input type="number" className="inputCoffret font" min="0"
                       onInput={event => setLargeur(event.target.value)} value={largeur}/>
                <div>m largeur</div>
              </div>
              <div>
                {listAutreOption.map((item, itemId) => (
                  <CheckBoxConfig defaultCheck={item.option} nom={item.nom} key={item.nom  + itemId} onChange={(checked) => {
                    setListAutreOption((oldListAutreOption) => {
                      oldListAutreOption[itemId].option = checked;
                      return [...oldListAutreOption];
                    })
                  }} isChecked={item.option}/>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ConfigPiece;