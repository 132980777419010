import {Button} from "@mui/material";
import HorizontalRuleIcon from "@mui/icons-material/HorizontalRule";
import AddIcon from "@mui/icons-material/Add";
import * as React from "react";

const CompterComposant = ({texte, nombre, setNombre, maximum}) => {
  const retirer = () => {
    if(nombre > 0){
      setNombre(nombre - 1)
    }
  }
  const ajouter = () => {
    if (nombre < maximum) {
      setNombre(nombre + 1)
    }
  }

  return(
    <div className="nombreprise marginBottom">
      <div className="nombreprise styleBtnDevis">
        <Button variant="contained" onClick={retirer} ><HorizontalRuleIcon sx={{ fontSize: 15 }}/></Button>
        <div className={nombre === 0 ? "quantitePrise textGris" : "quantitePrise"}>{nombre}</div>
        <Button variant="contained" onClick={ajouter}><AddIcon sx={{ fontSize: 15 }}/></Button>
      </div>
      <div className={nombre === 0 ? "textePrise textGris" : "textePrise"}>{texte}</div>
    </div>
  );
}

export default CompterComposant;