import './FormulaireAvis.css'
import React, {useState} from "react";
import {Snackbar, TextField} from "@mui/material";
import Etoiles from "../../../../Globale/Composants/etoiles/Etoiles";
import axios from "axios";
import MuiAlert from "@mui/material/Alert";

const FormulaireAvis = () => {
  const [nom, setNom] = useState('');
  const [note, setNote] = useState(0);
  const [commentaire, setCommentaire] = useState('');
  const [open, setOpen] = React.useState(false);
  const [messageErreur, setMessageErreur] = useState('');
  const [severity, setSeverity] = useState("success");
  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const envoieAvis = () => {
    if(nom !== ''){
      if(note > 0 || note !== null){
        if(commentaire !== ''){
          axios.post('https://api.jelefaismoimeme.be/avis.php', {
            nom : nom,
            note : note,
            commentaire : commentaire,
          }).then((response) => {
            console.log(response.data)
            setSeverity("success")
            setMessageErreur("Votre avis a été enregistrer.")
            reset()
          });
        }
      }
    }
    else{
      setSeverity("error")
      setMessageErreur("Votre avis n'a pas été enregistrer, veuillez compléter toutes les informations.")
    }
    setOpen(true);
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const reset = () => {
    setCommentaire('')
    setNom('')
    setNote(0)
  }

  return(
    <div className="formulaireAvis">
      <div className="titre1">Donner un avis !</div>
      <div className="boxCentre">
        <div className="contenueFormulaireAvis">
          <div>
            <TextField
              helperText=""
              id="demo-helper-text-aligned"
              label="Nom"
              onInput={event => setNom(event.target.value)}
              value={nom}
            />
          </div>
          <Etoiles setNote={setNote} note={note}/>
          <div className="commentaire">
            <TextField
              id="outlined-multiline-static"
              label="Commentaire"
              multiline
              rows={6}
              defaultValue=""
              onInput={event => setCommentaire(event.target.value)}
              value={commentaire}
            />
          </div>
          <div className="boxCentre marginTop">
            <button className="boutonEnvoyer" alt="bouton envoyer mail" loading="lazy" onClick={() => envoieAvis()}>Envoyer</button>
          </div>
        </div>
      </div>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert className="alert" onClose={handleClose} severity={severity} sx={{ width: '400px'}}>
          {messageErreur}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default FormulaireAvis;