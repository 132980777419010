import React from 'react';
import { SvgIcon } from "@mui/material";

function VillaIcon(props) {
  return (
    <SvgIcon {...props} viewBox="0 0 119 100">
      <path d="M47.8821 100V64.7059H71.4115V100H100.823V52.9412H118.47L59.6468 0L0.823242 52.9412H18.4703V100H47.8821Z"
            fill="currentColor"/>
    </SvgIcon>
  );
}

export default VillaIcon;