import './Niveau.css'
import * as React from 'react';
import {Button, ClickAwayListener} from "@mui/material";
import ElementPiece from "../ElementPiece/ElementPiece";
import Tooltip from "@mui/material/Tooltip";
import {useContext, useEffect, useMemo, useState} from "react";
import {DevisContext} from "../../../../../../Context/DevisContext";
import {ListePiece} from "../../ListePiece";

const Niveau = ({titre, setAjouteListeNiveau, setNewListeNiveau}) => {
  const { devis, setDevis } = useContext(DevisContext);
  const [open, setOpen] = React.useState(false);
  const habitation = useMemo(() => devis?.habitation, [devis]);
  const defaultListePiece = useMemo(() => {
    return ListePiece.filter(item => item.habitation.includes(habitation.nom))
  }, [habitation.nom]);   /*&& item.etage.includes(titre)).map(item => ({...item})*/
  const [newListePiece, setNewListePiece] = useState(devis?.niveaux ?? defaultListePiece ?? []);

  useEffect(() => {
    setDevis((oldDevis) => ({
      ...oldDevis,
      niveaux: newListePiece.map(({piece, nombre}) => ({
        piece,
        nombre : typeof nombre === 'number' ? nombre : nombre[devis?.habitation?.nom]
      })),
    }))
  }, [newListePiece, setDevis]);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  const ajoutePiece = (newPiece) => {
    let piecePresente = false;
    newListePiece.forEach((item , idItem) => {
      if (item.piece.includes(newPiece.piece)) {
        item.nombre = (typeof item.nombre === 'number' ? item.nombre+1 : item.nombre[devis?.habitation?.nom]+1);
        piecePresente = true;
        setNewListePiece(value => ([...value]));
      }
    });
    if(piecePresente === false) {
      setNewListePiece((value) => [...value,{...newPiece}]); ////{...newPiece} -> pour les reference
    }
    piecePresente = false;
    setOpen(false);
  }

  return(
    <div className="niveau" >
      <div className="center niveauTitre" >{titre}</div>
      <div className="positionPiece">
        <ElementPiece niveau={titre} setNewListePiece={setNewListePiece} newListePiece={newListePiece} />
        <ClickAwayListener onClickAway={handleTooltipClose}>
          <div className="ajoutePiece styleBtnDevis">
            <Tooltip
              PopperProps={{
                disablePortal: true,
              }}
              onClose={handleTooltipClose}
              open={open}
              disableFocusListener
              disableHoverListener
              disableTouchListener
              title={(
              <div className="listePiece">
                {ListePiece
                  .sort((a, b) => a.piece < b.piece ? -1 : 1)
                  .map((item , idItem) => (
                  <div key={'piece' + idItem}>
                    <div onClick={() => ajoutePiece(item)} >{item.piece}</div>
                  </div>
                ))}
              </div>
            )} classes={{ tooltip: "ajoutePieceTooltip"}} arrow>
              <Button variant="contained" onClick={handleTooltipOpen} >Ajouter une pièce</Button>
            </Tooltip>
          </div>
        </ClickAwayListener>
      </div>
    </div>
  );
}

export default Niveau;

/*
.filter(item => !item.etageUniquement || item.etageUniquement.includes(titre))

      <div className="supprimerPiece supprimerNiveau">
        <IconButton onClick={() => supprimerNiveau(titre)} >
          <HighlightOffIcon className="croixStyle"/>
        </IconButton>
      </div>
*/