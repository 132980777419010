import React from 'react';
import { SvgIcon } from "@mui/material";

function ElectriciteIcon(props) {
  return (
    <SvgIcon {...props} viewBox="0 0 351.69 395.79">
      <path d="M132.9,370l83.38.29a3.26,3.26,0,0,1,2.85,4.89c-4.26,7.18-12.45,19.84-44.74,20.61-32.65.77-39.75-13.16-44.35-20.91A3.24,3.24,0,0,1,132.9,370Z"/><path d="M133.14,345.81H216a5.93,5.93,0,0,1,5.56,3.7,7.86,7.86,0,0,1,.37,4.47l0,.08a6,6,0,0,1-5.84,4.52H133.14a6,6,0,0,1-6-5.24,8.44,8.44,0,0,1-.07-.89,9.88,9.88,0,0,1,.08-1.47A6,6,0,0,1,133.14,345.81Z"/>
      <path d="M134.3,324.63h82.55a6,6,0,0,1,5.71,3.72,7.76,7.76,0,0,1,.36,4.44l0,.08a6.05,6.05,0,0,1-6,4.51H134.3a6.1,6.1,0,0,1-6.13-5.21,8.83,8.83,0,0,1-.07-.91,8.09,8.09,0,0,1,.08-1.5A6.1,6.1,0,0,1,134.3,324.63Z"/><path d="M214.83,312.62H134.14c-.85,0-10,.34-15.83-7.15a19.16,19.16,0,0,1-3.57-14.3V255.94c-36.92-35.83-46-89.27-24-129.19,16-29,48.35-50.78,84.77-50.56,45.72.28,83.67,35.13,94.46,76.6,9.53,36.57-2.63,76.14-31.14,103.15v35.74a24.63,24.63,0,0,1-24,20.94Z"/>
      <path d="M3.2,176.12l47.6-4.32a3.55,3.55,0,0,1,3.53,2.31,6.66,6.66,0,0,1,.49,3.09v.06a3.69,3.69,0,0,1-3.16,3.47L4,185.06a3.83,3.83,0,0,1-3.86-3.33,5.93,5.93,0,0,1-.1-.63A7.74,7.74,0,0,1,0,180,3.82,3.82,0,0,1,3.2,176.12Z"/><path d="M300.05,167.18l47.47-5.55a3.54,3.54,0,0,1,3.59,2.21,6.53,6.53,0,0,1,.57,3.08V167a3.69,3.69,0,0,1-3.07,3.55l-47.51,5.56a3.81,3.81,0,0,1-4-3.22,6.06,6.06,0,0,1-.12-.64,7.61,7.61,0,0,1-.07-1A3.81,3.81,0,0,1,300.05,167.18Z"/><path d="M253.28,78.19l33.31-34.26a3.55,3.55,0,0,1,4.18-.55,6.59,6.59,0,0,1,2.39,2l0,0a3.69,3.69,0,0,1-.14,4.7l-33.34,34.3a3.82,3.82,0,0,1-5.1,0c-.16-.13-.33-.27-.49-.42a7.32,7.32,0,0,1-.72-.77A3.82,3.82,0,0,1,253.28,78.19Z"/>
      <path d="M169.63,51.31l.93-47.79A3.56,3.56,0,0,1,173.25.27a6.59,6.59,0,0,1,3.12-.15h.06a3.68,3.68,0,0,1,3.1,3.53l-.93,47.82A3.83,3.83,0,0,1,174.87,55c-.21,0-.43,0-.65,0a8.67,8.67,0,0,1-1.05-.07A3.8,3.8,0,0,1,169.63,51.31Z"/>
      <path d="M54.39,50.58,89,83.52a3.56,3.56,0,0,1,.59,4.17,6.59,6.59,0,0,1-2,2.41l-.05,0a3.69,3.69,0,0,1-4.7-.08l-34.66-33a3.83,3.83,0,0,1-.05-5.1c.13-.17.27-.33.42-.5a7.42,7.42,0,0,1,.76-.73A3.82,3.82,0,0,1,54.39,50.58Z"/>
  </SvgIcon>
  );
}

export default ElectriciteIcon;