import './JLFMMTitre.css'
import React from 'react';

const JLFMMTitre = () => {
  return (
    <h1 className="textBleu jlfmmTitre">
      JE LE FAIS<span className="textRose "> MOI-MÊME</span>
    </h1>
  );
}

export default JLFMMTitre;
