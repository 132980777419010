import './Description.css';
import "animate.css/animate.min.css";
import JLFMMTitre from "../../../../Globale/Composants/JLFMMTitre/JLFMMTitre";
import React from 'react';
import {NavLink} from "react-router-dom";

const Description = () => {
  return (
    <div className="description">
      <div className="textDescription">
        <JLFMMTitre/>
        <div className="jlfmmDescription justifier">
          C’est pour moi, mes amis, mes voisins,… pour tous ceux qui désirent réaliser des travaux eux même
          soit par soucis d’économies en gagnant <span className="textRose">jusqu’à 50%</span> sur le prix par rapport un professionnel ou par envie
          de réaliser, de maitriser,… de faire soi-même sa maison avec notre expertise et surtout avec
          <span className="textRose"> NOTRE ASSISTANCE</span>.
        </div>
        <div className="jlfmmDescription justifier">
          Pourquoi choisir «  <span className="textBleu">Je le fais moi-même</span> » ?
        </div>
        <div className="jlfmmDescription justifier">
          Pour les <span className="textRose">services</span> à chaque étape <span className="textRose"> ET </span>sur place lors de votre avancement à votre rythme.
          <br/>Pour nos <span className="textBleu">techniciens</span>, des vrais <span className="textBleu">professionnels</span>
          qui pratiquent tous les jours le métier d’<span className="textBleu">électricien</span> auprès de nos clients, et qui peuvent également poursuivre votre installation au besoin.
          <br/>Pour le <span className="textRose">choix</span> de vos équipements, <span className="textRose">facile</span> a mettre en oeuvre et <span className="textRose">accessible</span> à tous budget.
          <br/>Pour notre <span className="textRose">assistance plus +</span>, nous pouvons prendre en charge tous vos besoins administratifs pour mener à bien votre installation.
        </div>
        <div className="jlfmmDescription justifier">
          N’hésitez pas à utiliser notre configurateur<span className="textRose"> GRATUIT</span>, sans engagement pour vous donner une idée de prix de
          votre futur projet :
          <NavLink to="/devis"><span className="textRose gras"> COMMENCER MON DEVIS </span></NavLink>
          ou à venir nous voir dans l’un de nos showroom :
          <NavLink to="/contact"><span className="textRose gras"> contact</span></NavLink>.
        </div>
      </div>
      <div className="imageDescription">
        <div className="jlfmmImage">
          <img src="https://entrepot.jelefaismoimeme.be/accueil/saignee2.jpg" loading="lazy" alt="Image de description" className="jlfmmImage"/>
        </div>
      </div>

    </div>
  );
}

export default Description;
