import './Popup.css'
import CloseIcon from "@mui/icons-material/Close";
import * as React from "react";
import {useContext, useEffect, useState} from "react";
import axios from "axios";
import {PopupContext} from "../../../Context/PopupContext";

const Popup = () => {
  const { fermeturePopup, setFermeturePopup} = useContext(PopupContext);
  const [activePopup, setActivePopup] = useState(true);
  const [nomPopup, setNomPopup] = useState();
  const [descriptionPopup, setDescriptionPopup] = useState();

  useEffect(() => {
    axios.get('https://api.jelefaismoimeme.be/popup.php').then((response) => {
      setActivePopup(response.data.status)
      setNomPopup(response.data.nom)
      setDescriptionPopup(response.data.description)
    });
  },[]);

  return (
    activePopup === true ?
      <div className={fermeturePopup === true ? "visible": "popup"}>
        <div className="fermetureTuto">
          <CloseIcon sx={{fontSize: 40}} onClick={() => setFermeturePopup(true)}/>
        </div>
        <div>
          <div className="boxCentre titrePopup">
            <div  className="center nomPopup">{nomPopup}</div>
          </div>
          <div className="descriptionPopup">{descriptionPopup}</div>
        </div>
      </div>
  :<div/>
  );
}

export default Popup;