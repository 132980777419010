import './BoutonSupprimerImage.css'
import CorbeilleIcon from "../../../../Globale/Icons/corbeilleIcon";
import React from "react";
import axios from "axios";

const BoutonSupprimerImage = ({composant, dossier, element, onClick}) => {

  /*Bouton supprimer des images*/
  const supprimeImage = () => {
    axios.post('https://api.jelefaismoimeme.be/deleteImage.php', {
      composant : composant,
      dossier : dossier,
      element : element,
    })
      .then((response) => {
        onClick();
      });
  }

  return(
    <button className="corbeille" onClick={supprimeImage}>
      <CorbeilleIcon/>
    </button>
  );
}

export default BoutonSupprimerImage;