import AdapterMoment from "@mui/lab/AdapterMoment";
import {LocalizationProvider, StaticDateRangePicker} from "@mui/lab";
import React from "react";

const DatePicker = ({date, setDate}) => {

  /*Action changer la date*/
  const onChange = (newValue) => {
    setDate(newValue);
  }

  return(
    <LocalizationProvider dateAdapter={AdapterMoment} locale="fr">
      <StaticDateRangePicker
        mask='__/__/____'
        openTo="day"
        value={date}
        onChange={onChange}
        renderInput={() => (<div/>)}/>
    </LocalizationProvider>
  );
}

export default DatePicker;