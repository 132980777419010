import React from 'react';
import { SvgIcon } from "@mui/material";

function BorneIcon(props) {
  return (
    <SvgIcon {...props} viewBox="0 0 401.26 382.21">
      <path d="M349.94,68.78V95.05a1,1,0,0,0,1,1h22.44a1,1,0,0,0,1-1V68.78a1,1,0,0,1,1-1h10.19a1,1,0,0,1,1,1V95.05a1,1,0,0,0,1,1h12.48a1,1,0,0,1,1,1V130.4a.93.93,0,0,1-.05.29A35.41,35.41,0,0,1,375.72,155a.74.74,0,0,1-.25,0H350a.68.68,0,0,1-.2,0,39.33,39.33,0,0,1-28.94-24.31.94.94,0,0,1-.07-.37c0-10.38,0-20.77.35-33.13a1.06,1.06,0,0,1,1-1H334a1,1,0,0,0,1-1l.36-26.43a1,1,0,0,1,1-1h12.5A1,1,0,0,1,349.94,68.78Z"/>
      <path d="M247.71,169a3.78,3.78,0,0,0,3.84,3.8,34.16,34.16,0,0,1,18.53,4.87,33,33,0,0,1,8.36,7.36,3.75,3.75,0,0,1,.83,2.36V344.22a3.65,3.65,0,0,0,.1.88,47.36,47.36,0,0,0,92.48,0,3.8,3.8,0,0,0,.1-.89V154.66a3.78,3.78,0,0,0-3.78-3.78H353.5a3.78,3.78,0,0,0-3.78,3.76L349,333a2.62,2.62,0,0,0,0,.28c1.37,15.34-9,28.09-20.69,29.73-13.45,1.89-28.92-10.93-28.34-29.11q.76-75.49,1.52-151a3.56,3.56,0,0,0-.21-1.29,47.39,47.39,0,0,0-9.74-16.23A48.91,48.91,0,0,0,265.86,151a4,4,0,0,0-.77-.08h-13.5a3.78,3.78,0,0,0-3.78,3.76Z"/>
      <path d="M271,352.29c-5.18-1.15-22.27,2.71-22-6.3.44-81.63,1.82-260,2.22-340.23A5.73,5.73,0,0,0,245.47,0H31a5.72,5.72,0,0,0-5.73,5.73V344.32A5.73,5.73,0,0,1,19.55,350c-5,.74-19.75-3-19.55,5.73v20.72a5.73,5.73,0,0,0,5.73,5.72H270.79a5.72,5.72,0,0,0,5.72-5.72V358A5.72,5.72,0,0,0,271,352.29ZM109.19,332.77l27.75-83.41H94.21L118,168h38.47l-21.44,63.66,48,.34ZM218.81,130a6.72,6.72,0,0,1-6.72,6.72H61.79A6.72,6.72,0,0,1,55.07,130V35.83a6.72,6.72,0,0,1,6.72-6.72h150.3a6.72,6.72,0,0,1,6.72,6.72Z"/>
    </SvgIcon>
  );
}

export default BorneIcon;