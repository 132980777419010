import {NavLink} from "react-router-dom";

export const DataShowroom = {
  nom: 'Showroom',
  color: '#0A90C9',
  description:
    <div>
      <div>
        Je désire réaliser mon installation électrique, d'alarme, de caméra, de panneaux photovotaïque,...
        rien de plus simple grâce aux solutions <span className="textBleu">« je le fais moi-même »</span>.
      </div>
      <div>
        Vous commencez par <NavLink to="/devis" ><span className="textRose">réaliser votre devis </span></NavLink>
        via notre configurateur simplifié et puis avec notre aide et nos <span className="textBleu"> techniciens qualifiés </span>
        vous serez <span className="textRose"> facilement faire des économies</span>, en faisant vous-même votre installation
        tout en vous sentant satisfait et fier de l’avoir fait.
      </div>
    </div>,
  imageBanniere: 'https://entrepot.jelefaismoimeme.be/showroom/showroom.jpeg',
  dossier: 'showroom',
  etapes:[
    {
      nom: 'Conception et plan',
      image: 'plan.jpeg',
      description:
        <div>
          Lors de votre venu, nous réaliserons ensemble la <span className="textBleu">conception</span> de votre installation
          suivant vos  <span className="textRose">plans</span> où nous déterminerons les emplacements des équipements qui composeront votre
          installation selon vos <span className="textBleu">choix</span> et nos  <span className="textRose">conseils</span>.
        </div>,
    },
    {
      nom: 'Choix appareillage',
      image: 'appareillage.jpeg',
      description:
        <div>
          Avec <span className="textBleu">« je le fais moi-même »</span>, vous avez la possibilité d’un
          <span className="textRose"> choix </span> important pour vos équipements avec une large gamme de modèles et
          de couleurs qui vous permettra d’adapter vos équipements à votre habitation.
        </div>,
    },
    {
      nom: 'Choix luminaire',
      image: 'lampes.jpeg',
      description:
        <div>
          Vous avez également la possibilité de <span className="textRose">choisir</span> vos luminaires, spots, suspensions,
          appliques, suivant notre gamme et de pouvoir vous rendre compte dans le showroom des différentes
          <span className="textRose"> possibilités</span> de teintes, puissances et tendances.
        </div>,
    },
    {
      nom: 'Maison connecté',
      image: 'connecte.jpeg',
      description:
        <div>
          Finalement, selon vos <span className="textRose">envies</span> vous pourrez découvrir les <span className="textBleu">solutions </span>
          de maison <span className="textBleu">connectée</span> ou de la <span className="textBleu">domotique </span>
          qui permettent de gagner en efficacité, de réaliser des <span className="textRose">économies</span>, de gagner en
          <span className="textRose"> sécurité</span> et surtout en confort.
        </div>,
    },
  ],
  texte:
    <div>
      Tous cela vous a donné envie de découvrir nos solutions tout en réalisant <span className="textRose">jusqu’à 50% sur le prix </span>
      d’un professionnel, n’hésitez pas à ...
    </div>,
  texteFin: 'C’est gratuit et sans engagements.',
}