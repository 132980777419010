import {useMemo} from "react";
import ModifImages from "./ModifImages";
import ModifVideo from "./ModifVideo";
import ModifDevis from "./ModifDevis";
import ModifActualite from "./ModifActualite";
import ModifPromo from "./ModifPromo";
import ModifPopup from "./ModifPopup";
import ModifAvis from "./ModifAvis";

const ZoneModification = ({ElementSelectionne, ZoneModifSelectionne, setZoneModifSelectionne}) =>{

  const selectZoneModif = useMemo(() => {
    switch (ElementSelectionne) {
      case 'Actualité': return <ModifActualite Actualite={ZoneModifSelectionne}/>
      case 'Galerie': return <ModifImages elementSelectionne={ElementSelectionne} dossier={ZoneModifSelectionne} key={`${ElementSelectionne}${ZoneModifSelectionne}`}/>
      case 'Vidéo': return <ModifVideo videoSelectionne={ZoneModifSelectionne} />
      case 'Partenaire': return <ModifImages elementSelectionne={ElementSelectionne} dossier={ZoneModifSelectionne} key={`${ElementSelectionne}${ZoneModifSelectionne}`}/>
      case 'Devis': return <ModifDevis/>
      case 'Promo': return <ModifPromo Promo={ZoneModifSelectionne}/>
      case 'Pop-up': return <ModifPopup/>
      case 'Avis': return <ModifAvis avis={ZoneModifSelectionne} setZoneModifSelectionne={setZoneModifSelectionne}/>
      default: return <div/>
    }
  }, [ElementSelectionne,ZoneModifSelectionne]);

  return(
    <div>
      {selectZoneModif}
    </div>
  );
}

export default ZoneModification;