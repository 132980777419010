import {useCallback, useEffect, useState} from "react";
import axios from "axios";

const useActualite = () => {
  const [listActu, setListActu] = useState([]);

  const refresh = useCallback(() => {
    axios.post('https://api.jelefaismoimeme.be/actualite.php')
      .then((response) => {
        setListActu(response.data ?? []);
        //console.log(response.data.map(elm => elm.id));
      });
  }, []);

  useEffect(refresh,[refresh]);
  return { listActu, refresh };
}

export default useActualite;