import './ExemplePrix.css'
import CaseElement from "../../../../Globale/Composants/CaseElement/CaseElement";
import VillaIcon from "../../../../Globale/Icons/VillaIcon";
import AppartementIcon from "../../../../Globale/Icons/Appartement";
import MaisonMitoyenneIcon from "../../../../Globale/Icons/MaisonMitoyenneIcon";
import {useEffect, useMemo, useState} from "react";
import React from 'react';
import axios from "axios";

const ExemplePrix = ({Nom, Couleur, Categories, CouleurBackground, ColorHover}) => {
  const [prixSelectionne, setPrixSelectionne] = useState('Maison individuelle');
  const [donneesPrix, setDonneesPrix] = useState();
  const HabitationItems = [
    {
      icon:<AppartementIcon sx={{ fontSize: 80 }} />,
      nomServices:'Appartement',
      color: Couleur,
      background: CouleurBackground,
    },
    {
      icon:<MaisonMitoyenneIcon sx={{ fontSize: 100 }} />,
      nomServices:'Maison mitoyenne',
      color: Couleur,
      background: CouleurBackground,
    },
    {
      icon:<VillaIcon sx={{ fontSize: 80 }}/>,
      nomServices:'Maison individuelle',
      color: Couleur,
      background: CouleurBackground,
    },
  ];

  const categorieSelectionne = useMemo(
    () => {
      if (Object.keys(Categories).includes(prixSelectionne)) {
        return Categories[prixSelectionne];
      }
      return Categories['Maison individuelle'];
    },
    [Categories, prixSelectionne],
  );

  useEffect( () => {
    axios.post('https://api.jelefaismoimeme.be/prixEstimatif.php', {
      typeDevis : Nom.toLowerCase().normalize('NFD')?.replace(/([\u0300-\u036f]|[^0-9a-zA-Z])/g, ''),
      habitation : prixSelectionne.toLowerCase(),
    })
      .then((response) => {
        setDonneesPrix(response.data[0])
      })
  }, [prixSelectionne, Nom])

  return(
    <div className="exemplePrix">
      <div className="titre1">Le prix</div>
      <div className="elementHabitation">
        {HabitationItems.map((item , idItem) => (
          <div
            key={'HabitationItems' + idItem}
            onClick={() => setPrixSelectionne(item.nomServices)}
          >
            <CaseElement nomServices={item.nomServices} icon={item.icon} color={prixSelectionne === item.nomServices ? Couleur : ColorHover } colorBackground={prixSelectionne === item.nomServices ? item.background : 'white'} hoverColor={Couleur}/>
          </div>
        ))}
      </div>
      <div className="texteDetailsPrix borderArrondi padding" style={{borderColor: Couleur, background: `${Couleur}10` }}>
        <div>{categorieSelectionne?.description}</div>
        <div className="marginBottom TitreDescriptionExempleDevis gras" dangerouslySetInnerHTML={{__html: donneesPrix?.titreMateriel}}></div>
        <div dangerouslySetInnerHTML={{__html: donneesPrix?.texteMateriel}}></div>
        <div className="marginBottom TitreDescriptionExempleDevis gras" dangerouslySetInnerHTML={{__html: donneesPrix?.titreService}}></div>
        <div dangerouslySetInnerHTML={{__html: donneesPrix?.texteService}}></div>
        {Nom !== "Panneaux photovoltaïque" || prixSelectionne !== "Appartement" ?
          <div className="gras prixExempleDevis" style={{color: Couleur,}}>
            {Nom === "Panneaux photovoltaïque" ?
              <div>Prix Estimatif entre {donneesPrix?.prixPVMinHorsTVA}€ et {donneesPrix?.prixPVMaxHorsTVA}€ hors TVA</div>
              : <div>Prix Estimatif: {Math.round((donneesPrix?.prixHorsTVA + Number.EPSILON) * 100) / 100}€ hors TVA</div>
            }
          </div>
          :<div/>}
      </div>
    </div>
  );
}

export default ExemplePrix;