import React from 'react';
import { SvgIcon } from "@mui/material";
import './HexagoneIcon.css'

function HexagoneIcon(props) {
  return (
    <SvgIcon {...props} viewBox="0 0 348 400" className="tailleHexagone">
      <path d="M174 0L347.205 100V300L174 400L0.794922 300V100L174 0Z" />
    </SvgIcon>
  );
}

export default HexagoneIcon;