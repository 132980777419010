import React from 'react';
import {NavLink} from "react-router-dom";
import {Button} from "@mui/material";

export const Electricite = {
  nom: 'Electricité',
  color: '#0A90C9',
  colorHover: '#86c2db',
  colorBackground: '#F0F8FC',
  description:
    <div>
      Je fais mon installation électrique avec assistance, rien de plus simple.
      vous commencez par <NavLink to="/devis" ><span className="textRose">réaliser votre devis</span></NavLink> via notre configurateur simplifié et puis avec notre aide,
      nos <span className="textBleu">techniciens qualifiés</span> vous serez <span className="textRose">facilement faire des économies</span>, vous sentir satisfait et fier d’avoir
      réaliser votre propre installation électrique selon vos <span className="textRose">choix</span>, vos goûts, vos désirs et surtout votre budget.
    </div>,
  imageBanniere: 'https://entrepot.jelefaismoimeme.be/typeInstallation/electricite.jpg',
  dossier: 'electricite',
  categories: {
    "Maison individuelle": {
      triangleClass: 'triangleGauche',
      description: (
        <div>
          <div className="marginBottom TitreDescriptionExempleDevis gras">Pièces de l'habitation pour une nouvelle construction</div>
          <div className="positionPieceHabitation">
            <div>
              <ul>
                <li> 1 Hall d'entrée</li>
                <li> 1 Salon</li>
                <li> 1 Salle à manger</li>
                <li> 1 Cuisine </li>
                <li> 1 WC </li>
                <li> 1 Buanderie </li>
                <li> 1 Garage </li>
                <li> 1 Chambre </li>
              </ul>
            </div>
            <div>
              <ul>
                <li> 1 Escalier </li>
                <li>1 WC</li>
                <li>1 Chambre</li>
                <li>1 Salle de bain</li>
                <li>1 Hall de nuit</li>
                <li>1 grenier</li>
                <li>1 Cave</li>
              </ul>
            </div>
          </div>
        </div>
      ),
    },
    Appartement: {
      triangleClass: 'triangleCentre',
      description: (
        <div className="margin">
          <div className="marginBottom TitreDescriptionExempleDevis gras">Pièces de l'habitation pour une nouvelle construction</div>
          <div className="positionPieceHabitation">
            <div>
              <ul>
                <li> 1 Salon</li>
                <li> 1 Salle à manger</li>
                <li> 1 Cuisine </li>
                <li> 1 WC </li>
                <li> 1 Buanderie </li>
                <li> 1 Chambre </li>
                <li> 1 Salle de douche </li>
              </ul>
            </div>
          </div>
        </div>
      ),
    },
    "Maison mitoyenne": {
      triangleClass: 'triangleDroite',
      description: (
        <div className="margin">
          <div className="marginBottom TitreDescriptionExempleDevis gras">Pièces de l'habitation pour une nouvelle construction</div>
          <div className="positionPieceHabitation">
            <div>
              <ul>
                <li> 1 Hall d'entrée</li>
                <li> 1 Salon</li>
                <li> 1 Salle à manger</li>
                <li> 1 Cuisine </li>
                <li> 1 WC </li>
                <li> 1 Buanderie </li>
                <li> 1 Chambre </li>
              </ul>
            </div>
            <div>
              <ul>
                <li> 1 Escalier </li>
                <li> 1 Garage </li>
                <li>1 WC</li>
                <li>1 Chambre</li>
                <li>1 Salle de bain</li>
                <li>1 Hall de nuit</li>
                <li>1 grenier</li>
              </ul>
            </div>
          </div>
        </div>
      ),
    },
  },
  etapes: [
    <NavLink to="/devis"><Button className="btnDevis">Commencer mon devis</Button></NavLink>,
    <div>Rendez-vous chez vous ou à notre showroom pour <span className="gras">finaliser votre projet selon vos besoins et vos choix</span>.</div>,
    <div>Nous réalisons vos <span className="gras">plans précis</span> et <span className="gras">pré-configurons</span> votre demande.</div>,
    <div>Livraison et explication chez vous par un de nos techniciens pour <span className="gras">la réalisation de vos saignés et de la pose des boites d’encastrement</span>.</div>,
    <div>Livraison et explication chez vous par un de nos techniciens pour <span className="gras">le câblage de vos prises</span>.</div>,
    <div>Livraison et explication chez vous par un de nos techniciens pour <span className="gras">le câblage de vos interrupteurs et lampes</span>.</div>,
    <div>Livraison et explication chez vous par un de nos techniciens pour <span className="gras">le câblage spécifique</span>.</div>,
    <div>Livraison et explication chez vous par un de nos techniciens du <span className="gras">coffret pré-câblé</span> et de <span className="gras">vos appareillages</span>.</div>,
    <div>Profitez de votre <span className="gras">installation électrique</span>.</div>,
  ],
};
