import './Showroom.css'
import ScrollTop from "../../Globale/Composants/ScrollTop/ScrollTop";
import Menu from "../../Globale/Composants/Menu/Menu";
import React from "react";
import PiedDePage from "../../Globale/Composants/PiedDePage/PiedDePage";
import {DevisProvider} from "../../Context/DevisContext";
import NomExempleDevis from "../ExempleDevis/Composants/NomExempleDevis/NomExempleDevis";
import {DataShowroom} from "./DataShowroom";
import ExplicationService from "../ExempleDevis/Composants/ExplicationService/ExplicationService";
import EtapeShowroom from "./EtapeShowroom/EtapeShowroom";
import Galerie from "../Accueil/Composants/Galerie/Galerie";
import {NavLink} from "react-router-dom";

const Showroom = () => {
  return(
    <div className="showroom">
      <DevisProvider>
        <ScrollTop />
        <header>
            <Menu />
        </header>
        <div className="corps" style={{minHeight:"800px"}}>
          <NomExempleDevis Nom={DataShowroom.nom} Banniere={DataShowroom.imageBanniere} Couleur={DataShowroom.color}/>
          <ExplicationService description={DataShowroom.description}/>
          <div className="ellipseRose"></div>
          <div className="ellipseBleu"></div>
          <EtapeShowroom Etape={DataShowroom.etapes}/>
          <Galerie dossier={DataShowroom.dossier}/>
          <ExplicationService description={DataShowroom.texte}/>
          <div className="boxCentre marginBottom">
            <NavLink to="/devis" >
              <button className="boutonDevisShowroom">Commencer mon devis</button>
            </NavLink>
          </div>
          <ExplicationService description={DataShowroom.texteFin}/>
        </div>
        <footer>
          <PiedDePage />
        </footer>
      </DevisProvider>
    </div>
  );
}

export default Showroom;