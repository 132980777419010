import {useContext, useEffect, useState} from "react";
import {DevisContext} from "../../../../../Context/DevisContext";
import CompterComposant from "../CompterComposant/CompterComposant";

const Detecteur = () => {
  const { devis, setDevis } = useContext(DevisContext);
  const [mouvement,setMouvement] = useState(devis?.detecteur?.mouvement ?? 2);
  const [porteEntre,setPorteEntre] = useState(devis?.detecteur?.porteEntre ?? 1);
  const [porteGarage,setPorteGarage] = useState(devis?.detecteur?.porteGarage ?? 0);
  const [fenetre,setFenetre] = useState(devis?.detecteur?.fenetre ?? 0);
  const [fumee,setFumee] = useState(devis?.detecteur?.fumee ?? 0);
  useEffect(() => {
    setDevis((oldDevis) => ({
      ...oldDevis,
      detecteur:{
        mouvement: mouvement,
        porteEntre: porteEntre,
        porteGarage: porteGarage,
        fenetre: fenetre,
        fumee: fumee,
      },
    }))
  }, [mouvement, porteEntre, porteGarage, fenetre, fumee, setDevis]);

  return(
    <div className="contenuEtapeDetecteur fontAssisant detecteur">
      <div className="titreEtape texteInfoService center">Choississez le nombre et le type de détecteur souhaitées</div>
      <div className="checkGeneralePosition">
        <div>
          <CompterComposant texte="Détecteur de mouvement" maximum={8} nombre={mouvement} setNombre={setMouvement}/>
          <CompterComposant texte="Détecteur porte d'entrée ou de sortie" maximum={4} nombre={porteEntre} setNombre={setPorteEntre}/>
          <CompterComposant texte="Détecteur porte de garage" maximum={2} nombre={porteGarage} setNombre={setPorteGarage}/>
          <CompterComposant texte="Détecteur fenêtre" maximum={12} nombre={fenetre} setNombre={setFenetre}/>
          <CompterComposant texte="Détecteur de fumée" maximum={4} nombre={fumee} setNombre={setFumee}/>
        </div>
      </div>
    </div>
  );
}

export default Detecteur;