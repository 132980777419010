/*Toutes les listes pour mettre les valeurs par defaut des pièces*/
export const listAutreOptionDefaut = (nom) => [
  {
    nom: "Equipement en apparent et semi étanche",
    option: (nom.includes("Cave") || nom.includes("Garage") || nom.includes("Grenier") || nom.includes("Jardin arrière") || nom.includes("Jardin avant") || nom.includes("Chalet")),
  }
];

export const listPrisesSpecialesDefaut = (nom) => [{
  nom: "Taque",
  valeur: (nom.includes("Cuisine")),
},
  {
    nom: "Four",
    valeur: (nom.includes("Cuisine")),
  },
  {
    nom: "Lave linge",
    valeur: (nom.includes("Buanderie")),
  },
  {
    nom: "Lave vaisselle",
    valeur: (nom.includes("Cuisine")),
  },
  {
    nom: "Sèche linge",
    valeur: (nom.includes("Buanderie")),
  },
  {
    nom: "Boiler",
    valeur: (nom.includes("Chaufferie")),
  },
  {
    nom: "Chaudière",
    valeur: false,
  },
  {
    nom: "Pompe à chaleur",
    valeur: false,
  },
  {
    nom: "Radiateur électrique",
    valeur: false,
  },
];

export const internet = (nom) => {
  if(nom.includes("Salon") || nom.includes("Bureau")){
    return 1;
  }
  else{
    return 0;
  }
}

export const tv = (nom) => {
  if(nom.includes("Salon")){
    return 1;
  }
  else{
    return 0;
  }
}

export const listPrisesMultiDefaut = (nom) => [
  {
    nom: "Internet",
    valeur: internet(nom),
  },
  {
    nom: "TV",
    valeur: tv(nom),
  },
  {
    nom: "USB",
    valeur: 0,
  },
]

export const simple = (nom) => {
  if(nom.includes("Cave") || nom.includes("Salle de douche") || nom.includes("Hall d'entrée") || nom.includes("Buanderie") || nom.includes("Escalier") || nom.includes("Bureau") || nom.includes("Dressing") || nom.includes("Local technique") || nom.includes("Grenier")){
    return 1;
  }
  if(nom.includes("Garage") || nom.includes("Salle de bain")){
    return 2;
  }
  if(nom.includes("Salon") || nom.includes("Chambre") || nom.includes("Cuisine") || nom.includes("Salle de jeux")){
    return 3;
  }
  if(nom.includes("Salle à manger")){
    return 4;
  }
  else{
    return 0;
  }
}

export const double = (nom) => {
  if(nom.includes("Salle de bain") || nom.includes("Bureau") || nom.includes("Chaufferie") || nom.includes("Local technique")){
    return 1;
  }
  if(nom.includes("Cuisine")){
    return 2;
  }
  else{
    return 0;
  }
}

export const triple = (nom) => {
  if(nom.includes("Salon")){
    return 1;
  }
  else{
    return 0;
  }
}
export const listDimensionLargeur = (nom) => {
  if(nom.includes("Hall d'entrée"))
  {
    return 2;
  }
  if(nom.includes("Salon"))
  {
    return 3;
  }
  if(nom.includes("Salle à manger"))
  {
    return 4;
  }
  if(nom.includes("Cuisine"))
  {
    return 3;
  }
  if(nom.includes("WC"))
  {
    return 1;
  }
  if(nom.includes("Buanderie"))
  {
    return 3;
  }
  if(nom.includes("Garage"))
  {
    return 4;
  }
  if(nom.includes("Chambre"))
  {
    return 3;
  }
  if(nom.includes("Salle de bain"))
  {
    return 3;
  }
  if(nom.includes("Salle de douche"))
  {
    return 3;
  }
  if(nom.includes("Hall de nuit"))
  {
    return 1;
  }
  if(nom.includes("Escalier"))
  {
    return 1;
  }
  if(nom.includes("Grenier"))
  {
    return 5;
  }
  if(nom.includes("Hall de cave"))
  {
    return 1;
  }
  if(nom.includes("Cave"))
  {
    return 3;
  }
  if(nom.includes("Chaufferie"))
  {
    return 3;
  }
  if(nom.includes("Dressing"))
  {
    return 3;
  }
  if(nom.includes("Bureau"))
  {
    return 3;
  }
  if(nom.includes("Local technique"))
  {
    return 3;
  }
  if(nom.includes("Chalet"))
  {
    return 3;
  }
  if(nom.includes("Veranda"))
  {
    return 3;
  }
  if(nom.includes("Piscine"))
  {
    return 2;
  }
  if(nom.includes("Jardin avant"))
  {
    return 3;
  }
  if(nom.includes("Jardin arrière"))
  {
    return 5;
  }
  if(nom.includes("Salle de jeux"))
  {
    return 3;
  }
  else{
    return 0;
  }
}
export const listDimensionLongueur = (nom) => {
  if(nom.includes("Hall d'entrée"))
  {
    return 4;
  }
  if(nom.includes("Salon"))
  {
    return 4;
  }
  if(nom.includes("Salle à manger"))
  {
    return 4;
  }
  if(nom.includes("Cuisine"))
  {
    return 4;
  }
  if(nom.includes("WC"))
  {
    return 2;
  }
  if(nom.includes("Buanderie"))
  {
    return 3;
  }
  if(nom.includes("Garage"))
  {
    return 5;
  }
  if(nom.includes("Chambre"))
  {
    return 4;
  }
  if(nom.includes("Salle de bain"))
  {
    return 3;
  }
  if(nom.includes("Salle de douche"))
  {
    return 3;
  }
  if(nom.includes("Hall de nuit"))
  {
    return 4;
  }
  if(nom.includes("Escalier"))
  {
    return 4;
  }
  if(nom.includes("Grenier"))
  {
    return 5;
  }
  if(nom.includes("Hall de cave"))
  {
    return 4;
  }
  if(nom.includes("Cave"))
  {
    return 4;
  }
  if(nom.includes("Chaufferie"))
  {
    return 3;
  }
  if(nom.includes("Dressing"))
  {
    return 3;
  }
  if(nom.includes("Bureau"))
  {
    return 3;
  }
  if(nom.includes("Local technique"))
  {
    return 3;
  }
  if(nom.includes("Chalet"))
  {
    return 3;
  }
  if(nom.includes("Veranda"))
  {
    return 4;
  }
  if(nom.includes("Piscine"))
  {
    return 3;
  }
  if(nom.includes("Jardin avant"))
  {
    return 5;
  }
  if(nom.includes("Jardin arrière"))
  {
    return 8;
  }
  if(nom.includes("Salle de jeux"))
  {
    return 3;
  }
  else{
    return 0;
  }
}

export const listPrisesDefaut = (nom) => [{
  nom: "simple",
  valeur: simple(nom),
},
  {
    nom: "double",
    valeur: double(nom),
  },
  {
    nom: "triple",
    valeur: triple(nom),
  },
  {
    nom: "quadruple",
    valeur: 0,
  },
]

export const listEclairageDefaut = (nom) => [
  {
    interrupteurs: eclairage(nom),
  }
]

export const eclairage = (nom) => {
  if(nom.includes("Hall d'entrée") || nom.includes("Salle à manger") || nom.includes("Escalier") ||  nom.includes("Hall de nuit")){
    return 2;
  }
  else{
    return 1;
  }
}