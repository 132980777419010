import './Piece.css'
import {DevisContext} from "../../../../../Context/DevisContext";
import {useContext, useEffect, useMemo, useState} from "react";
import Niveau from "./Niveau/Niveau";
import {ListeNiveau} from "../ListeNiveau";
import * as React from "react";

const Piece = () => {
  const { devis } = useContext(DevisContext);
  const habitation = useMemo(() => devis?.habitation, [devis]);
  const [newListeNiveau, setNewListeNiveau] = useState(Object.keys(devis.niveaux ?? []));
  const [ajouteListeNiveau, setAjouteListeNiveau] = useState([]);
  const [open, setOpen] = React.useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const ajouteNiveau = (newNiveau) => { /*Ajoute un nouveau niveau et supprime le niveau selectionner de la liste des ajouts*/
    setNewListeNiveau((value) => [...value, newNiveau]);
    setAjouteListeNiveau(value => value.filter(niveau => niveau !== newNiveau));
    handleTooltipClose()
  }

  useEffect(() => {
    if(habitation !== null){
      ListeNiveau.forEach((item , idItem) => {
        if (item.defautNiveau.includes(habitation.nom) && Object.keys(devis?.niveaux ?? []).length === 0) {
          setNewListeNiveau((value) => [...value, item.nom]);
        }
        else if (!devis?.niveaux?.[item.nom]){
          setAjouteListeNiveau((value) => [...value, item.nom]);
        }
      });
    }
  }, []);

  return(
    <div className="contenuEtape fontAssisant">
      <div className="titreEtape texteInfoService center">Configurer les pièces de votre habitation</div>
      <div className="piece">
        <Niveau titre="Vos Pièces" setAjouteListeNiveau={setAjouteListeNiveau} setNewListeNiveau={setNewListeNiveau}/>
      </div>
    </div>
  );
}

export default Piece;
