import HexagoneAssistant from "../../HexagoneAssistant/HexagoneAssistant";
import {useContext, useEffect, useState} from "react";
import {DevisContext} from "../../../../../Context/DevisContext";

const Filaire = ({onClickHexa}) => {
  const { devis, setDevis } = useContext(DevisContext);
  const [selectionneAlarme, setSelectionneAlarme] = useState(devis.alarme ?? null);
  const list = [
    {
      nom:"Filaire",
    },
    {
      nom:"Sans fils",
    },
  ];

  useEffect(() => {
    setDevis((oldDevis) => ({
      ...oldDevis,
      typeAlarme: selectionneAlarme,
    }))
  }, [selectionneAlarme, setDevis]);

  return(
    <div className="contenuEtape fontAssisant">
      <div className="titreEtape texteInfoService center">Quel type d'installation pour votre alarme souhaitez-vous ?</div>
      <div className="hexagoneCentrer">
      <HexagoneAssistant
        ServicesItem={list}
        setSelectionHexagone={setSelectionneAlarme}
        selectionHexagone={selectionneAlarme}
        onClickHexa={onClickHexa}
      />
      </div>
    </div>
  );
}

export default Filaire;