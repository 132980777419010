import Checkbox from "@mui/material/Checkbox";
import './CheckBoxConfig.css'
import {useEffect, useState} from "react";

const CheckBoxConfig = ({nom, onChange, defaultCheck, isChecked }) => {
  const [checked, setChecked] = useState(defaultCheck ?? false);

  const checkChange = (event) => {
    setChecked(event.target.checked);
    if (onChange) {
      onChange(event.target.checked);
    }
  };
  useEffect(() => setChecked(isChecked), [isChecked])
  return(
    <div className="CheckBoxConfig">
      <Checkbox sx={{color: '#0A90C9', '&.Mui-checked': {color: '#0A90C9',},}} checked={checked} onChange={checkChange} name="check"/>
      <div className={checked === false ? "textePriseCheck textGris" : "textePriseCheck"}>{nom}</div>
    </div>
  );
}

export default CheckBoxConfig;