import './PointsPositif.css'
import PositifIcon from "../../../../Globale/Icons/PositifIcon";
import ScrollAnimation from 'react-animate-on-scroll';
import React from 'react';

const PointsPositif = () => {
  const PointsPsotifItem = [
    'Facile',
    'Choix',
    'Services',
    'Accessible',
  ];
  return(
    <ScrollAnimation animateIn='animate__fadeInLeft' className="pointsPositif">
      {PointsPsotifItem.map((item , idItem) => (
        <div
          key={'PointsPsotifItem' + idItem}
        >
          <div className="pointPosPosition">
            <PositifIcon sx={{ fontSize: 30 }} />
            <div className="textPointPos">{item}</div>
          </div>
        </div>
      ))}
    </ScrollAnimation>
  );
}

export default PointsPositif;