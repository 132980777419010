import React, {useState} from "react";
import axios from "axios";

export let AuthContext = React.createContext({})

export const AuthProvider = ({ children }) => {
  let [isConnected, setIsConnected] = useState(false);

  let signin = (email, motdepasse) => {
    setIsConnected(false);
    return axios.post('https://api.jelefaismoimeme.be/connexion.php', {
      email: email,
      motdepasse: motdepasse,
    }).then((response) => {
      setIsConnected(response.data.includes("connexionSucces"));
      //console.log(isConnected)
      return response;
    })
  };

  let signout = () => {
    isConnected = false;
  };
  let value = { isConnected, signin, signout };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}