import './FormulaireConnexion.css'
import React, {useEffect, useState} from "react";
import MuiAlert from "@mui/material/Alert";
import {Snackbar} from "@mui/material";
import Stack from "@mui/material/Stack";
import {NavLink, useLocation, useNavigate, Outlet} from "react-router-dom";
import {useAuth} from "../../../../Globale/Composants/Application/App";

const FormulaireConnexion = () => {
  const [email, setEmail] = useState('');
  const [motdepasse, setMotdepasse] = useState('');
  const [open, setOpen] = React.useState(false);
  const [messageErreur, setMessageErreur] = useState('');
  const [severity, setSeverity] = useState("success");
  //redirection
  let location = useLocation();
  let auth = useAuth();
  let navigate = useNavigate();

  /*Clean la memoire*/
  useEffect(() => {
    return () => {
     setEmail('')
      setMotdepasse('')
      setMessageErreur('')
      setSeverity("success")
    }
  }, []);

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });


  const reset = () => {
    setMotdepasse('');
    setEmail('');
  }

  /*Appel API pour valider la connexion ou non*/
  const validationConnexion = () => {
    auth.signin(email, motdepasse).then((response) => {
      if (response.data.includes("connexionSucces")) {
        reset()
        setOpen(true);
        setMessageErreur("Connexion réussie");
        setSeverity("success")
        navigate('/admin', { replace: true });
      } else {
        setOpen(true);
        setMessageErreur("Votre email ou/et mot de passe sont incorrects");
        setSeverity("error")
      }
    })
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  return(
    <div className="FormConnexion">
      <div className="titre1 margintop">Connexion</div>
      <div className="boxPlacement">
        <div className="titreBoxMail">Email</div>
        <input type="text" className="font borderArrondi boxMail" onInput={event => setEmail( event.target.value)} value={email}/>
      </div>
      <div className="boxPlacement">
        <div className="titreBoxMail">Mot de passe</div>
        <input type="password" className="font borderArrondi boxMail" onInput={event => setMotdepasse( event.target.value)} value={motdepasse}/>
      </div>
      <Stack spacing={2} sx={{ width: '100%' }}>
        <NavLink to="/admin">
          <div className="boxCentre">
            <button className="boutonEnvoyer" alt="bouton de connexion" loading="lazy" onClick={() => validationConnexion()}>Connexion</button>
          </div>
        </NavLink>
        <Outlet />
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert className="alert" onClose={handleClose} severity={severity} sx={{ width: '400px'}}>
            {messageErreur}
          </Alert>
        </Snackbar>
      </Stack>
    </div>
  );
}

export default FormulaireConnexion;