import './ConfigGenerale.css'
import FormControl from "@mui/material/FormControl";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import Checkbox from "@mui/material/Checkbox";
import * as React from "react";
import {useContext, useEffect, useState} from "react";
import {DevisContext} from "../../../../../../Context/DevisContext";
import TooltipInformation from "../tooltipInformation/TooltipInformation";

const ConfigGenerale = () => {
  const { devis, setDevis } = useContext(DevisContext);
  const [valueSonnette, setValueSonnette] = useState(devis?.configGenerale?.sonnette ?? '');
  const [checkedBorne, setCheckedBorne] = React.useState(devis?.configGenerale?.borne ?? false);
  const [checkedCoffret, setCheckedCoffret] = React.useState(devis?.configGenerale?.coffretSecondaire ?? false);
  const [distanceCoffret, setDistanceCoffret] = React.useState(devis?.configGenerale?.distanceCoffret ?? 10);
  const [distanceBorne, setDistanceBorne] = React.useState(devis?.configGenerale?.distanceBorne ?? 20);

  const checkChange = (event) => {
    setCheckedBorne(event.target.checked);
  };
  const checkedCoffretChange = (event) => {
    setCheckedCoffret(event.target.checked);
  };

  const handleChange = (event) => {
    setValueSonnette(event.target.value);
  };

  useEffect(() => {
    setDevis((oldDevis) => ({
      ...oldDevis,
      configGenerale: {
        sonnette: valueSonnette,
        borne: checkedBorne,
        distanceBorne : distanceBorne,
        coffretSecondaire: checkedCoffret,
        distanceCoffret : distanceCoffret,
      }
    }))
  }, [valueSonnette, checkedBorne, distanceCoffret, distanceBorne, checkedCoffret, setDevis]);

  return(
    <div className="fontAssisant">
      <div className="titreEtape texteInfoService center">Configurer les éléments générale de votre habitation</div>
      <div className="configuration">
        <div className="piece">
          <div className="configGenerale">
            <div className="center niveauTitre">Générale</div>
            <div className="texteExplication">Sélectionner les éléments souhaités pour votre habitation.</div>
            <div className="elementGenerale" >
              <div className="checkGeneralePosition">
                <div className="radioBouton">
                  <FormControl>
                    <div className="center marginBottom">Communication</div>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      defaultValue=''
                      value={valueSonnette}
                      onChange={handleChange}
                    >
                      <FormControlLabel  value="Sonnette"
                                         control={<Radio sx={{color: '#0A90C9', '&.Mui-checked': {color: '#0A90C9',},}}/>}
                                         label="Sonnette" className={valueSonnette === "Sonnette" ? "" : "textGris"}/>
                      <FormControlLabel value="Parlophone"
                                        control={<Radio sx={{color: '#0A90C9', '&.Mui-checked': {color: '#0A90C9',},}}/>}
                                        label="Parlophone" className={valueSonnette === "Parlophone" ? "" : "textGris"}/>
                      <FormControlLabel value="Vidéophone"
                                        control={<Radio sx={{color: '#0A90C9', '&.Mui-checked': {color: '#0A90C9',},}}/>}
                                        label="Vidéophone" className={valueSonnette === "Vidéophone" ? "" : "textGris"}/>
                      <FormControlLabel value="Vidéophone avec appel sur GSM"
                                        control={<Radio sx={{color: '#0A90C9', '&.Mui-checked': {color: '#0A90C9',},}}/>}
                                        label="Vidéophone avec appel sur GSM" className={valueSonnette === "Vidéophone avec appel sur GSM" ? "" : "textGris"}/>
                    </RadioGroup>
                  </FormControl>
                </div>
              </div>
              <div className="checkGeneralePosition">
                <div className="center marginBottom">Alimentation supplémentaire</div>
                <div className="checkGenerale">
                  <Checkbox sx={{color: '#0A90C9', '&.Mui-checked': {color: '#0A90C9',},}} checked={checkedBorne} onChange={checkChange} name="Borne"/>
                  <div className={checkedBorne === false ? "textePriseGenerale textGris" : "textePriseGenerale"}>Borne de recharge</div>
                  <TooltipInformation texte="Comprend une borne fixe au mur multi-tension de 22kw avec 8m de câble électrique pour son alimentation et 1 disjoncteur."/>
                </div>
                {checkedBorne === true ?
                  <div className="checkGenerale marginBottom">
                    <input type="number" className="inputCoffret font" min="0" onInput={event => setDistanceBorne(event.target.value)} value={distanceBorne}/>
                    <div>m de distance du coffret principale</div>
                  </div>
                :
                  <div/>
                }
                <div className="checkGenerale">
                  <Checkbox sx={{color: '#0A90C9', '&.Mui-checked': {color: '#0A90C9',},}} checked={checkedCoffret} onChange={checkedCoffretChange} name="Borne"/>
                  <div className={checkedCoffret === false ? "textePriseGenerale textGris" : "textePriseGenerale"}>Coffret secondaire</div>
                  <TooltipInformation texte="Comprend une coffret 2 rangées équipés d’un disjoncteurs général en attente et d’un câble de liaison de 30m."/>
                </div>
                {checkedCoffret === true ?
                  <div className="checkGenerale">
                    <input type="number" className="inputCoffret font" min="0" onInput={event => setDistanceCoffret(event.target.value)} value={distanceCoffret}/>
                    <div>m de distance du coffret principale</div>
                  </div>
                  :<div/>
                }

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  );
}

export default ConfigGenerale;