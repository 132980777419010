import * as React from "react";
import {useContext, useEffect, useState} from "react";
import {DevisContext} from "../../../../../Context/DevisContext";
import {Button} from "@mui/material";
import HorizontalRuleIcon from "@mui/icons-material/HorizontalRule";
import AddIcon from "@mui/icons-material/Add";

const NombreCamera = () => {
  const { devis, setDevis } = useContext(DevisContext);
  const [cameraInterieur, setCameraInterieur] = useState(devis?.camera?.interieur ?? 0);
  const [cameraExterieur, setCameraExterieur] = useState(devis?.camera?.exterieur ?? 0);

  useEffect(() => {
    setDevis((oldDevis) => ({
      ...oldDevis,
      camera:{
        interieur: cameraInterieur,
        exterieur: cameraExterieur,
      },
    }))
  }, [cameraInterieur, cameraExterieur, setDevis]);

  const retireCameraInterieur = () => {
    if (cameraInterieur > 0) {
      setCameraInterieur(cameraInterieur - 1)
    }
  }
  const retireCameraExterieur = () => {
    if(cameraExterieur > 0){
      setCameraExterieur(cameraExterieur-1)
    }
  }
  const ajouteCameraInterieur = () => {
    if(cameraInterieur+cameraExterieur < 8){
      setCameraInterieur(cameraInterieur+1)
    }
  }
  const ajouteCameraExterieur = () => {
    if(cameraInterieur+cameraExterieur < 8){
      setCameraExterieur(cameraExterieur+1)
    }
  }

  return(
    <div className="contenuEtape fontAssisant camera detecteur">
      <div className="titreEtape texteInfoService center">Combien de caméra souhaitez-vous en intérieur et en extérieur ? (maximum 8 au total)</div>
      <div className="Boxcenter checkGeneralePosition">
        <div>
          <div className="nombreprise marginBottom">
            <div className="nombreprise styleBtnDevis">
              <Button variant="contained" onClick={retireCameraInterieur} ><HorizontalRuleIcon sx={{ fontSize: 15 }}/></Button>
              <div className={ cameraInterieur === 0 ? "quantitePrise textGris" : "quantitePrise"}>{cameraInterieur}</div>
              <Button variant="contained" onClick={ajouteCameraInterieur}><AddIcon sx={{ fontSize: 15 }}/></Button>
            </div>
            <div className={cameraInterieur === 0 ? "textePrise textGris" : "textePrise"}>Caméra interieur</div>
          </div>
          <div className="nombreprise marginBottom">
            <div className="nombreprise styleBtnDevis">
              <Button variant="contained" onClick={retireCameraExterieur} ><HorizontalRuleIcon sx={{ fontSize: 15 }}/></Button>
              <div className={ cameraExterieur === 0 ? "quantitePrise textGris" : "quantitePrise"}>{cameraExterieur}</div>
              <Button variant="contained" onClick={ajouteCameraExterieur}><AddIcon sx={{ fontSize: 15 }}/></Button>
            </div>
            <div className={cameraExterieur === 0 ? "textePrise textGris" : "textePrise"}>Caméra exterieur</div>
          </div>
        </div>
        </div>
    </div>
  );
}

export default NombreCamera;