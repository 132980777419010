import {useContext, useEffect, useState} from "react";
import HexagoneAssistant from "../../HexagoneAssistant/HexagoneAssistant";
import {DevisContext} from "../../../../../Context/DevisContext";

const Toit = ({onClickHexa}) => {
  const { devis, setDevis } = useContext(DevisContext);
  const [selectionneToit, setSelectionneToit] = useState(devis.toit ?? null);
  const toit = [
    {
      nom:"Plat",
    },
    {
      nom:"Incliné",
    },
  ];

  useEffect(() => {
    setDevis((oldDevis) => ({
      ...oldDevis,
      toit: selectionneToit,
    }))
  }, [selectionneToit, setDevis]);


  return(
    <div className="contenuEtape fontAssisant">
      <div className="titreEtape texteInfoService center">Votre toiture est plate ou incliné ?</div>
      <div className="hexagoneCentrer">
        <HexagoneAssistant
          ServicesItem={toit}
          setSelectionHexagone={setSelectionneToit}
          selectionHexagone={selectionneToit}
          onClickHexa={onClickHexa}/>
      </div>
    </div>
  );
}

export default Toit;