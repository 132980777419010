import React from 'react';
import { SvgIcon } from "@mui/material";

function ConnecteIcon(props) {
  return (
    <SvgIcon {...props} viewBox="0 0 411.95 385.99">
      <path d="M410.89,325.39q-28.72,28.88-57.46,57.76a9.31,9.31,0,0,1-13.06.33l-94.05-87.8a9.31,9.31,0,0,0-13.35.64L189.5,345.48a9.33,9.33,0,0,1-16.14-4.37L133.51,139.65a9.34,9.34,0,0,1,10.12-11.09l205.49,21.31a9.33,9.33,0,0,1,5.93,15.56l-43.75,48a9.34,9.34,0,0,0,.43,13l96.61,87.15a10,10,0,0,1,3.57,6.64A9.49,9.49,0,0,1,410.89,325.39Z"/>
      <path d="M29.25,206.25l0-.05a39.62,39.62,0,0,1,1.6-55.11l.76-.73a39.62,39.62,0,0,1,56.79,1.54h0a39.63,39.63,0,0,1,.12,53.55l-.71.78A39.63,39.63,0,0,1,29.25,206.25Z"/>
      <path d="M148.48,78.56l0,0A39.62,39.62,0,0,1,150,23.41l.76-.74a39.63,39.63,0,0,1,56.79,1.54h0a39.63,39.63,0,0,1,.12,53.55l-.71.78A39.63,39.63,0,0,1,148.48,78.56Z"/>
      <path d="M14.32,92.3l-.06-.06a54.47,54.47,0,0,1,2.21-75.77l1-1a54.47,54.47,0,0,1,78.08,2.12h0a54.47,54.47,0,0,1,.17,73.63l-1,1.08A54.47,54.47,0,0,1,14.32,92.3Z"/>
    </SvgIcon>
  );
}

export default ConnecteIcon;