import React from 'react';
import { SvgIcon } from "@mui/material";

function PositifIcon(props) {
  return (
    <SvgIcon {...props} viewBox="0 0 40 40">
      <path d="M36 0L11.2 27.2727L4 21.8193H0L11.2 40L40 0H36Z" fill="#D90368"/>
    </SvgIcon>
  );
}

export default PositifIcon;