import './Centrale.css'
import {useContext, useEffect, useState} from "react";
import {DevisContext} from "../../../../../Context/DevisContext";
import CheckBoxConfig from "../Configuration/CheckBoxConfig/CheckBoxConfig";
import CompterComposant from "../CompterComposant/CompterComposant";
import Communication from "../Communication/Communication";

const Centrale = () => {
  const { devis, setDevis } = useContext(DevisContext);
  const [sireneInt,setSireneInt] = useState(devis?.centrale?.sireneInterieur ?? true);
  const [sireneEx,setSireneEx] = useState(devis?.centrale?.sireneExterieur ?? false);
  const [clavier,setClavier] = useState(devis?.centrale?.clavier ?? 1);
  const [telecommande,setTelecommande] = useState(devis?.centrale?.telecommande ?? 0);

  useEffect(() => {
    setDevis((oldDevis) => ({
      ...oldDevis,
      generale:{
        sireneInterieur: sireneInt,
        sireneExterieur: sireneEx,
        clavier: clavier,
        telecommande: telecommande,
      },
    }))
  }, [ clavier, telecommande, sireneEx, sireneInt, setDevis]);

  return(
    <div className="contenuEtape fontAssisant centrale">
      <div className="titreEtape texteInfoService center">Choisissez vos besoins</div>
      <div className="elementGenerale">
        <div className="checkGeneralePosition">
          <div className="center marginBottom">Commande</div>
          <CompterComposant texte="Clavier" maximum={2} nombre={clavier} setNombre={setClavier}/>
          <CompterComposant texte="Télécommande / Badge" maximum={2} nombre={telecommande} setNombre={setTelecommande}/>
        </div>
        <div className="checkGeneralePosition">
          <div className="center marginBottom">Alerte</div>
          <CheckBoxConfig nom="Sirène intérieur" defaultCheck={sireneInt} onChange={setSireneInt} isChecked={sireneInt}/>
          <CheckBoxConfig nom="Sirène extérieur" defaultCheck={sireneEx} onChange={setSireneEx} isChecked={sireneEx}/>
        </div>
        <div className="checkGeneralePosition">
          <Communication/>
        </div>
      </div>
    </div>
  );
}

export default Centrale;