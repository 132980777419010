import './Menu.css'
import {useCallback, useEffect, useState} from "react";
import React from 'react';
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {Button} from "@mui/material";
import ConnexionIcon from "../../Icons/ConnexionIcon";
import logo from "../../Images/logoJelefaismoimeme.png";
import {
  NavLink, useNavigate
} from "react-router-dom";
import {Electricite} from "../../../Pages/ExempleDevis/Electricite";
import {Borne} from "../../../Pages/ExempleDevis/Borne";
import {Alarme} from "../../../Pages/ExempleDevis/Alarme";
import {Panneaux} from "../../../Pages/ExempleDevis/Panneaux";
import {Camera} from "../../../Pages/ExempleDevis/Camera";

const Menu = ({statusClick}) => {
  const navigate = useNavigate();
  const [setSelected] = useState(0);
  const [btnDevis, setBtnDevis] = useState("Commencer mon devis");

  const navBarItem = [
    <div className="onglet"><NavLink to="/">Accueil</NavLink></div>,
    <div className="onglet"><NavLink to="/showroom">Showroom</NavLink></div>,
    <div className="onglet ongletExemple">
      <div className="ongletName">Type d'installation<KeyboardArrowDownIcon className="iconSous-menu"/></div>
      <div className="sousMenu">
        <NavLink to="/electricite">{Electricite.nom}</NavLink>
        <NavLink to="/alarme">{Alarme.nom}</NavLink>
        <NavLink to="/camera">{Camera.nom}</NavLink>
        <NavLink to="/panneaux">{Panneaux.nom}</NavLink>
        <NavLink to="/borne">{Borne.nom}</NavLink>
      </div>
    </div>,
    <div className="onglet"><NavLink to="/contact">Contact</NavLink></div>,
  ];

  useEffect(() => {
    if(statusClick === true){
      setBtnDevis("Recommencer mon devis")
    }
  })

  const resetDevis = useCallback(() => {
    navigate('/devis', { statusClick });
  }, [navigate, statusClick]);

  return (
    <nav className="navigationBarre">
      <div className="containerGauche">
        <NavLink to="/" className="logo">
          <img src={logo} alt="Logo" loading="eager" className="logo"/>
        </NavLink>
        <div className="menu">
          {navBarItem.map((onglet, idOnglet) => (
            <Button
              key={'navBarItem' + idOnglet}
              onClick={() => setSelected(idOnglet)}
            >
              {onglet}
            </Button>
          ))}
        </div>
      </div>
      <div className="containerDroite">
        <Button className="btnDevis" onClick={resetDevis}>{btnDevis}</Button>
        <div className="connexionIcon">
          <NavLink to="/connexion" target="_blank">
            <ConnexionIcon/>
          </NavLink>
        </div>
      </div>
    </nav>
  );
}

export default Menu;