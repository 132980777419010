import ConfigPiece from "../ConfigPiece/ConfigPiece";
import {Button} from "@mui/material";
import * as React from "react";
import {useContext, useEffect, useMemo, useRef, useState} from "react";
import {DevisContext} from "../../../../../../Context/DevisContext";
import TutoPiece from "../TutoPiece/TutoPiece";
import {
  listDimensionLongueur,
  listEclairageDefaut,
  listPrisesDefaut,
  listPrisesMultiDefaut,
  listPrisesSpecialesDefaut,
  listAutreOptionDefaut, listDimensionLargeur,
} from "../../ListeConfigurationPiece";
import {TutoContext} from "../../../../../../Context/TutoContext";

const ConfigPieces = () => {
  const { devis, setDevis } = useContext(DevisContext);
  const { fermetureTuto, setFermetureTuto} = useContext(TutoContext);
  const [index, setindex] = useState(0);

  /*Boucle toutes les pièces avec des numéro si la pièce est mis plusieur fois dans le niveau Exemple: Chambre 2 */
  const pieces = useMemo(() => {
      return [...devis.niveaux.reduce((acc, item) => {
        for (let idx = 0; idx < item.nombre; idx++) {
          const nom = item.nombre > 1 ? `${item.piece} ${idx + 1}` : item.piece;
          acc.push({...item, piece: nom});
        }
        return acc;
      }, [])];
    }, [devis]);

  /*Permet d'enregistrer les config de base des pièces sans devoir passer en revue toute les pièces*/
    useEffect(() => {
      setDevis(oldDevis => {
        if (!oldDevis) {
          oldDevis = {};
        }
        pieces.forEach(piece => {
          if (!oldDevis.configPiece) {
            oldDevis.configPiece = {}
          }
          if (!oldDevis.configPiece[piece.piece]) {
            oldDevis.configPiece[piece.piece] = {
              eclairage: listEclairageDefaut(piece.piece).map(({ interrupteurs }) => ({ interrupteurs })),
              autre: {
                DCPiece: piece.niveau === "Rez de chaussée" ? 10 : 15,
                longueur: listDimensionLongueur(piece.piece),
                largeur: listDimensionLargeur(piece.piece),
                option: listAutreOptionDefaut(piece.piece).map(({nom, option}) => ({ nom, option })),
              },
              prises: listPrisesDefaut(piece.piece).map(({nom, valeur}) => ({ nom, valeur })),
              prisesMulti: listPrisesMultiDefaut(piece.piece).map(({nom, valeur}) => ({ nom, valeur })),
              prisesSpeciales: listPrisesSpecialesDefaut(piece.piece).map(({nom, valeur}) => ({ nom, valeur })),
            }
          }
        })
        if(pieces.length !== 0){
          const reversePieces = Object.keys(oldDevis.configPiece).map(niveau => Object.keys(oldDevis.configPiece).map(piece => ({piece }))).flat();
          reversePieces.forEach(piece => {
            if (!pieces.find(p => p.piece === piece.piece)) {
              delete oldDevis.configPiece[piece.piece];
            }
          });
        }
        return oldDevis;
      })
    }, [pieces, setDevis]);

    const id = "piece";
    const piecePrecedent = () => {
      if(index > 0){
        setindex(index-1)
        window.scrollTo(0, window.scrollY + document.getElementById(id).getBoundingClientRect().top - 200);
      }
    }

    const pieceSuivante = () => {
      if(index < pieces.length-1){
        setindex(index+1)
        window.scrollTo(0, window.scrollY + document.getElementById(id).getBoundingClientRect().top - 200);
      }
    }

  const btnFlottantPieceRef = useRef(null);
  const fixedClassPiece = 'btnFlottantPieceFixed';
  let updatingPiece = false;

  const handleScrollPiece = () => {

    if (( window.innerHeight + 60 - document.querySelector('.piedDePage').getBoundingClientRect().top< 0)) //&& document.body.scrollHeight < 1200) // < plus petit
    {
      btnFlottantPieceRef.current?.classList.add(fixedClassPiece)
    }
    else{
      btnFlottantPieceRef.current?.classList.remove(fixedClassPiece)
    }
    updatingPiece = false
  }

  window.onscroll = () => {
    if (updatingPiece) return
    else {
      updatingPiece = true
      requestAnimationFrame(handleScrollPiece)
    }
  }

  return (
    <div className="piece" id="piece">
      <div className={fermetureTuto === false ? "flouTuto configPieceCentre" : "configPieceCentre"}>
        { pieces.length !== 0 ?(
          <div>
            <ConfigPiece nom={pieces[index].piece} key={index} piecelong={pieces.length} pieceIndex={index+1}/>
            <div className="btnSuivantStyle">
              <div className="styleBtnDevis marginTop" ref={btnFlottantPieceRef}>
                <div className="positionFleche">
                  <Button onClick={piecePrecedent} disabled={index === 0}>Pièce précédente</Button>
                  <Button onClick={pieceSuivante} disabled={index === pieces.length - 1}>Pièce suivante</Button>
                </div>
              </div>
            </div>
          </div>
        ):<div/>
        }
      </div>
      <TutoPiece tuto={fermetureTuto} setTuto={setFermetureTuto}/>
    </div>
  );
}

export default ConfigPieces;