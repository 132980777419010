import {Button, Snackbar} from "@mui/material";
import React, {useState} from "react";
import axios from "axios";
import MuiAlert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";

/*Bouton pour sauvegarder les videos*/
const BoutonSauvegarderVideo = ({video, date, origineDate, id, onClick}) => {
  const [open, setOpen] = React.useState(false);
  const [messageErreur, setMessageErreur] = useState('');
  const [severity, setSeverity] = useState("success");

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  /*Ouvrir le message d'erreur*/
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  /*Condition a respecter avant de pouvoir faire l'appelle APi pour sauvegarder*/
  const sauvegarder = () => {
    //console.log("save:"+video)
    if(video !== "Video"){
      if(origineDate !== "always"){
        if(date.length === 0){
          setOpen(true);
          setSeverity("error")
          setMessageErreur("Vous n'avez pas mis de date")
        }
        if(date[1] === undefined || date[0] === undefined || date[0] === null || date[1] === null){
          setOpen(true);
          setSeverity("error")
          setMessageErreur("Vous n'avez pas compléter correctement la date")
        }
        else{
          AppelApi();
        }
      }
      else{
        AppelApi();
      }
    }
    else{
      setOpen(true);
      setSeverity("error")
      setMessageErreur("Vous n'avez pas mis de vidéo")
    }
  }

  const AppelApi = () => {
    var formData = new FormData();
    formData.append("video", video);
    formData.append("id", id);
    formData.append("date", date);
    formData.append("dateDebut", date[0]);
    formData.append("dateFin", date[1]);
    axios.post('https://api.jelefaismoimeme.be/uploadVideo.php', formData,{
      headers: {
        'Content-Type': 'multipart/form-data'
      },
    })
      .then((response) => {
        onClick();
        setOpen(true);
        if(response.data.includes("Erreur")){
          setSeverity("error")
          setMessageErreur(response.data)
        }
        else{
          setSeverity("success")
          setMessageErreur("La vidéo a été sauvegarder")
        }
      });
  }

  return(
    <div>
      <div className="modifbtn">
        <Button variant="contained" size="large" onClick={sauvegarder}>Sauvegarder</Button>
      </div>
      <Stack spacing={2} sx={{ width: '100%' }}>
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert className="alert" onClose={handleClose} severity={severity} sx={{ width: '400px'}}>
            {messageErreur}
          </Alert>
        </Snackbar>
      </Stack>
    </div>
  );
}

export default BoutonSauvegarderVideo;