import './ModifDevis.css'
import * as React from 'react';
import TableauDevis from "../TableauDevis/TableauDevis";

const ModifDevis = () =>{

  return(
    <div className="modifDevis">
        <TableauDevis/>
    </div>
  );
}

export default ModifDevis;