import './ModifActualite.css'
import useGalerie from "../../../../Hook/useGalerie";
import React, {useContext, useEffect, useState} from "react";
import BoutonSauvegarder from "./BoutonSauvegarder";
import BoutonImporterImageActu from "../BoutonImporterImageActu/BoutonImporterImageActu";
import {AdminContext} from "../../../../Context/AdminContext";
import CorbeilleIcon from "../../../../Globale/Icons/corbeilleIcon";
import DatePicker from "../DatePicker/DatePicker";
import moment from "moment";

const ModifActualite = ({Actualite}) => {
  const {refresh} = useGalerie({composant: "actualite", dossier: ""});
  const { actualite: { refresh: refreshActu, listActu } } = useContext(AdminContext);
  const [nom, setNom] = useState('');
  const [position, setPosition] = useState('');
  const [description, setDescription] = useState('');
  const [image, setImage] = useState('');
  const [id, setId] = useState('');
  const [setEtatSauvegarde] = useState(false);
  const [date, setDate] = useState([]);

  useEffect(() => {
    if (Actualite?.id) {
      setNom(Actualite.nom)
      setDescription(Actualite.description)
      setImage(Actualite.image)
      setId(Actualite.id)
      setPosition(Actualite.position)
      setDate([moment(Actualite.dateDebut), moment(Actualite.dateFin)])
    }
  }, [Actualite])

  const resteImage = () => {
    setImage(null)
    refresh()
  }

  if (!Actualite) return null;
  return (
    <div className="ModifActualite">
      <div className="spacetop">
        <BoutonSauvegarder nom={nom} description={description} image={image} id={id} setEtatSauvegarde={setEtatSauvegarde}
                           onClick={refreshActu} position={position} maxPosition={listActu?.length} date={date} setDate={setDate}/>
        <div className="espacementbox borderArrondi">
          <div>Nom :</div>
          <input className="borderArrondi espacebox" value={nom} onChange={event => setNom(event.target.value)}/>
        </div>
        <div className="espacementbox">
          <div>Description :</div>
          <textarea value={description} className="boxtextarea borderArrondi" onChange={event => setDescription(event.target.value)}/>
        </div>
        <div className="espacementbox">
          <div>Image :</div>
          <BoutonImporterImageActu image={image} setImage={setImage} onClick={refreshActu}/>
          {image !== "" && image !== null && image !== undefined ? (
            <div className="imageActu">
              <button className="corbeille" onClick={resteImage}>
                <CorbeilleIcon/>
              </button>
              <img src={typeof image === 'string' ? `https://entrepot.jelefaismoimeme.be/actualite/${image}` : URL.createObjectURL(image)} alt="image actualite"
                   className="imageModif"/>
            </div>
          ) : <div/>
          }
        </div>
      </div>
      <div className="espacementbox">
        <div>Position(entre 1 et {listActu.length}) :</div>
        <input className="inputPosition borderArrondi" type="number" value={position} onChange={event => setPosition(event.target.value)} min="1" max={listActu.length}/>
      </div>
      <DatePicker date={date} setDate={setDate}/>
    </div>
  );
}

export default ModifActualite;