import React, {useEffect, useState} from "react";
import usePopup from "../Hook/usePopup";

export const PopupContext = React.createContext({
  popup: {
    popup: [],
    refresh: () => {},
  },
})

export const PopupProvider = ({ children }) => {
  const [fermeturePopup, setFermeturePopup] = useState(false);

  const value = {
    fermeturePopup,
    setFermeturePopup,
    popup: usePopup(),
  }

  return <PopupContext.Provider value={value}>{children}</PopupContext.Provider>;
};