import './ModifVideo.css'
import React, {useContext, useEffect, useRef, useState} from "react";
import BoutonChangerVideo from "../BoutonChangerVideo/BoutonChangerVideo";
import {AdminContext} from "../../../../Context/AdminContext";
import BoutonSauvegarderVideo from "../Sauvegarder/BoutonSauvegarderVideo";
import DatePicker from "../DatePicker/DatePicker";
import moment from "moment";

const ModifVideo = ({videoSelectionne}) =>{
  const [changevideo, setChangeVideo] = useState(videoSelectionne?.video);
  const { video: { refresh }} = useContext(AdminContext);
  const [date, setDate] = useState([]);
  const videoRef = useRef();

  /*déclenchez un loadévénement pour l'élément vidéo chaque fois qu'il y a une nouvelle URL*/
  useEffect(() => {
    videoRef.current?.load();
  }, [changevideo, videoSelectionne]);

  const formatDate = () => {
    if(videoSelectionne && (videoSelectionne.dateDebut !== "" || videoSelectionne.dateDebut !== "always")){
      return setDate([moment(videoSelectionne.dateDebut), moment(videoSelectionne.dateFin)])
    }
    else{
      return setDate([])
    }
  }

  /*Recharge les données des que la vidéo selectionner est changé*/
  useEffect(() => {
    formatDate()
  }, [])

  if (!videoSelectionne) return null;
  return(
    <div className="ModifVideo">
      <div className="boutonvideo">
        <BoutonSauvegarderVideo video={changevideo} date={date} origineDate={videoSelectionne.dateDebut} id={videoSelectionne.id} onClick={refresh}/>
        <BoutonChangerVideo changevideo={changevideo} setChangeVideo={setChangeVideo} onClick={refresh} />
      </div>
      <div className="titrevideo" >Titre vidéo: {videoSelectionne?.video}</div>
      {(videoSelectionne?.video ?? "Video") !== "Video" && videoSelectionne !== "" ?(
        <video ref={videoRef} className='video' controls loop muted >
          <source src={"https://entrepot.jelefaismoimeme.be/video/"+videoSelectionne.video} type="video/mp4" />
        </video>
        ): <div/>
      }
      {videoSelectionne?.dateDebut !== "always" ?(
        <DatePicker date={date} setDate={setDate}/>
        ): <div/>
      }
    </div>
  );
}

export default ModifVideo;