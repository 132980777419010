import './Accueil.css'
import Menu from "../../Globale/Composants/Menu/Menu";
import PiedDePage from "../../Globale/Composants/PiedDePage/PiedDePage";
import Services from "./Composants/Services/Services";
import Description from "./Composants/Description/Description";
import Actualites from "./Composants/Actualites/Actualites";
import Galerie from "./Composants/Galerie/Galerie";
import DonneesClients from "./Composants/DonneesClients/DonneesClients";
import Avis from "./Composants/Avis/Avis";
import Partenaires from "./Composants/Partenaires/Partenaires";
import React, {useContext} from 'react';
import PointsPositif from "./Composants/PointsPositif/PointsPositif";
import suivant from "./Media/next.png"
import ScrollAnimation from 'react-animate-on-scroll';
import "animate.css/animate.min.css";
import boutonDevis from "../../Globale/Images/Bouton devis.png";
import {NavLink} from "react-router-dom";
import StickyNav from "../../Globale/Composants/StickyNav/StickyNav";
import ScrollTop from "../../Globale/Composants/ScrollTop/ScrollTop";
import useVideo from "../../Hook/useVideo";
import Video from "./Composants/Video/Video";
import Popup from "../../Globale/Composants/Popup/Popup";
import {PopupContext} from "../../Context/PopupContext";
import Promo from "../Devis/Composants/PrixDevis/Promo/Promo";
import FormulaireAvis from "./Composants/FormulaireAvis/FormulaireAvis";

const Accueil = () => {
  const { video } = useVideo();
  const { fermeturePopup, popup} = useContext(PopupContext);

  if (!video) return (<div/>)

  return (
    <div className="accueil">
      <ScrollTop />
      <Popup/>
      <div className={fermeturePopup === false && popup.popup.status === true ? "flouTuto desactiveClick" : ""}>
        <header>
          <StickyNav height='600'>
            <Menu />
          </StickyNav>
          <Video/>
          <Promo/>
          {
            <ScrollAnimation animateIn="animate__pulse" className="fleche">
              <img src={suivant} loading="lazy" alt="suite de la page" />
            </ScrollAnimation>
          }
          <NavLink to="/devis"  className="btnDevisVideo">
            <img src={boutonDevis} loading="lazy" alt="Bouton devis" />
          </NavLink>
        </header>
        <div>
          <Services />
          <Description />
          <PointsPositif />
          <Actualites />
          <Galerie dossier='accueil'/>
          <DonneesClients />
          <div className="ellipseRose"></div>
          <div className="ellipseBleu"></div>
          <Avis />
          <Partenaires />
          <FormulaireAvis />
        </div>
        <PiedDePage />
      </div>
    </div>
  );
}

export default Accueil;