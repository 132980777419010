import './PrixDevis.css'
import {Button, Snackbar, TextField} from "@mui/material";
import React, {useContext, useEffect, useState} from "react";
import validator from "validator";
import {DevisContext} from "../../../../Context/DevisContext";
import RecapitulatifDevis from "./RecapitulatifDevis/RecapitulatifDevis";
import axios from "axios";
import Stack from "@mui/material/Stack";
import MuiAlert from "@mui/material/Alert";
import ScrollTop from "../../../../Globale/Composants/ScrollTop/ScrollTop";
import CloseIcon from "@mui/icons-material/Close";

const PrixDevis = ({changeComposant, setRecupererDevis, setNumeroActiveStep, numeroActiveStep}) => {
  const {devis} = useContext(DevisContext);
  const [valideEmail, setValideEmail] = useState(false);
  const [tel, setTel] = useState(false);
  const [mail, setMail] = useState(devis?.client?.mail ?? null);
  const [telephone, setTelephone] = useState(devis?.client?.telephone ?? null);
  const [devisReponse, setDevisReponse] = useState();
  const [open, setOpen] = React.useState(false);
  const [messageErreur, setMessageErreur] = useState('');
  const [severity, setSeverity] = useState("success");
  const tva = 'TVA de' + devis?.construction === 'Nouvelle construction' ? ' 21%' : ' 6%';
  const [codePromo, setCodePromo] = useState('');
  const [valeurCode, setValeurCode] = useState('');
  const [codePromoActif, setCodePromoActif] = useState(false);
  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  /*Api qui recois le devis et renvoie le calcule du prix et les textes correspondant*/
  useEffect(() => {
    if(devis !== null){
      if(!devis.secret){
        axios.post('https://api.jelefaismoimeme.be/devis.php', {
          devis: devis,
        }).then((response) => {
            setDevisReponse(response.data[0])
          }
        );
      }
      else{
        axios.post('https://api.jelefaismoimeme.be/afficherDevis.php', {
          devis: devis,
        }).then((response) => {
            setDevisReponse(response.data[0])
          }
        );
      }
    }
  }, [devis])

  //useEffect(() => console.log('Promo', listPromo), [listPromo]);

  const validationEmail = (e) => {
    var email = e.target.value
    if (validator.isEmail(email)) {
      setValideEmail(true)
    } else {
      setValideEmail(false)
    }
  }
  const validatePhoneNumber = (e) => {
    var entreTel = e.target.value
    const isValidPhoneNumber = validator.isMobilePhone(entreTel)
    setTel(isValidPhoneNumber)
  }
  const verificationCodePromo = () => {
    if (codePromoActif === false) {
      axios.post('https://api.jelefaismoimeme.be/verificationPromoValide.php', {
        typeDevis: devis?.devis?.nom.toLowerCase().normalize('NFD')?.replace(/([\u0300-\u036f]|[^0-9a-zA-Z])/g, ''),
      })
        .then((response) => {
          if(response.data !== null){
            response.data.map((item) => {
              if (item.code.toLowerCase() === codePromo.toLowerCase()) {
                setMessageErreur("Votre code promo est activé")
                setSeverity("success")
                setValeurCode(codePromo)
                setCodePromoActif(true)
                reset()
                ajoutPromoDevis(item)
              } else {
                setMessageErreur("Votre code promo n'est pas valide")
                setSeverity("error")
              }
            })
          }
          else{
            setMessageErreur("Votre code promo n'est pas valide")
            setSeverity("error")
          }
        });
    } else {
      setMessageErreur("Vous avez déjà activé un code promo")
      setSeverity("error")
    }
    setOpen(true);
  }

  const ajoutPromoDevis = (donneesCode) => {
    axios.post('https://api.jelefaismoimeme.be/appliquerPromoDevis.php', {
      donneesCode : donneesCode,
      numeroDevis : devisReponse?.secret,
      devis : devis,
      prix: devisReponse?.prix,
      prixDomotique: devisReponse?.prixDomotique,
      prixConnecte: devisReponse?.prixConnecte,
      prixPVMax: devisReponse?.prixPVMax,
      prixPVMin: devisReponse?.prixPVMin,
    })
      .then((response) => {
        setDevisReponse(response.data[0])
      })
  }

  const precedent = () => {
    changeComposant(false)
    setRecupererDevis(false)
    setNumeroActiveStep(numeroActiveStep)
  }

  const installation = (nom) => {
    const installation = {
      "Panneaux photo voltaïque": "de panneaux photovoltaïques",
      "Electricité": "d'électricité",
      "Borne": "de borne rechargable",
      "Alarme": "d'alarme",
      "Caméra": "de caméra",
    }
    return installation[nom];
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  /*Vide les input une fois que que la donnée entrée a été envoyer*/
  const reset = () => {
    setValideEmail(false)
    setMail('')
    setTelephone('')
    setCodePromo('')
  }

  /*Envoie du numéro de telephone*/
  const envoieTel = () => {
    if (tel === false) {
      setSeverity("error")
      setMessageErreur("Votre numéro de téléphone est incorrecte")
      setOpen(true);
    } else { //A finir
      reset()
      apiEnvoieTel()
    }
  }

  /*Envoie du mail a l'administration pour pouvoir recontacter*/
  const apiEnvoieMailAdmin = () => {
    const message = 'Numéro du devis: ' + devisReponse?.secret;
    axios.post('https://api.jelefaismoimeme.be/mail.php', {
      mail: mail,
      message: message,
    }).then((response) => {
      console.log(response)
    })
  }

  /*Envoie du devis par mail de je le fais moi-meme*/
  const apiEnvoieMail = () => {
    axios.post('https://api.jelefaismoimeme.be/mailClient.php', {
      mail: mail,
      typeInstallation: devis?.devis?.nom,
      numeroDevis: devisReponse?.secret,
      prixInstallation: devisReponse?.prix,
      tva: tva,
      titreMateriel: devisReponse?.titreMateriel,
      texteInstallation: devisReponse?.texteMateriel,
      prixConnecte: devisReponse?.prixConnecte ?? "",
      texteConnecte: devisReponse?.texteMateriel ?? "",
      prixDomotique: devisReponse?.prixDomotique ?? "",
      texteDomotique: devisReponse?.texteMateriel ?? "",
      prixAssistant: devisReponse?.prixAssistant ?? "",
      titreAssistant: devisReponse?.titreAssistance,
      contenueAssistant: devisReponse?.texteAssistance,
    }).then((response) => {
        if (response.data.includes("success")) {
          setMessageErreur("Votre mail a bien été envoyé")
          setSeverity("success")
          apiEnvoieMailAdmin()
          recupererInfoClient()
          reset()
        } else {
          setMessageErreur("Votre mail n'a pas été envoyé, veuillez réessayer.")
          setSeverity("error")
        }
        setOpen(true);
      }
    );
  }

  /*Envoie du mail pour recevoir le devis */
  const envoieMail = () => {
    if (valideEmail === false) {
      setSeverity("error")
      setMessageErreur("Votre email est incorrecte")
      setOpen(true);
    } else { //A finir
      reset()
      apiEnvoieMail()
    }
  }

  /*Envoie du numéro de téléphone au mail de je le fais moi-meme*/
  const apiEnvoieTel = () => {
    const message = 'Numéro du devis: ' + devisReponse?.secret;
    axios.post('https://api.jelefaismoimeme.be/mail.php', {
      mail: telephone,
      message: message,
    }).then((response) => {
        if (response.data.includes("success")) {
          setMessageErreur("Votre numéro de téléphone a bien été envoyé")
          setSeverity("success")
          recupererInfoClient()
          reset()
        } else {
          setMessageErreur("Votre numéro de téléphone n'a pas été envoyé, veuillez réessayer.")
          setSeverity("error")
        }
        setOpen(true);
      }
    );
  }

  const recupererInfoClient = () => {
    axios.post('https://api.jelefaismoimeme.be/recupererInfoClient.php', {
      mail: mail,
      telephone: telephone,
      numeroDevis: devisReponse?.secret,
    }).then((response) => {});
  }

  const supprimerCodePromo = () => {
    setCodePromoActif(false)
    setValeurCode('')
    ajoutPromoDevis(null)
  }

  /*ligne 67: dangerouslySetInnerHTML = innerHTML mais attention au attaques xss*/
  return (
    <div className="devisFrame devisTaillePage">
      <ScrollTop />
      <div className="prixDevis formulaire">
        <div className="titre1 margintop">Mon devis pour l'installation {installation(devis?.devis?.nom)}</div>
        <div className="gridDevis">
          <div className="recevoirDevis borderArrondi center padding">
            <div className="marginBottom titreEnvoieDevis textRose">Référence de votre devis</div>
            <div className="numeroDevis bloqueCopierColler">{devisReponse?.secret}</div>
            <div className="aligementContact">
              <div className="visible saveDevis">
                <TextField
                  helperText=""
                  id="demo-helper-text-aligned"
                  label="Adresse mail"
                  onInput={event => setMail(event.target.value)}
                  value={mail}
                  onChange={(e) => validationEmail(e)}
                />
                <div className="boutonFormulaire">
                  <Button variant="contained" onClick={() => {} }>Sauvegarder mon devis</Button>
                </div>
              </div>
              <div>
                <TextField
                  helperText=""
                  id="demo-helper-text-aligned"
                  label="Adresse mail"
                  onInput={event => setMail(event.target.value)}
                  value={mail}
                  onChange={(e) => validationEmail(e)}
                />
                <div className="boutonFormulaire">
                  <Button variant="contained" onClick={() => envoieMail()}>Envoyer mon devis par mail</Button>
                </div>
              </div>
              <div>
                <TextField
                  helperText=""
                  id="demo-helper-text-aligned"
                  label="Téléphone"
                  onChange={(e) => validatePhoneNumber(e)}
                  onInput={event => setTelephone(event.target.value)}
                  value={telephone}
                />
                <div className="boutonFormulaire">
                  <Button variant="contained" onClick={() => envoieTel()}>Être contacté par téléphone</Button>
                </div>
              </div>
              <div>
                <TextField
                  helperText=""
                  id="demo-helper-text-aligned"
                  label="Code promo"
                  onInput={event => setCodePromo(event.target.value)}
                  value={codePromo}
                />
                { codePromoActif === true ?
                  <div className="affichageCodePromo">
                    <div>{valeurCode}</div>
                    <div className="fermetureTuto">
                      <CloseIcon sx={{fontSize: 40}} onClick={() => supprimerCodePromo()}/>
                    </div>
                  </div>
                  : <div/>
                }
                <div className="boutonFormulaire">
                  <Button variant="contained" onClick={() => verificationCodePromo() }>Ajouter code promo</Button>
                </div>
              </div>
            </div>
          </div>
            {devisReponse === undefined ?
              <div className="chargement">
                <div className="lds-ring">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              </div>
              :
              <RecapitulatifDevis type={devis?.devis?.nom} donneeDevis={devisReponse} codePromoActif={codePromoActif}/>
            }
          </div>
        <div className="boutonFormulaire margintop">
          <Button variant="contained" onClick={() => precedent()}>Précédent</Button>
        </div>
      </div>
      <Stack spacing={2} sx={{width: '100%'}}>
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert className="alert" onClose={handleClose} severity={severity} sx={{width: '400px'}}>
            {messageErreur}
          </Alert>
        </Snackbar>
      </Stack>
    </div>
  );
}

export default PrixDevis;