import './TutoPiece.css'
import CloseIcon from "@mui/icons-material/Close";
import * as React from "react";

/*Affichage de la visio tuto sur la page configuration des pièces dans le type d'installation électricité*/
const TutoPiece = ({tuto, setTuto}) => {
  return(
    <div className={tuto === true ? "visible": "tutoPiece"}>
      <div className="fermetureTuto">
        <CloseIcon sx={{ fontSize: 40 }} onClick={() => setTuto(true)}/>
      </div>
      { tuto === true ?
        <div/>
        :
        <div>
          <div className="center titreDevis marginTop">Tutoriel: configurer les pièces de votre habitation</div>
          <div className="center margin">Ci-dessous vous pouvez configurer vos pièces en choisissant ou modifiant les éléments prédéfinies et conseillés
            pour chacune de vos pièces que vous avez choisis par niveau dans l’étape précédente.
          </div>
          <video className="video" autoPlay loop muted controls>
            <source src={"https://entrepot.jelefaismoimeme.be/tutoriel/tuto-configPiece.mp4"} type="video/mp4" />
          </video>
        </div>
      }
    </div>
  );
}

export default TutoPiece;