import './TypeHabitation.css'
import HexagoneAssistant from "../../HexagoneAssistant/HexagoneAssistant";
import {useContext, useEffect, useState} from "react";
import {DevisContext} from "../../../../../Context/DevisContext";

const TypeHabitation = ({onClickHexa}) => {
  const { devis, setDevis } = useContext(DevisContext);
  const [selectionneHabitation,setSelectionneHabitation] = useState(devis.habitation ?? null);
  const habitation = [
    {
      nom:"Appartement",
    },
    {
      nom:"Maison mitoyenne",
    },
    {
      nom:"Maison individuelle",
    },
  ];

  useEffect(() => {
    setDevis((oldDevis) => ({
      ...oldDevis,
      habitation: selectionneHabitation,
    }))
  }, [selectionneHabitation, setDevis]);


  return(
    <div className="contenuEtape fontAssisant">
      <div className="titreEtape texteInfoService center">Quel type d'habitation ?</div>
      <div className="hexagoneCentrer">
        <HexagoneAssistant
          ServicesItem={habitation}
          setSelectionHexagone={setSelectionneHabitation}
          selectionHexagone={selectionneHabitation}
          onClickHexa={onClickHexa}/>
      </div>
    </div>
  );
}

export default TypeHabitation;