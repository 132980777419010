import './PiedDePage.css'
import logo from "../../Images/logoJelefaismoimeme.png";
import facebook from "../../Images/facebook.png"
import instagram from "../../Images/instagram.png"
import InformationContact from "../InformationContact/InformationContact";
import React from 'react';
import {NavLink} from "react-router-dom";

const PiedDePage = () => {
  function getYear() {
    return new Date().getFullYear();
  }
  return (
    <footer className="piedDePage textBlanc">
      <img src={logo} alt="Logo" loading="eager" className="logo"/>
      <div className="elementPiedDePage">
        <InformationContact/>
        <div className="reseauxSociaux">
          <div className="textReseau">Suivez-nous !</div>
          <a href="https://www.facebook.com/easyservices.be" target="_blank">
            <img src={facebook} alt="logo facebook" loading="eager" className="logoRS"/>
            <div>EasyServices</div>
          </a>
          <a href="https://www.instagram.com/easy_services_elec_domotique/?hl=fr" target="_blank">
            <img src={instagram} alt="logo instagram" loading="eager" className="logoRS"/>
            <div>easy_services_elec_domotique</div>
          </a>
        </div>
        <div className="conditions">
          <NavLink to="/conditions_generale">Condition générales de ventes</NavLink>
          <div> |</div>
          <NavLink to="/mentions_legales">Mentions légales</NavLink>
          <div> |</div>
          <NavLink to="/cookies">Cookies</NavLink>
        </div>
        <div className="copyright">© SRL Easy Services électricité domotique... à faire soi-même ou par nos équipes - 2008 à {getYear()}</div>
      </div>
    </footer>
  );
}

export default PiedDePage;