import {Button, IconButton} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import HorizontalRuleIcon from "@mui/icons-material/HorizontalRule";
import * as React from "react";
import {useCallback} from "react";
import CorbeilleIcon from "../../../../../../Globale/Icons/corbeilleIcon";

/*Composant de la page configuration pièce du type d'installation éléctricité, c'est la ligne qui permet d'ajouter le nombre d'interrupteur souhaité*/
const Eclairage = ({nombrePrise,setListEclairage, id}) => {

  /*Modifie les nouvelles valeurs dans la lists des éclairages de la pièce selectionné*/
  const save = useCallback((interrupteurs) => {
    setListEclairage(value => {
      value[id].interrupteurs = value[id].interrupteurs + interrupteurs;
      return [...value];
    });
  }, [id, setListEclairage]);
  
  const retirePrise = () => {
    if(nombrePrise > 0){
      save(-1, 0);
    }
  }
  const ajoutePrise = () => {
    if(nombrePrise < 3){
      save(1, 0);
    }
  }

  const supprimerEclairage = () => {
    setListEclairage(value => {
      delete value[id];
      return value.filter(Boolean);
    });
  }

  const pluriel = () => {
    if (nombrePrise <= 1) return 'interrupteur';
    else {
      return 'interrupteurs';
    }
  }

  return(
    <div className="eclairage">
      <div className="nombreprise positionEclairageZone marginBottom centeEclairage">
        <div className="nombreprise styleBtnDevis">
          <Button variant="contained" onClick={retirePrise}><HorizontalRuleIcon sx={{ fontSize: 15 }}/></Button>
          <div className={nombrePrise === 0 ? "quantitePrise textGris" : "quantitePrise"}>{nombrePrise}</div>
          <Button variant="contained" onClick={ajoutePrise} ><AddIcon sx={{ fontSize: 15 }}/></Button>
        </div>
        <div className={nombrePrise === 0 ? "textePrise textGris" : "textePrise"}>{pluriel()} pour le circuit d'éclairage {id+1}</div>
        <div className="supprimerPiece">
          <IconButton onClick={supprimerEclairage}>
            <CorbeilleIcon/>
          </IconButton>
        </div>
      </div>
    </div>
  );
}

export default Eclairage;