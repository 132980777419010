import './BoutonAjouter.css'
import React, {useCallback, useContext} from "react";
import axios from "axios";
import {AdminContext} from "../../../../Context/AdminContext";

/*Bouton ajouter vidéo, promo ou actualité*/
const BoutonAjouter = ({composant}) => {
  const {
    actualite: { refresh: refreshActu },
    video: { refresh: refresh },
    promo: { refresh: refreshPromo },
    avis: { refresh: refreshAvis },
  } = useContext(AdminContext);

  /*Appelle API pour ajouter*/
  const ajouteElement = useCallback(() => {
    axios.post('https://api.jelefaismoimeme.be/ajouteActu.php',{
      composant: composant
    })
      .then((response) => {
        refreshActu();
        refresh();
        refreshPromo();
        refreshAvis();
      });
  }, [composant, refreshActu, refresh, refreshPromo, refreshAvis]);

  return(
    <div className="btnAjouter alignementCol" onClick={ajouteElement}>Ajouter</div>
  );
}

export default BoutonAjouter;