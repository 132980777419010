import React from 'react';
import Icon from './ConnexionIcon.svg';
import './ConnexionIcon.css';

function ConnexionIcon(props) {
  return (
    <img src={Icon} alt="" className="connexionIcon"/>
  );
}

export default ConnexionIcon;