import './NomExempleDevis.css'
import ScrollAnimation from 'react-animate-on-scroll';
import "animate.css/animate.min.css";
import React from 'react';

const NomExempleDevis = ({Nom, Banniere, Couleur}) =>{
  return(
    <h1 className="nomExempleDevis" style={{backgroundImage:`url(${Banniere})`}}>
      {<ScrollAnimation animateIn="animate__fadeIn" className="nom" style={{ background: `${Couleur}88`}}>
        {Nom}
      </ScrollAnimation>
      }
    </h1>
  );
}

export default NomExempleDevis;