import "./BoutonImporterImageActu.css"
import {Button, Snackbar} from "@mui/material";
import Stack from "@mui/material/Stack";
import React, {useRef, useState} from "react";
import MuiAlert from "@mui/material/Alert";

const BoutonImporterImageActu = ({image, setImage, onClick}) =>{
  const hiddenFileInput = useRef(null);
  const [open, setOpen] = React.useState(false);
  const [messageErreur] = useState('');
  const [severity] = useState("success");

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  /*Ouvre avec le click l'exporateur de fichier*/
  const handleClick = () => {
    hiddenFileInput.current.click();
  };

  /*Ouvrir le message d'erreur*/
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  /*Event pour changer l'image*/
  const ChangeImage = (event) => {
    const imageChange = event.target.files[0]; //recuperer le fichier selectionner dans l'explorateur de fichier
    setImage(imageChange)
  }
  return(
    <div>
      <div className="modifbtn">
        <Button className="boutonImporterImage" variant="contained" size="large" onClick={handleClick}>
          Importer image
        </Button>
      </div>
      <input
        type="file"
        ref={hiddenFileInput}
        onChange={ChangeImage}
        style={{display: 'none'}}
        accept="image/png, image/jpeg, image/jpg"
      />
      <Stack spacing={2} sx={{ width: '100%' }}>
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert className="alert" onClose={handleClose} severity={severity} sx={{ width: '400px'}}>
            {messageErreur}
          </Alert>
        </Snackbar>
      </Stack>
    </div>
  );
}

export default BoutonImporterImageActu;