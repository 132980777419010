import './Galerie.css'
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import React from 'react';
import useGalerie from "../../../../Hook/useGalerie";

const Galerie = ({dossier}) => {
  const { listImage } = useGalerie({dossier: dossier, composant: 'galerie'})
  const responsive = {
    BigDesktop:{
      breakpoint: { max: 6000, min: 3000 },
      items: 6,
      slidesToSlide: 1
    },
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 3000, min: 2500 },
      items: 5,
      slidesToSlide: 1
    },
    LargeDesktop:{
      breakpoint: { max: 2500, min: 2000 },
      items: 4,
      slidesToSlide: 1
    },
    desktop: {
      breakpoint: { max: 2000, min: 1024 },
      items: 3,
      slidesToSlide: 1
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 1
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1
    }
  };
  return (
    <div className="galerie" >
      <Carousel
        responsive={responsive}
        autoPlay={true}
        autoPlaySpeed={5000}
        infinite={true}
        className="carousel"
      >
        {listImage.map((item) => (
          <div
            key={'ListImage'}
          >
            <div><img src={"https://entrepot.jelefaismoimeme.be/galerie/"+dossier+"/"+item} loading="eager" alt="image galery" className="imageGalerie"/></div>
          </div>
        ))}
      </Carousel>
    </div>
  );
}

export default Galerie;