import './ElementPiece.css'
import Tooltip from "@mui/material/Tooltip";
import {IconButton} from "@mui/material";
import CorbeilleIcon from "../../../../../../Globale/Icons/corbeilleIcon";
import NombrePrise from "../../Configuration/NombrePrise/NombrePrise";
import {useContext} from "react";
import {DevisContext} from "../../../../../../Context/DevisContext";

const ElementPiece = ({setNewListePiece,newListePiece}) => {
  const devis = useContext(DevisContext);

  const supprimerPiece = (piece) => { //nombre a voir
    setNewListePiece(value => value.filter(niveau => niveau !== piece));
  }

  const piecePluriel = (item) => {
    if ((typeof item.nombre === 'number') ? item.nombre <= 1 : item.nombre[devis?.devis?.habitation?.nom] <= 1){
      return item.piece;
    }
    const pluriel = {
      "Bureau": "Bureaux",
      "Local technique": "Locaux techniques",
      "WC": "WC",
      "Salle de bain": "Salles de bain",
      "Salle de douche": "Salles de douche",
      "Salle à manger": "Salles à manger",
      "Hall d'entrée": "Halls d'entrée",
      "Hall de nuit": "Halls de nuit",
      "Hall de cave": "Halls de cave",
      "jardin avant": "jardins avant",
      "jardin arrière": "jardins arrière",
      "Salle de jeux": "Salles de jeux",
    }
    if (pluriel[item.piece]) {
      return pluriel[item.piece];
    } else {
      return `${item.piece}s`;
    }
  }

  return(
    <div className="pieceList">
      {newListePiece.map((item , idItem) => (
        <div className="pieceNombre" key={'Niveau' + idItem}>
          <div className="piecebox">
            <NombrePrise nombrePrise={(typeof item.nombre === 'number') ? item.nombre : item.nombre[devis?.devis?.habitation?.nom]} texte={piecePluriel(item)} id={idItem} setListPrise={setNewListePiece} />
          </div>
          <div className="supprimerPiece">
            <Tooltip title="Supprimer">
              <IconButton onClick={() => supprimerPiece(item)}>
                <CorbeilleIcon/>
              </IconButton>
            </Tooltip>
          </div>
        </div>
      ))}
    </div>
  );
}

export default ElementPiece;