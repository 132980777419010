import React from 'react';
import { SvgIcon } from "@mui/material";

function PVIcon(props) {
  return (
    <SvgIcon {...props} viewBox="0 0 523.25 383.93">
      <polygon points="158.98 162.99 171.57 178.31 15.66 365.54 0 352.61 158.98 162.99"/>
      <rect x="128.68" y="302.22" width="49.02" height="36.77" rx="2.38"/>
      <rect x="129.02" y="347.16" width="49.02" height="36.77" rx="2.38"/>
      <rect x="186.04" y="347.16" width="49.02" height="36.77" rx="2.38"/>
      <rect x="186.04" y="302.22" width="49.02" height="36.77" rx="2.38"/>
      <polygon points="252.6 251.84 312.51 237.54 236.94 144.61 171.57 151.07 252.6 251.84"/>
      <polygon points="262.47 264.27 320.68 248.8 395.92 341.03 345.19 365.54 262.47 264.27"/>
      <polygon points="253.62 142.56 312.51 137.8 379.57 220.52 329.19 233.46 253.62 142.56"/>
      <polygon points="336.34 244.69 387.06 231.75 454.47 314.82 410.21 335.58 336.34 244.69"/>
      <polygon points="323.92 136.95 367.32 133.71 428.25 207.75 390.98 217.97 323.92 136.95"/>
      <polygon points="397.96 228.18 435.92 217.97 497.87 293.71 465.36 309.2 397.96 228.18"/>
      <path d="M413.79,171.67,372.6,121.12l-30.47,2.72c.94-22.47,13.92-42.32,33.5-51.12,2.08-.94,25.95-11.18,49.56,2.07,17.29,9.71,31.57,30.4,27.92,54.16C450,149.38,434.45,166.24,413.79,171.67Z"/>
      <path d="M309.56,35.92l39,20.51h0c.73.32,11.83,5.28,11.54,12.25a6.77,6.77,0,0,1-.46,2.16.31.31,0,0,1-.05.1,49.16,49.16,0,0,1-4.06,5.23,48.31,48.31,0,0,1-9.19,8,5.74,5.74,0,0,1-1.59.62c-7.1,1.57-15-11.45-15.77-12.7l-20-35.66A.38.38,0,0,1,309.56,35.92Z"/>
      <path d="M399,.28l12,42.43v0c.27.75,4.3,12.22-1,16.8a6.6,6.6,0,0,1-1.89,1.15.17.17,0,0,1-.1,0,46.44,46.44,0,0,1-6.59.66,48.73,48.73,0,0,1-12.13-1.17,5.63,5.63,0,0,1-1.54-.73c-6-4.07-2-18.77-1.63-20.18q6.07-19.51,12.14-39A.38.38,0,0,1,399,.28Z"/>
      <path d="M486.94,36l-22,38.2v0c-.34.72-5.74,11.62-12.68,11.06a6.89,6.89,0,0,1-2.15-.55l-.09,0A48.12,48.12,0,0,1,437.29,71a5.39,5.39,0,0,1-.56-1.62c-1.29-7.15,12-14.56,13.31-15.26l36.4-18.57A.38.38,0,0,1,486.94,36Z"/>
      <path d="M523,121.8,480.61,134h0c-.74.27-12.19,4.37-16.8-.86a6.86,6.86,0,0,1-1.17-1.89l0-.09a49.29,49.29,0,0,1-.69-6.59,48.68,48.68,0,0,1,1.1-12.14,5.53,5.53,0,0,1,.72-1.55c4-6,18.76-2.13,20.17-1.74L523,121.07A.38.38,0,0,1,523,121.8Z"/>
      <path d="M485.92,205.94l-39-20.54h0c-.73-.32-11.82-5.29-11.53-12.26a6.77,6.77,0,0,1,.46-2.16l.05-.1a48.18,48.18,0,0,1,13.26-13.22,6.13,6.13,0,0,1,1.6-.62c7.09-1.56,15,11.46,15.75,12.72q10,17.83,19.94,35.67A.37.37,0,0,1,485.92,205.94Z"/>
      <path d="M273.27,121.31l47.05-13.75h0c1.48-.28,8.23-1.35,12.58,2.83a10.18,10.18,0,0,1,1.57,2,.37.37,0,0,1,.06.21V124a.38.38,0,0,1-.34.37l-39.35,3.54h-.14L273.27,122A.37.37,0,0,1,273.27,121.31Z"/>
    </SvgIcon>
  );
}

export default PVIcon;