import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import {Button, IconButton, MenuItem, Select} from "@mui/material";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import FormControl from "@mui/material/FormControl";
import TableauDetails from "../TableauDetails/TableauDetails";
import * as React from "react";
import {Fragment, useEffect, useState} from "react";
import axios from "axios";

const LigneTableauDevis = ({ligne, id, setDonneesDevis, donneesDevis, listStatus, setListStatus}) => {
  const [status, setStatus] = useState(ligne.status);
  const [open, setOpen] = React.useState(false);
  const [choixPrix, setChoixPrix] = useState(1);
  const handleChange = (event) => {
    setStatus(event.target.value);
  };

  useEffect(() => {
    setDonneesDevis((oldDevis) => {
      oldDevis[id].IDstatus_devis = status;
      return oldDevis;
    })
  }, [status, id, donneesDevis]);

  useEffect(() => {
    //console.log(donneesDevis[id].NuméroInterneDevis + "  "+status.toString())
    //console.log(status)
    axios.post('https://api.jelefaismoimeme.be/modificationStatusDevis.php', {
      iddevis: donneesDevis[id].NuméroInterneDevis,
      idstatus: status,
    }).then((response) => {})
  },[status])

  return (
    <Fragment>
      <TableRow
        key={ligne.numeroDevis}
        sx={{'&:last-child td, &:last-child th': {border: 0}}}
      >
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
          </IconButton>
        </TableCell>
        <TableCell align="center" component="th" scope="row">{ligne.date}</TableCell>
        <TableCell align="center">
          <Button variant="contained" style={{background: "#0a90c9", width: "200px"}} value={ligne.numeroDevis}
                  onClick={() => window.open(`/devis?id=${ligne.numeroDevis}`, '_blank')}>{ligne.numeroDevis}</Button>
        </TableCell>
        <TableCell align="center">{ligne.typeInstallation}</TableCell>
        <TableCell align="center">
          <FormControl sx={{m: 1, minWidth: 120}}>
            <Select
              value={status}
              defaultValue={1}
              onChange={handleChange}
              displayEmpty
            >
              {listStatus.map((item, idItem) => (
                <MenuItem key={idItem} value={item?.IDstatus_devis}>{item.Nom}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </TableCell>
        <TableCell align="center">
          <Button variant="contained" style={choixPrix === 1 ? {background: "#1059a1"} : {background: "#0a90c9"}}
                  onClick={() => setChoixPrix(1)}>{isNaN(ligne?.prix) ? 0 : ligne?.prix}€</Button>
        </TableCell>
        <TableCell align="center">
          <Button variant="contained" style={choixPrix === 2 ? {background: "#1059a1"} : {background: "#0a90c9"}}
                  onClick={() => setChoixPrix(2)}>{isNaN(ligne?.connecte) ? 0 : ligne?.connecte}€</Button>
        </TableCell>
        <TableCell align="center">
          <Button variant="contained" style={choixPrix === 3 ? {background: "#1059a1"} : {background: "#0a90c9"}}
                  onClick={() => setChoixPrix(3)}>{isNaN(ligne?.domotique) ? 0 : ligne?.domotique}€</Button>
        </TableCell>
        <TableCell align="center">{isNaN(ligne?.assistant) ? 0 : ligne?.assistant}€</TableCell>
        <TableCell align="center">{ligne?.mail}<br/>{ligne?.telephone}</TableCell>
      </TableRow>
      { ligne.typeInstallation === "Electricité" ?
          <TableauDetails openCollapse={open} donnees={donneesDevis[id]} valuePrix={choixPrix}/>
        :<div/>
      }
    </Fragment>
  );
}


export default LigneTableauDevis;