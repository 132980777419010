import './Promo.css'
import CloseIcon from "@mui/icons-material/Close";
import * as React from "react";
import {useEffect, useState} from "react";
import axios from "axios";
import Carousel from "react-multi-carousel";

const Promo = () => {
  const [messagePromo, setMessagePromo] = useState(true);
  const [promo, setPromo] = useState([]);

  /*Appel API pour récuperer les bonne actu */
  useEffect(() => {
    axios.post('https://api.jelefaismoimeme.be/recupererPromo.php')
      .then((response) => {
        setPromo(response.data ?? [])
      });
  },[setPromo])

  const symboleMontant = (item) => {
    const symbole = {
      "euro" : "€",
      "pourcentage" : "%",
    }
    return symbole[item];
  }

  const date = (item) => {
    var options = {year: 'numeric', month: 'long', day: 'numeric' };
    var today  = new Date(item);
    var weekday = toTitleCase(today.toLocaleDateString("fr-FR"));
    function toTitleCase(str) {
      return str.replace(
        /\w\S*/g,
        function(txt) {
          return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        }
      );
    }

    return today.toLocaleDateString("fr-FR", options)
  }

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 5000, min: 3000 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return(
    messagePromo === true && promo.length !== 0 ? (
    <div className="promo">
      <div className="fermetureTuto">
        <CloseIcon sx={{fontSize: 40}} onClick={() => setMessagePromo(false)}/>
      </div>
      <div className="titrePromo">Promotions</div>
      <Carousel
        responsive={responsive}
        autoPlay={true}
        autoPlaySpeed={5000}
        infinite={true}
        arrows={false}
        className="carousel"
      >
        {promo.map((item , idItem) => (
          <div className="textePromo" key={idItem}>
            <div>
              <div><span className="codePromo">{item.montant}{symboleMontant(item.typeMontant)}</span> sur votre
                installation électrique avec le code <span className="codePromo">{item.code}</span></div>
            </div>
            <div>
              <div>{item.condition}</div>
              {item.dateDebut !== null ?
                <div>du {date(item.dateDebut)} au {date(item.dateFin)}</div>
                :<div/>}
            </div>
          </div>
        ))}
      </Carousel>
    </div>
    ):<div/>

  );
}

export default Promo;