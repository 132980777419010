import './Cookies.css'
import ScrollTop from "../../Globale/Composants/ScrollTop/ScrollTop";
import Menu from "../../Globale/Composants/Menu/Menu";
import React, {useEffect, useState} from "react";
import PiedDePage from "../../Globale/Composants/PiedDePage/PiedDePage";
import axios from "axios";

const Cookies = ({element}) => {
  const [texteCookie, setTexteCookie] = useState();

  useEffect(() => {
    axios.post('https://api.jelefaismoimeme.be/texteCookies.php',{
      element: element,
    }).then((response) => {
        setTexteCookie(response.data);
      });
  },[element]);

  return(
    <div>
      <ScrollTop />
      <header>
        <Menu/>
      </header>
      <div className="corps marginTop">
        <div className="taillePage" dangerouslySetInnerHTML={{__html: texteCookie}}></div>
      </div>
      <footer>
        <PiedDePage />
      </footer>
    </div>
  );
}

export default Cookies;