import './Admin.css'
import React, {useEffect, useState} from "react";
import EntrepotElement from "./Composants/EntrepotElements/EntrepotElement";
import ListeElement from "./Composants/ListeElements/ListeElements";
import ZoneModification from "./Composants/ZoneModification/ZoneModification";
import {AdminProvider} from "../../Context/AdminContext";

const Admin = () =>{
  const [selectionne, setSelectionne] = useState(null);
  const [selectionneModif, setSelectionneModif] = useState(null);

  useEffect(() => {
    setSelectionneModif(null);
  }, [selectionne]);

  return(
    <div className="admin">
      <AdminProvider>
        <div className="listComposant">
         <EntrepotElement ElementSelectionne={selectionne} setElementSelectionne={setSelectionne}/>
        </div>
        {![null, "Partenaire", "Devis", "Pop-up"].includes(selectionne) ?(
          <div className="listElement">
            <ListeElement ElementSelectionne={selectionne} ZoneModifSelectionne={selectionneModif} setZoneModifSelectionne={setSelectionneModif}/>
          </div>
        ) : <div/>}
        <div className="zoneDeModif">
          <ZoneModification ElementSelectionne={selectionne} ZoneModifSelectionne={selectionneModif} setZoneModifSelectionne={setSelectionneModif}/>
        </div>
      </AdminProvider>
    </div>
  );
}

export default Admin;