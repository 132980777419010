
export const ListDetailsElectriciteGeneraux = (json_site) => [
  {
    nom: "Boite d'encastrement",
    valeur: json_site[0]?.devis?.calcul?.boiteEncastrement,
    prixAchat: json_site[0]?.devis?.prixAchat?.boiteEncastrement,
    prixVente: json_site[0]?.devis?.prixVente?.boiteEncastrement,
  },
  {
    nom: "Boite d'encastrement semi étanche",
    valeur: json_site[0]?.devis?.calcul?.boiteSemiEtanche,
    prixAchat: json_site[0]?.devis?.prixAchat?.boiteEncastrementsemiEtanche,
    prixVente: json_site[0]?.devis?.prixVente?.boiteEncastrementsemiEtanche,
  },
  {
    nom: "Cable 3G2.5",
    valeur: json_site[0]?.devis?.calcul?.cable3G25 + ' m',
    prixAchat: json_site[0]?.devis?.prixAchat?.metreCable3G25,
    prixVente: json_site[0]?.devis?.prixVente?.metreCable3G25,
  },
  {
    nom: "Cable 5G6",
    valeur: json_site[0]?.devis?.calcul?.cable5G6 + ' m',
    prixAchat: json_site[0]?.devis?.prixAchat?.metreCable5G6,
    prixVente: json_site[0]?.devis?.prixVente?.metreCable5G6,
  },
  {
    nom: "Cable 5G1.5",
    valeur: json_site[0]?.devis?.calcul?.cable5G15 + ' m',
    prixAchat: json_site[0]?.devis?.prixAchat?.metreCable5G15,
    prixVente: json_site[0]?.devis?.prixVente?.metreCable5G15,
  },
  {
    nom: "Cable réseau",
    valeur: json_site[0]?.devis?.calcul?.cableReseau + ' m',
    prixAchat: json_site[0]?.devis?.prixAchat?.metreCableUTP,
    prixVente: json_site[0]?.devis?.prixVente?.metreCableUTP,
  },
  {
    nom: "Cable TV",
    valeur: json_site[0]?.devis?.calcul?.cableTV + ' m',
    prixAchat: json_site[0]?.devis?.prixAchat?.metreCableTv,
    prixVente: json_site[0]?.devis?.prixVente?.metreCableTv,
  },
  {
    nom: "Circuit avec 1 interrupteur",
    valeur: json_site[0]?.devis?.calcul?.circuit1Interrupteur,
    prixAchat: json_site[0]?.devis?.prixAchat?.circuit1Interrupteur,
    prixVente: json_site[0]?.devis?.prixVente?.circuit1Interrupteur,
  },
  {
    nom: "Circuit avec 2 interrupteurs",
    valeur: json_site[0]?.devis?.calcul?.circuit2Interrupteur,
    prixAchat: json_site[0]?.devis?.prixAchat?.circuit2Interrupteur,
    prixVente: json_site[0]?.devis?.prixVente?.circuit2Interrupteur,
  },
  {
    nom: "Circuit avec 3 interrupteurs",
    valeur: json_site[0]?.devis?.calcul?.circuit3Interrupteur,
    prixAchat: json_site[0]?.devis?.prixAchat?.circuit3Interrupteur,
    prixVente: json_site[0]?.devis?.prixVente?.circuit3Interrupteur,
  },
  {
    nom: "Total des circuits d'éclairage",
    valeur: json_site[0]?.devis?.calcul?.totalCircuitEclairage,
    prixAchat: '/',
    prixVente: '/',
  },
  {
    nom: "Disjoncteur",
    valeur: json_site[0]?.devis?.calcul?.disjoncteurs,
    prixAchat: json_site[0]?.devis?.prixAchat?.disjoncteur2P,
    prixVente: json_site[0]?.devis?.prixVente?.disjoncteur2P,
  },
  {
    nom: "Disjoncteur 4P",
    valeur: json_site[0]?.devis?.calcul?.disjoncteurs4P,
    prixAchat: json_site[0]?.devis?.prixAchat?.kit4P,
    prixVente: json_site[0]?.devis?.prixVente?.kit4P,
  },
  {
    nom: "Nombre de module dans le coffret",
    valeur: json_site[0]?.devis?.calcul?.NombreModuleCoffret,
    prixAchat: '/',
    prixVente: '/',
  },
  {
    nom: "Coffret 1 rangée",
    valeur: json_site[0]?.devis?.calcul?.R1,
    prixAchat: json_site[0]?.devis?.prixAchat?.coffret1R,
    prixVente: json_site[0]?.devis?.prixVente?.coffret1R,
  },
  {
    nom: "Coffret 2 rangée",
    valeur: json_site[0]?.devis?.calcul?.R2,
    prixAchat: json_site[0]?.devis?.prixAchat?.coffret2R,
    prixVente: json_site[0]?.devis?.prixVente?.coffret2R,
  },
  {
    nom: "Coffret 3 rangée",
    valeur: json_site[0]?.devis?.calcul?.R3,
    prixAchat: json_site[0]?.devis?.prixAchat?.coffret3R,
    prixVente: json_site[0]?.devis?.prixVente?.coffret3R,
  },
  {
    nom: "Coffret 4 rangée",
    valeur: json_site[0]?.devis?.calcul?.R4,
    prixAchat: json_site[0]?.devis?.prixAchat?.coffret4R,
    prixVente: json_site[0]?.devis?.prixVente?.coffret4R,
  },
  {
    nom: "Prise internet",
    valeur: json_site[0]?.devis?.calcul?.internetNormal +' | semi-etanche: '+ json_site[0]?.devis?.calcul?.internetSemiEtanche,
    prixAchat: json_site[0]?.devis?.prixAchat?.priseInternetNormal +' | semi-etanche: '+json_site[0]?.devis?.prixAchat?.priseInternetSemiEtanche,
    prixVente: json_site[0]?.devis?.prixVente?.priseInternetNormal +' | semi-etanche: '+json_site[0]?.devis?.prixVente?.priseInternetSemiEtanche,
  },
  {
    nom: "Prise TV",
    valeur: json_site[0]?.devis?.calcul?.tv,
    prixAchat: json_site[0]?.devis?.prixAchat?.priseTVNormal,
    prixVente: json_site[0]?.devis?.prixVente?.priseTVNormal,
  },
  {
    nom: "Prise simple",
    valeur: json_site[0]?.devis?.calcul?.priseSimpleNormal +' | semi-etanche: '+ json_site[0]?.devis?.calcul?.priseSimpleSemiEtanche,
    prixAchat: json_site[0]?.devis?.prixAchat?.priseSimpleNormal +' | semi-etanche: '+json_site[0]?.devis?.prixAchat?.priseSimpleSemiEtanche,
    prixVente: json_site[0]?.devis?.prixVente?.priseSimpleNormal +' | semi-etanche: '+json_site[0]?.devis?.prixVente?.priseSimpleSemiEtanche,
  },
  {
    nom: "Prise double",
    valeur: json_site[0]?.devis?.calcul?.priseDoubleNormal +' | semi-etanche: '+ json_site[0]?.devis?.calcul?.priseDoubleSemiEtanche,
    prixAchat: json_site[0]?.devis?.prixAchat?.priseDoubleNormal +' | semi-etanche: '+json_site[0]?.devis?.prixAchat?.priseDoubleSemiEtanche,
    prixVente: json_site[0]?.devis?.prixVente?.priseDoubleNormal +' | semi-etanche: '+json_site[0]?.devis?.prixVente?.priseDoubleSemiEtanche,
  },
  {
    nom: "Prise triple",
    valeur: json_site[0]?.devis?.calcul?.priseTripleNormal +' | semi-etanche: '+ json_site[0]?.devis?.calcul?.priseTripleSemiEtanche,
    prixAchat: json_site[0]?.devis?.prixAchat?.priseTripleNormal +' | semi-etanche: '+json_site[0]?.devis?.prixAchat?.priseTripleSemiEtanche,
    prixVente: json_site[0]?.devis?.prixVente?.priseTripleNormal +' | semi-etanche: '+json_site[0]?.devis?.prixVente?.priseTripleSemiEtanche,
  },
  {
    nom: "Prise quadruple",
    valeur: json_site[0]?.devis?.calcul?.priseQuadrupleNormal +' | semi-etanche: '+ json_site[0]?.devis?.calcul?.priseQuadrupleSemiEtanche,
    prixAchat: json_site[0]?.devis?.prixAchat?.priseQuadrupleNormal +' | semi-etanche: '+json_site[0]?.devis?.prixAchat?.priseQuadrupleSemiEtanche,
    prixVente: json_site[0]?.devis?.prixVente?.priseQuadrupleNormal +' | semi-etanche: '+json_site[0]?.devis?.prixVente?.priseQuadrupleSemiEtanche,
  },
  {
    nom: "Prise seule",
    valeur: json_site[0]?.devis?.calcul?.priseSpecialeNormal +' | semi-etanche: '+ json_site[0]?.devis?.calcul?.priseSpecialeSemiEtanche,
    prixAchat: json_site[0]?.devis?.prixAchat?.priseSpecialesNormal +' | semi-etanche: '+json_site[0]?.devis?.prixAchat?.priseSpecialesSemiEtanche,
    prixVente: json_site[0]?.devis?.prixVente?.priseSpecialesNormal +' | semi-etanche: '+json_site[0]?.devis?.prixVente?.priseSpecialesSemiEtanche,
  },
  {
    nom: "Prise taque",
    valeur: json_site[0]?.devis?.calcul?.priseTaque,
    prixAchat: json_site[0]?.devis?.prixAchat?.priseTaque,
    prixVente: json_site[0]?.devis?.prixVente?.priseTaque,
  },
  {
    nom: "Prise USB",
    valeur: json_site[0]?.devis?.calcul?.priseUSBNormal +' | semi-etanche: '+ json_site[0]?.devis?.calcul?.priseUSBSemiEtanche,
    prixAchat: json_site[0]?.devis?.prixAchat?.priseUSBNormal +' | semi-etanche: '+json_site[0]?.devis?.prixAchat?.priseUSBSemiEtanche,
    prixVente: json_site[0]?.devis?.prixVente?.priseUSBNormal +' | semi-etanche: '+json_site[0]?.devis?.prixVente?.priseUSBSemiEtanche,
  },
  {
    nom: "Total des prises",
    valeur: json_site[0]?.devis?.calcul?.totalPrises,
    prixAchat: '/',
    prixVente: '/',
  },
  {
    nom: "Prix services",
    valeur: '/',
    prixAchat: json_site[0]?.prixAssistant,
    prixVente: json_site[0]?.prixAssistantHorsTVA,
  },

];

export const ListDetailsClassique = (json_site) => [
  {
    nom: "Cable 3G1.5",
    valeur: json_site[0]?.devis?.calcul?.cable3G15 + ' m',
    prixAchat: json_site[0]?.devis?.prixAchat?.metreCable3G15,
    prixVente: json_site[0]?.devis?.prixVente?.metreCable3G15,
  },
];

export const ListDetailsDomotique = (json_site) => [
  {
    nom: "Cable bus",
    valeur: json_site[0]?.devis?.calcul?.cableBus + ' m',
    prixAchat: json_site[0]?.devis?.prixAchat?.cableBus,
    prixVente: json_site[0]?.devis?.prixVente?.cableBus,
  },
  {
    nom: "Cable 3G1.5 pour domotique",
    valeur: json_site[0]?.devis?.calcul?.cable3G15Domotique + ' m',
    prixAchat: '/',
    prixVente: '/',
  },
];

export const ListDetailsConnecte = (json_site) => [

];

