import './DonneesClients.css'
import ScrollAnimation from 'react-animate-on-scroll';
import "animate.css/animate.min.css";
import React, {useEffect, useState} from 'react';
import axios from "axios";

const DonneesClients = () => {
  const [nombreAvis, setNombreAvis] = useState();
  const [clientSatisfait, setClientSatisfait] = useState([]);

  useEffect(() => {
    axios.post('https://api.jelefaismoimeme.be/afficheAvis.php')
      .then((response) => {
        setNombreAvis(response.data.length ?? 0)
        setClientSatisfait(response.data ?? [])
      });
  },[])

  const pourcentageClientSatisfait = () => {
    return clientSatisfait.reduce((moyenne, item) => {
      return moyenne + parseInt(item.note) / (nombreAvis * 5) * 100
    }, 0);
  }

  return (
    <ScrollAnimation animateIn='animate__fadeIn' className="donneesClients">
      <div>
        <div className="gras">{nombreAvis}</div>
        <div>Nombres d'avis</div>
      </div>
      <div>
        <div className="gras">{pourcentageClientSatisfait()}%</div>
        <div>Clients satisfaits</div>
      </div>
    </ScrollAnimation>
  );

}

export default DonneesClients;