import './ExplicationService.css'
import React from 'react';

const ExplicationService = ({description}) => {
  return(
    <div className="explicationService">
      {description}
   </div>
  );
}

export default ExplicationService;