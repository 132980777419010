import './App.css';
import Accueil from "../../../Pages/Accueil/Accueil";
import {
  BrowserRouter,
  Route,
  Routes,
  Navigate,
  useLocation,
} from "react-router-dom";
import Contact from "../../../Pages/Contact/Contact";
import ExempleDevis from "../../../Pages/ExempleDevis/ExempleDevis";
import {Electricite} from "../../../Pages/ExempleDevis/Electricite";
import {Alarme} from "../../../Pages/ExempleDevis/Alarme";
import {Borne} from "../../../Pages/ExempleDevis/Borne";
import {Camera} from "../../../Pages/ExempleDevis/Camera";
import React from 'react';
import Devis from "../../../Pages/Devis/Devis";
import Admin from "../../../Pages/Admin/Admin";
import Connexion from "../../../Pages/Connexion/Connexion";
import {AuthContext, AuthProvider} from "../../../Context/AuthContext";
import {Panneaux} from "../../../Pages/ExempleDevis/Panneaux";
import Cookies from "../../../Pages/Cookies/Cookies";
import Showroom from "../../../Pages/Showroom/Showroom";
import {PopupProvider} from "../../../Context/PopupContext";

const App = () => {
  return (
    <AuthProvider>
      <PopupProvider>
        <BrowserRouter>
          <Routes>
            <Route exact path="/" element={<Accueil/>}/>
            <Route path="/contact" element={<Contact/>}/>
            <Route path="/electricite" element={<ExempleDevis Data={Electricite}/>}/>
            <Route path="/borne" element={<ExempleDevis Data={Borne}/>}/>
            <Route path="/panneaux" element={<ExempleDevis Data={Panneaux}/>}/>
            <Route path="/camera" element={<ExempleDevis Data={Camera}/>}/>
            <Route path="/alarme" element={<ExempleDevis Data={Alarme}/>}/>
            <Route path="/devis" element={<Devis/>}/>
            <Route path="/admin"
                   element={
                     <RequireAuth>
                       <Admin/>
                     </RequireAuth>
                   }
            />
            <Route path="/connexion" element={<Connexion/>}/>
            <Route path={"/showroom"} element={<Showroom/>}/>
            <Route path={"/cookies"} element={<Cookies element={1}/>}/>
            <Route path={"/mentions_legales"} element={<Cookies element={2}/>}/>
            <Route path={"/conditions_generale"} element={<Cookies element={3}/>}/>
          </Routes>
        </BrowserRouter>
      </PopupProvider>
    </AuthProvider>
  );
}
export default App;

export function useAuth() {
  return React.useContext(AuthContext);
}

function RequireAuth({children}) {
  let auth = useAuth();
  let location = useLocation();

  if (!auth.isConnected) {
    return <Navigate to="/connexion" state={{from: location}} replace/>;
  }

  return children;
}



