import './EnvoieMail.css'
import {useState} from "react";
import React from 'react';
import validator from 'validator';
import MuiAlert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import {Snackbar} from "@mui/material";

const EnvoieMail = () => {
  const [emailErreur] = useState('');
  const [selected, setSelected] = useState(false);
  const [mail, setMail] = useState('');
  const [message, setMessage] = useState('');
  const axios = require('axios');
  const [open, setOpen] = React.useState(false);
  const [messageErreur, setMessageErreur] = useState('');
  const [severity, setSeverity] = useState("success");

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const validationEmail = (e) => {
    var email = e.target.value
    if (validator.isEmail(email)){
      setSelected(true)
    }
    else{
      //setEmailErreur('Entrer un email valide !')
      setSelected(false)
    }
  }

  const envoieMail = (e) => {
    setMessage(message.replace(/<[^>]+>/g, '')); //a teste
    if(selected === true){
      axios.post('https://api.jelefaismoimeme.be/mail.php', {
        message: message,
        mail: mail,
      }).then((response) => {
        if(response.data.includes("success")){
            setMessageErreur("Votre mail est bien envoyé")
            setSeverity("success")
            setMail('')
            setMessage('')
            setSelected(false)
          }
          else{
            setMessageErreur("Votre mail n'a pas été envoyer, veiller nous contacter autrement")
            setSeverity("error")
          }
          setOpen(true);
      }
    );
    }
    else{
      setSeverity("error")
      setMessageErreur('Votre mail est incorrect')
      setOpen(true);

    }
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  return(
    <div className="envoieMail">
      <div className="containerEnvoieMail">
        <div className="titreBoxMail">Votre email</div>
        <input type="text" onChange={(e) => validationEmail(e)} className="font borderArrondi boxMail" onInput={event => setMail( event.target.value)} value={mail}/>
        <div className= {selected === true ? "valide boxPlacement" : "invalide boxPlacement"}>{emailErreur}</div>
        <div className="titreBoxMail">Votre message</div>
        <textarea type="text" className="font borderArrondi boxMessage boxPlacement" onChange={event => setMessage(event.target.value.replace(/<[^>]+>/g, ''))} value={message} />
      </div>
      <Stack spacing={2} sx={{ width: '100%' }}>
        <div className="boxCentre">
          <button className="boutonEnvoyer" alt="bouton envoyer mail" loading="lazy" onClick={() => envoieMail()}>Envoyer</button>
        </div>
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert className="alert" onClose={handleClose} severity={severity} sx={{ width: '400px'}}>
            {messageErreur}
          </Alert>
        </Snackbar>
      </Stack>
    </div>

  );

}

export default EnvoieMail;