import {useCallback, useEffect, useState} from "react";
import axios from "axios";

const usePopup = () => {
  const [popup, setPopup] = useState([]);

  const refresh = useCallback(() => {
    axios.post('https://api.jelefaismoimeme.be/popup.php')
      .then((response) => {
        setPopup(response.data ?? []);
      });
  }, []);

  useEffect(refresh,[refresh]);
  return { popup, refresh };
}

export default usePopup;