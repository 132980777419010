import {Button} from "@mui/material";
import React, {useRef} from "react";

const BoutonChangerVideo = ({changevideo, setChangeVideo, onClick}) => {
  const hiddenFileInput = useRef(null);

  /*Ouvre avec le click l'exporateur de fichier*/
  const handleClick = () => {
    hiddenFileInput.current.click();
  };

  /*Event pour changer la vidéo*/
  const changeVideo = (event) => {
    const videoChange = event.target.files[0]; //recuperer le fichier selectionner dans l'explorateur de fichier
    setChangeVideo(videoChange)
  }

  return(
    <div>
      <div className="modifbtn">
        <Button variant="contained" size="large" onClick={handleClick}>Changer la vidéo</Button>
      </div>
      <input
        type="file"
        ref={hiddenFileInput}
        onChange={changeVideo}
        style={{display: 'none'}}
        accept="video/mp4,video/x-m4v,video/*"
      />
    </div>
  );
}

export default BoutonChangerVideo;