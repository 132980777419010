import './ModifPromo.css'
import BoutonSauvegarderPromo from "../Sauvegarder/BoutonSauvegarderPromo";
import React, {useContext, useEffect, useState} from "react";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import DatePicker from "../DatePicker/DatePicker";
import {AdminContext} from "../../../../Context/AdminContext";
import moment from "moment";

const ModifPromo = ({Promo}) => {
  const { promo: { refresh: refreshPromo} } = useContext(AdminContext);
  const [date, setDate] = useState([]);
  const [condition, setCondition] = useState('');
  const [montant, setMontant] = useState();
  const [code, setCode] = useState('code promo');
  const [categorie, setCategorie] = useState('');
  const [typePromo, setTypePromo] = useState('');
  const [typeMontant, setTypeMontant] = useState('euro');
  const [id, setId] = useState('');
  const [setEtatSauvegarde] = useState(false);

  const handleChangeTypePromo = (event) => {
    setTypePromo(event.target.value);
  };
  const handleChangeCategorie = (event) => {
    setCategorie(event.target.value);
  };
  const handleChangeTypeMontant = (event) => {
    setTypeMontant(event.target.value);
  };

  useEffect(() => {
    if (Promo?.id) {
      setId(Promo.id)
      setCondition(Promo.condition)
      setMontant(Promo.montant)
      setCode(Promo.code)
      setCategorie(Promo.categorie)
      setTypePromo(Promo.typePromo)
      setTypeMontant(Promo.typeMontant)
      setDate([moment(Promo.dateDebut), moment(Promo.dateFin)])
    }
  }, [Promo])

  if (!Promo) return null;
  return(
    <div className="ModifPromo">
      <BoutonSauvegarderPromo typePromo={typePromo} id={id} condition={condition} montant={montant} code={code} categorie={categorie} typeMontant={typeMontant} date={date} onClick={refreshPromo} setEtatSauvegarde={setEtatSauvegarde}/>
      <div className="marginTop">
        <FormControl>
          <div>Type de promo</div>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue="Public"
            name="radio-buttons-group"
            value={typePromo}
            onChange={handleChangeTypePromo}
          >
            <FormControlLabel value="Public" control={<Radio />} label="Public" />
            <FormControlLabel value="Prive" control={<Radio />} label="Privé" />
          </RadioGroup>
        </FormControl>
      </div>
      <div className="espacementbox marginTop">
        <div>Code :</div>
        <input className="borderArrondi espacebox" value={code} onChange={event => setCode(event.target.value)}/>
      </div>
      <div>
        <FormControl>
          <div>Catégorie</div>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue="Electricite"
            name="radio-buttons-group"
            value={categorie}
            onChange={handleChangeCategorie}
          >
            <FormControlLabel value="Electricite" control={<Radio />} label="Electricité" />
            <FormControlLabel value="Alarme" control={<Radio />} label="Alarme" />
            <FormControlLabel value="Camera" control={<Radio />} label="Caméra" />
            <FormControlLabel value="Borne" control={<Radio />} label="Borne" />
            <FormControlLabel value="Panneaux photovoltaique" control={<Radio />} label="Panneaux photovoltaïque" />
          </RadioGroup>
        </FormControl>
      </div>
      <div className="espacementbox marginTop">
        <div>Montant :</div>
        <div>
          <FormControl>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue="euro"
              name="radio-buttons-group"
              value={typeMontant}
              onChange={handleChangeTypeMontant}
            >
              <FormControlLabel value="euro" control={<Radio />} label="€" />
              <FormControlLabel value="pourcentage" control={<Radio />} label="%" />
            </RadioGroup>
          </FormControl>
        </div>
        <input type={"number"} className="borderArrondi espacebox" value={montant} onChange={event => setMontant(event.target.value)}/>
      </div>
      <div className="espacementbox marginTop">
        <div>Condition</div>
        <textarea value={condition} className="boxtextarea borderArrondi" onChange={event => setCondition(event.target.value)}/>
      </div>
      <DatePicker date={date} setDate={setDate}/>
    </div>
  );
}

export default ModifPromo;