import HexagoneAssistant from "../../HexagoneAssistant/HexagoneAssistant";
import {useContext, useEffect, useState} from "react";
import {DevisContext} from "../../../../../Context/DevisContext";

const MateriauxToit = ({onClickHexa}) => {
  const { devis, setDevis } = useContext(DevisContext);
  const [selectionneMateriauxToit, setSelectionneMateriauxToit] = useState(devis.materiauxToit ?? null);
  const materiauxIncline = [
    {
      nom:"Tuile",
    },
    {
      nom:"Ardoise",
    },
    {
      nom:"Bardage métallique",
    },
    {
      nom:"Eternit",
    },
  ];
  const materiauxPlat = [
    {
      nom:"EPDM",
    },
    {
      nom:"Bithune",
    },
  ];

  useEffect(() => {
    setDevis((oldDevis) => ({
      ...oldDevis,
      materiauxToit: selectionneMateriauxToit,
    }))
  }, [selectionneMateriauxToit, setDevis]);

  return(
    <div className="contenuEtape fontAssisant">
      <div className="titreEtape texteInfoService center">Quel type de matériaux est utilisé pour votre toiture ?</div>
      <div className="hexagoneCentrer">
      <HexagoneAssistant
        ServicesItem={devis?.toit.nom === "Plat" ? materiauxPlat :  materiauxIncline}
        setSelectionHexagone={setSelectionneMateriauxToit}
        selectionHexagone={selectionneMateriauxToit}
        onClickHexa={onClickHexa}
      />
      </div>
    </div>
  );
}

export default  MateriauxToit;