import './Contact.css'
import Menu from "../../Globale/Composants/Menu/Menu";
import PiedDePage from "../../Globale/Composants/PiedDePage/PiedDePage";
import EnvoieMail from "./Composants/EnvoieMail/EnvoieMail";
import Information from "./Composants/Information/Information";
import React from 'react';
import ScrollTop from "../../Globale/Composants/ScrollTop/ScrollTop";
import FormulaireAvis from "../Accueil/Composants/FormulaireAvis/FormulaireAvis";

const Contact = () => {
  return (
    <div>
      <ScrollTop />
      <header>
        <Menu/>
      </header>
      <div className="corps">
        <div className="ellipseRose"></div>
        <div className="ellipseBleu"></div>
        <div className="titre1 margintop">Contactez-nous !</div>
        <EnvoieMail />
        <div className="infoContact">
          <Information />
        </div>
        <FormulaireAvis/>
      </div>
      <footer>
        <PiedDePage />
      </footer>
    </div>
  );
}

export default Contact;
