import {useCallback, useEffect, useState} from "react";
import axios from "axios";

const useGalerie = ({composant, dossier}) => {
  const [listImage, setListImage] = useState([]);

  const refresh = useCallback(() => {
    //if (!dossier || !composant) return;
      axios.post('https://api.jelefaismoimeme.be/galerie.php',{
        composant : composant,
        dossier : dossier,
      })
        .then((response) => {
          setListImage(response.data);
        });
  }, [dossier, composant]);

  useEffect(refresh,[refresh]);

  return { listImage, refresh };
}

export default useGalerie;