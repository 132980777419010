import {Button, Snackbar} from "@mui/material";
import Stack from "@mui/material/Stack";
import React, {useState} from "react";
import MuiAlert from "@mui/material/Alert";
import axios from "axios";

const BoutonSauvegarderAvis = ({id, nom, note, valide, commentaire, onClick}) => {
  const [open, setOpen] = React.useState(false);
  const [messageErreur, setMessageErreur] = useState('');
  const [severity, setSeverity] = useState("success");

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  /*Ouvrir le message d'erreur*/
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  /*Event pour sauvegarder le code promo*/
  const sauvegarderClick = () => {
    console.log(valide)
    axios.post('https://api.jelefaismoimeme.be/sauvegarderAvis.php',{
      id : id,
      nom : nom,
      note : note,
      commentaire : commentaire,
      valide : valide,
    }).then((response) => {
      console.log(response.data)
      onClick();
      if(response.data.includes("Erreur")){
        setSeverity("error")
        setMessageErreur(response.data)
      }
      else{
        setSeverity("success")
        setMessageErreur("L'avis a bien été enregistrer")
      }
    });
    setOpen(true);
  }

  return(
    <div>
      <div className="modifbtn marginTop">
        <Button variant="contained" onClick={sauvegarderClick}>Sauvegarder</Button>
      </div>
      <Stack spacing={2} sx={{ width: '100%' }}>
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert className="alert" onClose={handleClose} severity={severity} sx={{ width: '400px'}}>
            {messageErreur}
          </Alert>
        </Snackbar>
      </Stack>
    </div>
  );
}

export default BoutonSauvegarderAvis;