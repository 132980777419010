import React from 'react';

function CorbeilleIcon(props) {
  return (
    <svg width="30" height="30" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M18.75 18.75H21.875V37.5H18.75V18.75ZM28.125 18.75H31.25V37.5H28.125V18.75Z" fill="black"/>
      <path d="M6.25 9.375V12.5H9.375V43.75C9.375 44.5788 9.70424 45.3737 10.2903 45.9597C10.8763 46.5458 11.6712 46.875 12.5 46.875H37.5C38.3288 46.875 39.1237 46.5458 39.7097 45.9597C40.2958 45.3737 40.625 44.5788 40.625 43.75V12.5H43.75V9.375H6.25ZM12.5 43.75V12.5H37.5V43.75H12.5ZM18.75 3.125H31.25V6.25H18.75V3.125Z" fill="black"/>
    </svg>
  );
}

export default CorbeilleIcon;