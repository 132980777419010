import './Formulaire.css'
import React, {useContext, useEffect, useState} from "react";
import {Button, Snackbar, TextField} from "@mui/material";
import Stack from "@mui/material/Stack";
import MuiAlert from '@mui/material/Alert';
import validator from "validator";
import {DevisContext} from "../../../../Context/DevisContext";


const Formulaire = ({changeComposant}) => {
  const { devis, setDevis } = useContext(DevisContext);
  const [selected, setSelected] = useState(false);
  const [tel, setTel] = useState(false);
  const [mail, setMail] = useState(devis?.client?.mail ?? null);
  const axios = require('axios');
  const [open, setOpen] = React.useState(false);
  const [messageErreur, setMessageErreur] = useState('');
  const [severity, setSeverity] = useState("success");
  const [prenom, setPrenom] = useState(devis?.client?.prenom ?? null);
  const [nom, setNom] = useState(devis?.client?.nom ?? null);
  const [telephone, setTelephone] = useState(devis?.client?.telephone ?? null);
  const [ville, setVille] = useState(devis?.client?.ville ?? null);
  const [codePostale, setCodePostale] = useState(devis?.client?.codePostale ?? null);
  const [rue, setRue] = useState(devis?.client?.rue ?? null);
  const [numero, setNumero] = useState(devis?.client?.numero ?? null);
  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  useEffect(() => {
    setDevis((oldDevis) => ({
      ...oldDevis,
      client: {
        mail: mail,
        ville: ville,
        nom: nom,
        prenom: prenom,
        telephone: telephone,
        rue: rue,
        numero: numero,
        codePostale: codePostale,
      },
    }))
  }, [mail, ville, numero, nom, prenom, telephone, rue, codePostale, setDevis]);

  const validationEmail = (e) => {
    var email = e.target.value
    if (validator.isEmail(email)){
      setSelected(true)
    }
    else{
      setSelected(false)
    }
  }
  const validatePhoneNumber = (e) => {
    var entreTel = e.target.value
      const isValidPhoneNumber = validator.isMobilePhone(entreTel)
      setTel(isValidPhoneNumber)
  }

  const reset = () => {
    setSelected(false)
    setMail('')
    setPrenom('')
    setNom('')
    setTelephone('')
    setRue('')
    setVille('')
    setCodePostale('')
    setNumero('')
  }

  const envoieMailValide = (e) => {
    if(selected === true && ville !== null && telephone === null){
      envoieMail()
    }
    else{
      if(tel === true && selected === true && ville !== null){
        envoieMail()
      }
      else{
        if(tel === false && telephone !== null){
          setSeverity("error")
          setMessageErreur("Le champs non obligatoire téléphone n'a pas été complé correctement")
          setOpen(true);
        }
        else{
          setSeverity("error")
          setMessageErreur("Un des champs obligatoire n'a pas été completé correctement")
          setOpen(true);
        }
      }
    }
  }

  const envoieMail = () => {

    axios.post('https://api.jelefaismoimeme.be/devis.php', {
      devis : devis,
    }).then((response) => {
        if(response.data === 'success'){
          setMessageErreur("Votre devis a bien été envoyé")
          setSeverity("success")
          reset()
        }
        else{
          setMessageErreur("Votre devis n'a pas été envoyé, veuillez réessayer.")
          setSeverity("error")
        }
        setOpen(true);
      }
    );
  }

  const precedent = () => {
    changeComposant(false)
  }


  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  return (
    <div className="formulaire">
      <div className="titre1 margintop">Formulaire</div>
      <div className="marginBottom">Pour recevoir votre devis, veuillez completer les champs obligatoire</div>
      <div className="boxFormulaire">
        <div className="boxFormulaireObligatoire">
          <TextField
            helperText="* Champs obligatoire"
            id="demo-helper-text-aligned"
            label="Adresse mail"
            onInput={event => setMail( event.target.value)}
            value={mail}
            onChange={(e) => validationEmail(e)}
          />
        </div>
        <div className="boxFormulaireObligatoire">
          <TextField
            helperText="* Champs obligatoire"
            id="demo-helper-text-aligned"
            label="Ville"
            onInput={event => setVille( event.target.value)}
            value={ville}
          />
        </div>
      </div>
      <div className="boxFormulaire ">
        <div className="boxFormulaireObligatoire">
          <TextField
            helperText=""
            id="demo-helper-text-aligned"
            label="Nom"
            onInput={event => setNom( event.target.value)}
            value={nom}
          />
        </div>
        <div className="boxFormulaireObligatoire">
          <TextField
            helperText=""
            id="demo-helper-text-aligned"
            label="Prénom"
            onInput={event => setPrenom( event.target.value)}
            value={prenom}
          />
        </div>
        <div className="boxFormulaireObligatoire">
          <TextField
            helperText=""
            id="demo-helper-text-aligned"
            label="Téléphone"
            onChange={(e) => validatePhoneNumber(e)}
            onInput={event => setTelephone( event.target.value)}
            value={telephone}
          />
        </div>
        <div className="boxFormulaireObligatoire">
          <TextField
            helperText=""
            id="demo-helper-text-aligned"
            label="Rue"
            onInput={event => setRue( event.target.value)}
            value={rue}
          />
        </div>
        <div className="boxFormulaireObligatoire">
          <TextField
            helperText=""
            id="demo-helper-text-aligned"
            label="Numéro"
            type="number"
            onInput={event => setNumero( event.target.value)}
            value={numero}
          />
        </div>
        <div className="boxFormulaireObligatoire">
          <TextField
            helperText=""
            id="demo-helper-text-aligned"
            label="Code postal"
            type="number"
            onInput={event => setCodePostale( event.target.value)}
            value={codePostale}
          />
        </div>
      </div>
      <Stack spacing={2} sx={{width: '100%'}}>
        <div className="boutonFormulaire">
          <Button variant="contained" onClick={() => precedent()} >Précédent</Button>
          <Button variant="contained" onClick={() => envoieMailValide()} >Envoyer mon devis</Button>
        </div>
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert className="alert" onClose={handleClose} severity={severity} sx={{width: '400px'}}>
            {messageErreur}
          </Alert>
        </Snackbar>
      </Stack>
    </div>
  );
}

export default Formulaire;