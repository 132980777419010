import './Connexion.css'
import ScrollTop from "../../Globale/Composants/ScrollTop/ScrollTop";
import Menu from "../../Globale/Composants/Menu/Menu";
import React from "react";
import PiedDePage from "../../Globale/Composants/PiedDePage/PiedDePage";
import FormulaireConnexion from "./Composants/FormulaireConnexion/FormulaireConnexion";

const Connexion = () => {
  return(
    <div className="connexion">
      <ScrollTop />
      <header>
        <Menu />
      </header>
      <div className="corps">
        <div className="ellipseRose"></div>
        <div className="ellipseBleu"></div>
        <FormulaireConnexion/>
      </div>
      <footer>
        <PiedDePage />
      </footer>
    </div>
  );
}

export default Connexion;