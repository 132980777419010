import './Assistant.css'
import {useMemo} from "react";
import TypeHabitation from "./TypeHabitation/TypeHabitation";
import Construction from "./Construction/Construction";
import Piece from "./Piece/Piece";
import Toit from "./Toit/Toit";
import ConfigurationElectricite from "./Configuration/ConfigurationElectricite";
import ScrollTop from "../../../../Globale/Composants/ScrollTop/ScrollTop";
import * as React from "react";
import MateriauxToit from "./MateriauxToit/MateriauxToit";
import Superficie from "./Superficie/Superficie";
import Consommation from "./Consommation/Consommation";
import Compteur from "./Compteur/Compteur";
import TypeBorne from "./TypeBorne/TypeBorne";
import Emplacement from "./Emplacement/Emplacement";
import NombreCamera from "./NombreCamera/NombreCamera";
import Filaire from "./Filaire/Filaire";
import Formulaire from "../Formulaire/Formulaire";
import Centrale from "./Centrale/Centrale";
import Detecteur from "./Detecteur/Detecteur";
import ConfigGenerale from "./Configuration/ConfigGenerale/ConfigGenerale";

const Assistant = ({EtapeSelectionne, onClickHexa}) => {
  const selectEtapeWidget = useMemo(() => {
    switch (EtapeSelectionne) {
      case 'Construction': return <Construction onClickHexa={onClickHexa}/>;
      case 'Habitation': return <TypeHabitation onClickHexa={onClickHexa}/>;
      case 'Toit': return <Toit onClickHexa={onClickHexa}/>;
      case 'Pièce': return <Piece/>;
      case 'Detail': return <ConfigurationElectricite/>
      case 'Matériaux': return <MateriauxToit onClickHexa={onClickHexa}/>
      case 'Superficie': return <Superficie/>
      case 'Consommation': return <Consommation/>
      case 'Compteur électrique': return <Compteur onClickHexa={onClickHexa}/>
      case 'Type borne': return <TypeBorne onClickHexa={onClickHexa}/>
      case 'Emplacement': return <Emplacement/>
      case 'Nombre de caméra': return <NombreCamera/>
      case 'Type installation': return  <Filaire onClickHexa={onClickHexa}/>
      case 'Formulaire': return  <Formulaire/>
      case 'Configuration Générale': return <Centrale/>
      case 'Détection': return <Detecteur/>
      case 'Générale': return <ConfigGenerale/>
      default: return <div/>
    }
  }, [EtapeSelectionne]);
  return(
    <div>
      <ScrollTop key={EtapeSelectionne}/>
      {selectEtapeWidget}
    </div>
  );
}

export default Assistant;