import React from 'react';
import { SvgIcon } from "@mui/material";

function MaisonMitoyenneIcon(props) {
  return (
    <SvgIcon {...props} viewBox="0 0 177 100">
      <path d="M106.059 100V64.7059H129.588V100H159V52.9412H176.647L117.824 0L59 52.9412H76.6471V100H106.059Z" fill="currentColor"/>
      <path d="M47.0588 100V64.7059H70.5882V100H100V52.9412H117.647L58.8235 0L0 52.9412H17.6471V100H47.0588Z" fill="currentColor"/>
    </SvgIcon>
  );
}

export default MaisonMitoyenneIcon;