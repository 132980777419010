import './HexagoneAssistant.css'
import HexagoneIcon from "../../../../Globale/Icons/HexagoneIcon";
import React, {useMemo} from "react";

const HexagoneAssistant = ({ServicesItem, setSelectionHexagone, selectionHexagone, onClickHexa}) => {

  const Changeselection = (sousItem) => {
    setSelectionHexagone(sousItem)
    onClickHexa(true)
  }

  /*Coupe la liste Servicesitem en 3 avec une taille de 2,1,2 pour avoir l'effet souhaité*/
  const NewServicesItem = useMemo(() => ServicesItem.reduce((acc, elm, idx) => {
    if (idx === 0) return [[elm]];
    if (ServicesItem.length === 4){
      if (parseInt(idx / 2) % 2 === 0 && acc[acc.length - 1].length < 1) {
        acc[acc.length - 1].push(elm);
        return acc;
      }
      if (parseInt(idx / 2) % 2 === 1 && acc[acc.length - 1].length < 2) {
        acc[acc.length - 1].push(elm);
        return acc;
      }
      return [...acc, [elm]];
    }
    else{
      if (parseInt(idx / 3) % 3 === 0 && acc[acc.length - 1].length < 3) {
        acc[acc.length - 1].push(elm);
        return acc;
      }
      if (parseInt(idx / 3) % 3 === 1 && acc[acc.length - 1].length < 2) {
        acc[acc.length - 1].push(elm);
        return acc;
      }
      return [...acc, [elm]];
    }
  }, []));

  return(
    <div className="allServices ">
      {NewServicesItem.map((item , idItem) => (
        <div
          key={'NewServicesItem' + idItem}
          className="colonneServices ">
          {item.map((sousItem, idsousItem) => {
            return (
              <div
                key={'ServicesItem' + idsousItem}
                className="hexa"
                onClick={() => Changeselection(sousItem)}
              >
                <div className={selectionHexagone?.nom === sousItem.nom ? "hexagoneAssisant iconColor" : "hexagoneAssisant iconColorSelec"}>
                  <HexagoneIcon/>
                </div>
                <div className="elementHexagone">
                  {sousItem.icon && (<div className="iconCase " >{<sousItem.icon sx={{ fontSize: 70 }} />}</div>)}
                  <div className="titreService center" >{sousItem.nom}</div>
                </div>
              </div>
            )
          })}
        </div>
      ))}
    </div>
  );
}

export default HexagoneAssistant;