import './Actualites.css'
import {useEffect, useMemo, useState} from "react";
import React from 'react';
import axios from "axios";
import useActualite from "../../../../Hook/useActualite";

const Actualites = () => {
  const { listActu: evenementItem } = useActualite();
  const [afficheActu, setAfficheActu] = useState([]);
  const titreActualite = useMemo(() => {
    if(afficheActu.length > 1){
      return 'Actualités'
    }
    if(afficheActu.length === 0
    ){
      return ''
    }
    else{
      return 'Actualité'
    }
  }, [afficheActu.length]);


  /*Appel API pour récuperer les bonne actu */
  useEffect(() => {
    axios.post('https://api.jelefaismoimeme.be/recupereActu.php')
      .then((response) => {
        setAfficheActu(response.data ?? [])
      });
  },[setAfficheActu])

  return (
    <div className="actualites">
      <div className="titre1 justifier">{titreActualite}</div>
      {afficheActu.map((item , idItem) => (
        <div
          key={'EvenementItem' + idItem}
        >
          <div className="evenement">
            <div className="EvenementImage">
              {item.image !== ""?(
                <img src={"https://entrepot.jelefaismoimeme.be/actualite/"+item.image} loading="lazy" alt="Evenement image"/>
              ):<div/>
              }
            </div>
            <div className="texteEvenement">
              <div className="gras nomEvenement">{item.nom}</div>
              <div>{item.description}</div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

export default Actualites;