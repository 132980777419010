/*Liste des pièces disponible avec les niveaux par defaut, leurs nombres et le type d'habitation */

export const ListePiece = [
  {
    piece: "Hall d'entrée",
    etage: ["Rez de chaussée"],
    habitation: ['Maison mitoyenne','Maison individuelle'],
    nombre: 1,
  },
  {
    piece: "Salon",
    etage: ["Rez de chaussée"],
    habitation: ['Maison mitoyenne','Appartement','Maison individuelle'],
    nombre: 1,
  },
  {
    piece: "Salle à manger",
    etage: ["Rez de chaussée"],
    habitation: ['Maison mitoyenne','Appartement','Maison individuelle'],
    nombre: 1,
  },
  {
    piece: "Cuisine",
    etage: ["Rez de chaussée"],
    habitation: ['Maison mitoyenne','Appartement','Maison individuelle'],
    nombre: 1,
  },
  {
    piece: "WC",
    etage: ["Rez de chaussée","Etage 1"],
    habitation: ['Maison mitoyenne','Appartement','Maison individuelle'],
    nombre: {
      'Maison mitoyenne': 1,
      'Appartement': 1,
      'Maison individuelle': 2,
    }
  },
  {
    piece: "Buanderie",
    etage: ["Rez de chaussée"],
    habitation: ['Maison mitoyenne','Maison individuelle','Appartement'],
    nombre: 1,
  },
  {
    piece: "Garage",
    etage: ["Rez de chaussée"],
    habitation: ['Maison mitoyenne','Maison individuelle'],
    etageUniquement: ["Rez de chaussée", 'Extérieur', 'Cave'],
    nombre: 1,
  },
  {
    piece: "Chambre",
    etage: ["Rez de chaussée","Etage 1"],
    habitation:['Appartement','Maison mitoyenne','Maison individuelle'],
    nombre: {
      'Maison mitoyenne': 2,
      'Appartement': 1,
      'Maison individuelle': 3,
    }
  },
  {
    piece: "Salle de bain",
    etage: ["Etage 1"],
    habitation: ['Maison mitoyenne','Maison individuelle'],
    nombre: {
      'Maison mitoyenne': 1,
      'Appartement': 1,
      'Maison individuelle': 2,
    }
  },
  {
    piece: "Salle de douche",
    etage: ["Rez de chaussée"],
    habitation: ['Appartement'],
    nombre: 1,
  },
  {
    piece: "Hall de nuit",
    etage: ["Etage 1"],
    habitation: ['Maison mitoyenne','Maison individuelle'],
    nombre: 1,
  },
  {
    piece: "Escalier",
    etage: ["Rez de chaussée"],
    habitation: ['Maison mitoyenne','Maison individuelle'],
    nombre: 1,
  },
  {
    piece: "Grenier",
    etage: ["Grenier"],
    habitation: ['Maison mitoyenne','Maison individuelle'],
    etageUniquement: ["Etage 1", "Etage 2", 'Grenier'],
    nombre: 1,
  },
  {
    piece: "Hall cave",
    etage: ["Cave"],
    habitation: [],
    etageUniquement: ["Cave"],
    nombre: 1,
  },
  {
    piece: "Cave",
    etage: ["Cave"],
    habitation: ['Maison individuelle'],
    etageUniquement: ["Cave"],
    nombre: 1,
  },
  {
    piece: "Chaufferie",
    etage: [],
    habitation: [],
    nombre: 1,
  },
  {
    piece: "Dressing",
    etage: [],
    habitation: [],
    nombre: 1,
  },
  {
    piece: "Bureau",
    etage: [],
    habitation: [],
    nombre: 1,
  },
  {
    piece: "Salle de jeux",
    etage: [],
    habitation: [],
    nombre: 1,
  },
  {
    piece: "Local technique",
    etage: [],
    habitation: [],
    nombre: 1,
  },
  {
    piece: "Veranda",
    etage: [],
    habitation: [],
    etageUniquement: ["Rez de chaussée"],
    nombre: 1,
  },
  {
    piece: "Chalet",
    etage: ["Extérieur"],
    habitation: [],
    etageUniquement: ["Extérieur"],
    nombre: 1,
  },
  {
    piece: "Piscine",
    etage: ["Extérieur"],
    habitation: [],
    etageUniquement: ["Extérieur"],
    nombre: 1,
  },
  {
    piece: "Jardin avant",
    etage: ["Extérieur"],
    habitation: [],
    etageUniquement: ["Extérieur"],
    nombre: 1,
  },
  {
    piece: "Jardin arrière",
    etage: ["Extérieur"],
    habitation: [],
    etageUniquement: ["Extérieur"],
    nombre: 1,
  },
  {
    piece: "Autre",
    etage: [],
    habitation: [],
    nombre: 1,
  },
];