import * as React from "react";
import {useContext, useEffect, useState} from "react";
import {DevisContext} from "../../../../../Context/DevisContext";
import CheckBoxConfig from "../Configuration/CheckBoxConfig/CheckBoxConfig";

const Emplacement = () => {
  const { devis, setDevis } = useContext(DevisContext);
  const [emplacement, setEmplacement] = useState(devis?.emplacement?.distance ?? null);
  const [cableApparent, setCableApparent] = useState(devis?.emplacement?.cableapparent ?? false);

  useEffect(() => {
    setDevis((oldDevis) => ({
      ...oldDevis,
      emplacement: {
        distance: emplacement,
        cableapparent: cableApparent,
      },
    }))
  }, [emplacement, cableApparent, setDevis]);
  return(
    <div className="contenuEtape fontAssisant">
      <div className="titreEtape texteInfoService center">A quelle distance entre le coffret électrique et la borne ?</div>
      <div className="superficie Boxcenter">
        <input type="number" className="inputAssistant font" min="0"
               onInput={event => setEmplacement(event.target.value)} value={emplacement}/>
        <div style={{fontSize:'30px'}}>m</div>
      </div>
      <div className="Boxcenter">
        <CheckBoxConfig nom="câblage en apparence" defaultCheck={cableApparent} onChange={setCableApparent} isChecked={cableApparent}/>
      </div>
    </div>
  );
}

export default Emplacement;