import './Devis.css'
import Menu from "../../Globale/Composants/Menu/Menu";
import React, {useContext, useEffect, useState} from "react";
import PiedDePage from "../../Globale/Composants/PiedDePage/PiedDePage";
import ScrollTop from "../../Globale/Composants/ScrollTop/ScrollTop";
import DevisFrame from "./Composants/DevisFrame/DevisFrame";
import EtapeCommencerDevis from "./Composants/EtapeCommencerDevis/EtapeCommencerDevis";
import {DevisContext, DevisProvider} from "../../Context/DevisContext";
import {useLocation} from "react-router-dom";
import {TutoProvider} from "../../Context/TutoContext";

const Devis = () => {
  const { devis } = useContext(DevisContext);
  const location = useLocation();
  const [statusEtape, setStatusEtape] = useState(false);
  const [recupererDevis, setRecupererDevis] = useState(false);
  const [selectionneService,setSelectionneService] = useState(devis?.devis ?? null);

  useEffect(() => {
    setStatusEtape(false);
    setSelectionneService(null);
  }, [location.key]);
  //console.log('KEY', location.key, location.state);

  return(
    <div className="devis" key={location.key}>
      <DevisProvider>
        <ScrollTop />
        <header>
          <Menu statusClick={true}/>
        </header>
        <TutoProvider>
          <div className="corps">
            <div className="ellipseRose"></div>
            <div className="ellipseBleu"></div>
            {statusEtape === false ?(
              <EtapeCommencerDevis setRecupererDevis={setRecupererDevis} setStatusEtape={setStatusEtape} selectionneService={selectionneService} setSelectionneService={setSelectionneService}/>
            ): <DevisFrame recupererDevis={recupererDevis} setRecupererDevis={setRecupererDevis} setStatusEtape={setStatusEtape} selectionneService={selectionneService}/>
            }
          </div>
        </TutoProvider>
        <footer>
          <PiedDePage />
        </footer>
      </DevisProvider>
    </div>
  );
}

export default Devis;