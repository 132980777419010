import * as React from "react";
import {useContext, useEffect, useState} from "react";
import {DevisContext} from "../../../../../Context/DevisContext";
import SliderDevis from "../Slider/SliderDevis";

const Consommation = () => {
  const { devis, setDevis } = useContext(DevisContext);
  const [consommationJour, setConsommationJour] = useState(devis?.consommation?.jour ?? 0);
  const [consommationNuit, setConsommationNuit] = useState(devis?.consommation?.nuit ?? 0);
  const [consommationExclusif, setConsommationExclusif] = useState(devis?.consommation?.exclusifNuit ?? 0);

  useEffect(() => {
    setDevis((oldDevis) => ({
      ...oldDevis,
      consommation:{
        jour: consommationJour,
        nuit: consommationNuit,
        exclusifNuit: consommationExclusif,
      },
    }))
  }, [consommationJour, consommationExclusif, consommationExclusif, setDevis]);

  return(
    <div className="contenuEtape fontAssisant">
      <div className="titreEtape texteInfoService center">Quelle est votre consommation annuelle en Kwh ?</div>
      <div className="consommation">
        <div className="consommationTexte">Jour</div>
        <div>
          <SliderDevis min={0} max={20000} step={250} value={consommationJour} setValue={setConsommationJour}/>
          <div className="consommationTexte">{consommationJour}Kwh</div>
        </div>
      </div>
      <div className="consommation">
        <div className="consommationTexte">Nuit</div>
        <div>
          <SliderDevis min={0} max={20000} step={250} value={consommationNuit} setValue={setConsommationNuit}/>
          <div className="consommationTexte">{consommationNuit}Kwh</div>
        </div>
      </div>
      <div className="consommation">
        <div className="consommationTexte">Exclusif nuit</div>
        <div>
          <SliderDevis min={0} max={20000} step={250} value={consommationExclusif} setValue={setConsommationExclusif}/>
          <div className="consommationTexte">{consommationExclusif}Kwh</div>
        </div>
      </div>
    </div>
  );
}

export default Consommation;