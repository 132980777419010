import './ModifPopup.css'
import {Switch} from "@mui/material";
import {useContext, useState} from "react";
import BoutonSauvegarderPopup from "../Sauvegarder/BoutonSauvegarderPopup";
import {AdminContext} from "../../../../Context/AdminContext";
import * as React from 'react';

const ModifPopup = () => {
  const { popup: { refresh, popup } } = useContext(AdminContext);
  const [active, setActive] = useState(popup.active ?? true);
  const [nom, setNom] = useState(popup.nom ?? '');
  const [description, setDescription] = useState(popup.description ?? '');

  const handleChange = (event) => {
    setActive(event.target.checked);
  };
  return(
    <div className="ModifPopup">
      <BoutonSauvegarderPopup nom={nom} description={description} active={active} onClick={refresh}/>
      <div className="marginTop">Activer le pop-up :</div>
      <div className="activePopup">
        <div>off</div>
        <Switch checked={active} onChange={handleChange} />
        <div>on</div>
      </div>
      <div className="espacementbox marginTop">
        <div>Nom :</div>
        <input className="borderArrondi espacebox" value={nom} onChange={event => setNom(event.target.value)}/>
      </div>
      <div className="espacementbox marginTop">
        <div>Description :</div>
        <textarea className="boxtextarea borderArrondi" value={description} onChange={event => setDescription(event.target.value)}/>
      </div>
    </div>
  );
}

export default ModifPopup;