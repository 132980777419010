import './DevisFrame.css'
import {Button} from "@mui/material";
import StepperDevis from "../Stepper/StepperDevis";
import {useContext, useEffect, useMemo, useRef, useState} from "react";
import Assistant from "../Assistant/Assistant";
import {DevisContext} from "../../../../Context/DevisContext";
import PrixDevis from "../PrixDevis/PrixDevis";

const DevisFrame = ({recupererDevis, setStatusEtape, selectionneService, setRecupererDevis}) => {
  const { devis } = useContext(DevisContext);
  const [numeroActiveStep, setNumeroActiveStep] = useState(0);
  const [clickHexa, onClickHexa] = useState(false);
  const [terminerDevis, setTerminerDevis] = useState(false);

  //useEffect(() => console.log('save generale', {...devis}), [devis]);

  const etapeBorne = [
    'Construction',
    'Type borne',
    'Compteur électrique',
    'Emplacement',
  ];
  const etapePV = [
    'Construction',
    'Toit',
    'Matériaux',
    'Superficie',
    'Compteur électrique',
    'Consommation',
  ];
  const etapeElectricite = [
    'Construction',
    'Habitation',
    'Pièce',
    'Detail',
  ];
  const etapeCamera = [
    'Construction',
    'Nombre de caméra',
  ];
  const etapeAlarme = [
    'Construction',
    'Type installation',
    'Configuration Générale',
    'Détection',
  ];

  useEffect(() => {
    if(clickHexa === true){
      // setNumeroActiveStep((oldNumeroActiveStep) => oldNumeroActiveStep + 1)
      const timer = setTimeout(() => {
        setNumeroActiveStep((oldNumeroActiveStep) => oldNumeroActiveStep + 1);
        onClickHexa(false)
      }, 150);
      return () => {
        clearTimeout(timer)
        onClickHexa(false)
      };
    }
    // onClickHexa(false)
  }, [clickHexa]);

  /*Selectionne la liste a afficher par rapport a l'element selectionner dans l'etape 0*/
  const selectList = useMemo(() => {
    switch (selectionneService?.nom) {
      case 'Borne': return etapeBorne;
      case 'Panneaux photo voltaïque': return etapePV;
      case 'Electricité': return etapeElectricite;
      case 'Caméra': return etapeCamera;
      case 'Alarme': return etapeAlarme;
      default: return [];
    }
  }, [selectionneService, etapeBorne, etapePV, etapeElectricite, etapeCamera, etapeAlarme]);


  const texteBtnSuivant = useMemo(() => {
    if(numeroActiveStep === selectList.length-1){
      return 'Terminé'
    }
    else{
      return 'Suivant'
    }

  }, [numeroActiveStep, selectList]);

  const stepCompleted = useMemo(() => {
    switch (selectionneService?.nom) {
      case 'Borne': return {
        0: !!devis.construction,
        1: !!devis.typeBorne,
        2: !!devis.compteur,
        3: !!devis.emplacement,
      };
      case 'Panneaux photo voltaïque': return {
        0: !!devis.construction,
        1: !!devis.toit,
        2: !!devis.materiauxToit,
        3: !!devis.superficie,
        4: !!devis.consommation,
        5: !!devis.compteur,
      };
      case 'Electricité': return {
        0: !!devis.construction,
        1: !!devis.habitation,
        2: !!devis.niveaux,
        3: !!devis.configPiece,
      };
      case 'Caméra': return {
        0: !!devis.construction,
        1: !!devis.camera,
      };
      case 'Alarme': return {
        0: !!devis.construction,
        1: !!devis.typeAlarme,
        2: !!devis.generale,
        3: !!devis.detecteur,
      };
      default: return [];
    }
  }, [devis, selectionneService]);

  const suivant = () => {
    if(numeroActiveStep < selectList.length-1){
      setNumeroActiveStep(numeroActiveStep+1)
    }
    else{
      setTerminerDevis(true)
    }
  }
  const precedent = () => {
    if(numeroActiveStep > 0){
      setNumeroActiveStep(numeroActiveStep-1)
    }
    if(numeroActiveStep === 0){
      setStatusEtape(false)
    }
  }

  const btnFlottantRef = useRef(null);
  const fixedClass = 'btnFlottantFixed';
  let updating = false;

  const handleScroll = () => {

    if (( window.innerHeight - document.querySelector('.piedDePage').getBoundingClientRect().top < 0)) //&& document.body.scrollHeight < 1200) // < plus petit
    {
      btnFlottantRef.current?.classList.add(fixedClass)
    }
    else{
      btnFlottantRef.current?.classList.remove(fixedClass)
    }
    updating = false
  }

  window.onscroll = () => {
    if (updating) return
    else {
      updating = true
      requestAnimationFrame(handleScroll)
    }
  }

  return(
    <div>
      {(terminerDevis === false && recupererDevis === false) ? (
        <div className="devisFrame devisTaillePage">
          <div className="espaceTop">
            <StepperDevis stepCompleted={stepCompleted} numeroActiveStep={numeroActiveStep} etapes={selectList} setNumeroActiveStep={setNumeroActiveStep}/>
          </div>
          <div className="marginAssistant">
            <Assistant EtapeSelectionne={selectList[numeroActiveStep]} onClickHexa={onClickHexa}/>
          </div>
            <div className="marginTop styleBtnDevis padding5 marginBottom espaceBtnFlottant">
              <div className="btnFlottant" ref={btnFlottantRef}>
                <div className="positionFlex">
                  <Button variant="contained" onClick={precedent}>Précédent</Button>
                  <Button variant="contained" onClick={suivant} disabled={!stepCompleted[numeroActiveStep]}>{texteBtnSuivant}</Button>
                </div>
              </div>
            </div>
        </div>
      ) : <PrixDevis changeComposant={setTerminerDevis} setRecupererDevis={setRecupererDevis} setNumeroActiveStep={setNumeroActiveStep} numeroActiveStep={selectList.length-1}/>}
    </div>
  );
}

export default DevisFrame;

