import './Processus.css'
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import React from 'react';

const Processus = ({Etapes, Couleur}) => {
  return(
    <div className="processus" style={{ '--processusColor': Couleur }}>
      <div className="titre1">Le processus</div>
      <Timeline>
        {Etapes.map((item , idItem) => (
          <div
            key={'Etapes' + idItem}
          >
            <TimelineItem>
              <TimelineSeparator>
                <TimelineDot />
                {idItem !== Etapes.length - 1 && <TimelineConnector />}
              </TimelineSeparator>
              <TimelineContent>{item}</TimelineContent>
            </TimelineItem>
          </div>
        ))}
      </Timeline>
    </div>
  );
}

export default Processus;