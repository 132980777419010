import './Services.css'
import CaseElement from "../../../../Globale/Composants/CaseElement/CaseElement";
import ElectriciteIcon from "../../../../Globale/Icons/ElectriciteIcon";
import CameraIcon from "../../../../Globale/Icons/CameraIcon";
import AlarmeIcon from "../../../../Globale/Icons/AlarmeIcon";
import React from 'react';
import {NavLink} from "react-router-dom";
import BorneIcon from "../../../../Globale/Icons/BorneIcon";
import PVIcon from "../../../../Globale/Icons/PVIcon";

const Services = () => {
  const ServicesItem = [
    {
      icon:<ElectriciteIcon sx={{ fontSize: 80 }} />,
      nomServices:'Electricité',
      color: '#0A90C9',
      lien:"/Electricite",
    },
    {
      icon:<AlarmeIcon sx={{ fontSize: 80 }} />,
      nomServices:'Alarme',
      color: '#D90368',
      lien:"/Alarme",
    },
    {
      icon:<CameraIcon sx={{ fontSize: 80 }} />,
      nomServices:'Caméra',
      color: '#A61414',
      lien: "/Camera",
    },
    {
      icon:<PVIcon sx={{ fontSize: 80 }} />,
      nomServices:'Panneaux photo voltaïques',
      color: '#05AB33',
      lien:"/Panneaux",
    },
    {
      icon:<BorneIcon sx={{ fontSize: 80 }} />,
      nomServices:'Borne',
      color: '#FFB100',
      lien:"/Borne",
    },
  ];
  function reveal() {
    var reveals = document.querySelectorAll(".reveal");
    for (var i = 0; i < reveals.length; i++) {
      var windowHeight = window.innerHeight; //Récupère la hauteur (en pixels) de la partie visible de la fenêtre de navigation en incluant, si elle est affichée, la barre de défilement horizontale.
      var elementTop = reveals[i].getBoundingClientRect().top;
      var elementVisible = 150;

      if (elementTop < windowHeight - elementVisible) {
        reveals[i].classList.add("active");
      } else {
        reveals[i].classList.remove("active");
      }
    }
  }
  window.addEventListener("scroll", reveal);

  return (
    <div className="services">
      <div className="titre1">J'aimerais réaliser une installation en ...</div>
      {
        <div className="elementServices reveal">
          {ServicesItem.map((item , idItem) => (
            <div
              key={'ServicesItem' + idItem}
            >
              <NavLink to={item.lien}>
                <CaseElement nomServices={item.nomServices} icon={item.icon} color={'#0A90C9'} colorBackground={"white"} hoverColor={'#086993'}/>
              </NavLink>
            </div>
          ))}
        </div>
      }
    </div>
  );
}

export default Services;

/**
 * <ScrollAnimation animateIn="animate__bounceInLeft" duration={2} className="elementServices">
 *         {ServicesItem.map((item , idItem) => (
 *           <div
 *             key={'ServicesItem' + idItem}
 *           >
 *             <NavLink to={item.lien}>
 *               <CaseElement nomServices={item.nomServices} icon={item.icon} color={item.color} colorBackground={"white"}/>
 *             </NavLink>
 *           </div>
 *         ))}
 *       </ScrollAnimation>
 */
