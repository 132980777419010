import {useCallback, useEffect, useState} from "react";

const StickyNav = ({ children, height }) => {
  const [isScrolled, setIsScrolled] = useState(false);
  const onScroll = useCallback(() => {
    setIsScrolled(window.pageYOffset > height);
  }, [height]);

  useEffect(() => {
    window.addEventListener('scroll', onScroll)
  }, [onScroll]);

  return (
    <div className={`nav ${isScrolled && 'scrollNav'}`}>
      {children}
    </div>
  )
}

export default StickyNav;