import './TooltipInformation.css'
import {ClickAwayListener, IconButton, Tooltip} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import * as React from "react";

const TooltipInformation = ({texte}) => {
  const [open, setOpen] = React.useState(false)

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  return(
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <Tooltip
        PopperProps={{
          disablePortal: true,
        }}
        onClose={handleTooltipClose}
        open={open}
        disableFocusListener
        disableHoverListener
        disableTouchListener
        placement="top"
        title={texte} classes={{ tooltip: "tooltipInformation"}} >
        <IconButton onClick={handleTooltipOpen}>
          <InfoIcon />
        </IconButton>
      </Tooltip>
    </ClickAwayListener>
  );
}

export default TooltipInformation;