import {Button, Snackbar} from "@mui/material";
import React, {useRef, useState} from "react";
import axios from "axios";
import MuiAlert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';

/*Bouton importer image dans galerie et partenaire de la page admin*/
const BoutonImporterImage = ({composant,dossier, onClick, idImage}) =>{
  const hiddenFileInput = useRef(null);
  const [open, setOpen] = React.useState(false);
  const [messageErreur, setMessageErreur] = useState('');
  const [severity, setSeverity] = useState("success");

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  /*Ouvre avec le click l'exporateur de fichier*/
  const handleClick = () => {
    hiddenFileInput.current.click();
  };

  /*Ouvrir le message d'erreur*/
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  /*Appele de l'APi pour lui renvoyer le fichier qui a été ouvert*/
  const UploadImage = async event => {
    const imageUploaded = event.target.files[0];
    var formData = new FormData();
    formData.append("image", imageUploaded);
    formData.append("dossier", dossier);
    formData.append("composant", composant);
      axios.post('https://api.jelefaismoimeme.be/uploadImage.php', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
      })
        .then((response) => {
          onClick(response.data);
          setOpen(true);
          if(response.data.includes("Erreur")){
            setSeverity("error")
            setMessageErreur(response.data)
          }
          else{
            setSeverity("success")
            setMessageErreur("L'image a été importé")
          }
        })
        .catch(function (erreur) {
        });
    setOpen(false);
  }

  return(
    <div>
      <Button className="boutonImporterImage" variant="contained" size="large"
              onClick={handleClick}
      >
        Importer image
      </Button>
      <input
        type="file"
        ref={hiddenFileInput}
        onChange={UploadImage}
        style={{display: 'none'}}
        accept="image/png, image/jpeg, image/jpg"
      />
      <Stack spacing={2} sx={{ width: '100%' }}>
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert className="alert" onClose={handleClose} severity={severity} sx={{ width: '400px'}}>
            {messageErreur}
          </Alert>
        </Snackbar>
      </Stack>
    </div>
  );
}

export default BoutonImporterImage;